import * as actions from "./action-types";
import * as auth0 from "../../services/authService";

export const isLoggedIn = () => {
  return async function(dispatch) {
    const response = await auth0.getCurrentSession();
    if (response) {
      dispatch({ type: actions.LOGGED_IN, payload: response });
      return true;
    } else {
      dispatch({ type: actions.NOT_LOGGED_IN });
    }
  };
};

export const login = () => {
  return async function() {
    auth0.login();
  };
};

export const logout = () => {
  return async function() {
    auth0.logout();
  };
};

export const loginSuccessful = () => {
  return async function(dispatch) {
    const response = await auth0.loginSuccessful();
    if (response) {
      dispatch({ type: actions.LOGIN_SUCCESSFUL, payload: response });
      return true;
    } else {
      dispatch({ type: actions.NOT_LOGGED_IN });
    }
  };
};
