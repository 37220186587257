import { CONSTANTS } from 'constants/constants'
import { PRODUCTS } from 'constants/forioVariables'
import { useDispatch, useSelector } from 'react-redux'
import { changeValue as onChangeValue } from 'common'
import { changeVariable } from 'actions/productsActions'
import React from 'react'
import List from 'components/List'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { validatePackagingVariables, sumPackagingVariables } from 'common/utils'
import Title from 'components/TitleTablesValidation'
import { getVariablesOfTheState } from 'common/states'
import InputDisable from 'components/InputDisable'

const { EMPAQUE_A, EMPAQUE_B, EMPAQUE_C, EMPAQUE_D, EMPAQUE_E, EMPAQUE_F } = CONSTANTS.INVENTORY

const { PACKAGING } = CONSTANTS.PRODUCTS

const {
  PACKAGING_DECISION_EMP_A,
  PACKAGING_DECISION_EMP_B,
  PACKAGING_DECISION_EMP_C,
  PACKAGING_DECISION_EMP_D,
  PACKAGING_DECISION_EMP_E,
  PACKAGING_DECISION_EMP_F,
} = PRODUCTS

const returnData = (variables, changeValue, totalValue) => [
  createListRowForVariable(PACKAGING_DECISION_EMP_A, EMPAQUE_A, variables, changeValue),
  createListRowForVariable(PACKAGING_DECISION_EMP_B, EMPAQUE_B, variables, changeValue),
  createListRowForVariable(PACKAGING_DECISION_EMP_C, EMPAQUE_C, variables, changeValue),
  createListRowForVariable(PACKAGING_DECISION_EMP_D, EMPAQUE_D, variables, changeValue),
  createListRowForVariable(PACKAGING_DECISION_EMP_E, EMPAQUE_E, variables, changeValue),
  createListRowForVariable(PACKAGING_DECISION_EMP_F, EMPAQUE_F, variables, changeValue),
  {
    id: `list-${totalValue}`,
    label: 'Total',
    component: (
      <InputDisable
        value={totalValue}
        errorsToValidate={[totalValue !== '1.00' ? totalValue : '']}
        error={totalValue}
      />
    ),
  },
]

const Packaging = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesPackaging', dispatch, changeVariable)
  const variables = useSelector(state => state.products.currentValues.variablesPackaging)
  const productsNewValues = useSelector(state => state.products.newValues)
  const error = validatePackagingVariables({ ...getVariablesOfTheState(productsNewValues) })
  const totalValue = productsNewValues.variablesPackaging
    ? sumPackagingVariables(productsNewValues.variablesPackaging)
    : sumPackagingVariables(variables)

  return (
    <List
      listTitle={
        <Title
          title={PACKAGING}
          validationText="(Sum of values should be equal to 1)"
          isError={error}
        />
      }
      items={returnData(variables, changeValue, totalValue.toFixed(2))}
    />
  )
}

export default Packaging
