import React from 'react'
import { Line } from 'react-chartjs-2'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9ED',
    boxShadow: 'none',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '2px solid #E6E9ED',
    padding: '0.75rem 0.75rem',
    margin: '0 0.75rem',
    backgroundColor: '#FFFFFF',
  },
  title: {
    color: '#73879C',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
})

const LineChart = ({ data, titleChart, hideLabel }) => {
  let options = {}

  if (hideLabel) {
    options = {
      legend: {
        display: false,
      },
    }
  }

  const classes = useStyles()
  const newData = {
    ...data,
    datasets: data.datasets.map(item => ({
      ...item,
      pointRadius: 0,
    })),
  }
  return (
    <Card className={classes.card}>
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{titleChart}</h2>
      </div>
      <CardContent>
        <Line data={newData} options={options} />
      </CardContent>
    </Card>
  )
}
export default LineChart
