import React from "react";
import "./style.css";

const Loading = props => {
  const { message } = props;

  return (
    <div className="centerLoadingInformation">
      <div className="loading">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
      {message}
    </div>
  );
};

export default Loading;
