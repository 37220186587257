import { CONSTANTS } from 'constants/constants'
import { PRODUCTS } from 'constants/forioVariables'
import { useSelector } from 'react-redux'
import React from 'react'
import List from 'components/List'
import InputDisable from 'components/InputDisable'

const { PRODUCTION, BI_WEEKLY_PRODUCTION } = CONSTANTS.PRODUCTS

const { FORTNIGHTLY_PRODUCTION_DECISION_HELADO } = PRODUCTS

const returnData = variables => {
  return [
    {
      id: `list-${FORTNIGHTLY_PRODUCTION_DECISION_HELADO}`,
      label: BI_WEEKLY_PRODUCTION,
      component: <InputDisable value={variables[FORTNIGHTLY_PRODUCTION_DECISION_HELADO]} />,
    },
  ]
}

const Packaging = () => {
  const variables = useSelector(state => state.products.currentValues.variablesBiweeklyProduction)
  return <List listTitle={PRODUCTION} items={returnData(variables)} />
}

export default Packaging
