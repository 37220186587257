const OPERATIONS = {
  PASTEURIZERS_DESIRED: 'PasteurizersDesired',
  MATURATION_TANKS_DESIRED: 'MaturationTanksDesired',
  BATIDORAS_DESIRED: 'BatidorasDesired',
  CUARTO_FRIO_DESIRED: 'CuartoFrioDesired',
  PRODUCTION_DECISION_HELADO: 'FortnightlyProductionDecisionHelado',
  AGEING: 'Ageing',
  OVERRUN_PERCENTAGE: 'OverrunPercentage',
  SALARIES_IN_ECONOMY_SMOOTHED: 'SalariesInEconomySmoothed',
  MONTHLY_SALARY_PER_PERSON: 'SalaryMonthlySalaryPerPerson',
  PERSONNEL_DESIRED_PER_SHIFT: 'PersonnelDesiredPerShift',
  CAPACITY_FACTOR_POR_PERSONAL: 'CapacityCorrectionFactorPorPersonal',
  ROOKIES: 'Rookies',
  EXPERTS: 'Experts',
  DECISION_INVERTIR_MANTENIMIENTO: 'DecisionDeDolaresAInvertirEnMantenimientoPorQuincena',
  CAPACIDAD_DE_LAS_MAQUINAS: 'FactorDeCapacidadPorEstadoDeLasMaquinas',
  PASTEURIZATION_CAPACITY_AVAILABLE: 'TotalPasteurizationCapacityAvailablePerHour',
  PASTEURIZERS: 'Pasteurizers',
  CAPACIDAD_DE_BATIDO: 'CapacidadDeBatido',
  BATIDORAS: 'Batidoras',
  MATURATION_TANK_CAPACITY: 'MaturationTankCapacity',
  MATURATION_TANKS: 'MaturationTanks',
  WIP_AGEING_TANKS: 'WIPInAgeingTanks',
  PRODUCT_NOT_YET_FROZEN: 'InventoryOfProductNotYetFrozen',
  PRODUCT_READY_FOR_SALE: 'InventoryOfProductReadyForSale',
  QUALITY_POR_AGING: 'PorcentajeDelQualityGapCausadoPorAging',
  QUALITY_POR_OVERRUN: 'PorcentajeDelQualityGapCausadoPorOverrun',
  QUALITY_POR_MAQUINAS: 'PorcentajeDelQualityGapCausadoPorEstadoDeLasMaquinas',
  PERCEIVED_QUALITY: 'PerceivedQuality',
}

const PRODUCTS = {
  PACKAGING_DECISION_EMP_A: 'PackingDecision[EmpA]',
  PACKAGING_DECISION_EMP_B: 'PackingDecision[EmpB]',
  PACKAGING_DECISION_EMP_C: 'PackingDecision[EmpC]',
  PACKAGING_DECISION_EMP_D: 'PackingDecision[EmpD]',
  PACKAGING_DECISION_EMP_E: 'PackingDecision[EmpE]',
  PACKAGING_DECISION_EMP_F: 'PackingDecision[EmpF]',
  EMPAQUE_A_FLAVOR_DECISION_SABOR_C: 'EmpaqueAFlavorDecision[SaborC]',
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C: 'EmpaqueBFlavorDecisions[SaborC]',
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C: 'EmpaqueCFlavorDecisions[SaborC]',
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C: 'EmpaqueDFlavorDecisions[SaborC]',
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C: 'EmpaqueEFlavorDecisions[SaborC]',
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C: 'EmpaqueFFlavorDecisions[SaborC]',
  EMPAQUE_A_FLAVOR_DECISION_SABOR_F: 'EmpaqueAFlavorDecision[SaborF]',
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F: 'EmpaqueBFlavorDecisions[SaborF]',
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F: 'EmpaqueCFlavorDecisions[SaborF]',
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F: 'EmpaqueDFlavorDecisions[SaborF]',
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F: 'EmpaqueEFlavorDecisions[SaborF]',
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F: 'EmpaqueFFlavorDecisions[SaborF]',
  EMPAQUE_A_FLAVOR_DECISION_SABOR_L: 'EmpaqueAFlavorDecision[SaborL]',
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L: 'EmpaqueBFlavorDecisions[SaborL]',
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L: 'EmpaqueCFlavorDecisions[SaborL]',
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L: 'EmpaqueDFlavorDecisions[SaborL]',
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L: 'EmpaqueEFlavorDecisions[SaborL]',
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L: 'EmpaqueFFlavorDecisions[SaborL]',
  EMPAQUE_A_FLAVOR_DECISION_SABOR_M: 'EmpaqueAFlavorDecision[SaborM]',
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M: 'EmpaqueBFlavorDecisions[SaborM]',
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M: 'EmpaqueCFlavorDecisions[SaborM]',
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M: 'EmpaqueDFlavorDecisions[SaborM]',
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M: 'EmpaqueEFlavorDecisions[SaborM]',
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M: 'EmpaqueFFlavorDecisions[SaborM]',
  EMPAQUE_A_FLAVOR_DECISION_SABOR_R: 'EmpaqueAFlavorDecision[SaborR]',
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R: 'EmpaqueBFlavorDecisions[SaborR]',
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R: 'EmpaqueCFlavorDecisions[SaborR]',
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R: 'EmpaqueDFlavorDecisions[SaborR]',
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R: 'EmpaqueEFlavorDecisions[SaborR]',
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R: 'EmpaqueFFlavorDecisions[SaborR]',
  EMPAQUE_A_FLAVOR_DECISION_SABOR_V: 'EmpaqueAFlavorDecision[SaborV]',
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V: 'EmpaqueBFlavorDecisions[SaborV]',
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V: 'EmpaqueCFlavorDecisions[SaborV]',
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V: 'EmpaqueDFlavorDecisions[SaborV]',
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V: 'EmpaqueEFlavorDecisions[SaborV]',
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V: 'EmpaqueFFlavorDecisions[SaborV]',
  TOTAL_FG_INVENTORY_A: 'TotalFGInventoryA',
  PT_PAQUETE_A_SABOR_C: 'PTPaqueteASaborC',
  PT_PAQUETE_A_SABOR_F: 'PTPaqueteASaborF',
  PT_PAQUETE_A_SABOR_L: 'PTPaqueteASaborL',
  PT_PAQUETE_A_SABOR_M: 'PTPaqueteASaborM',
  PT_PAQUETE_A_SABOR_R: 'PTPaqueteASaborR',
  PT_PAQUETE_A_SABOR_V: 'PTPaqueteASaborV',
  TOTAL_FG_INVENTORY_B: 'TotalFGInventoryB',
  PT_PAQUETE_B_SABOR_C: 'PTPaqueteBSaborC',
  PT_PAQUETE_B_SABOR_F: 'PTPaqueteBSaborF',
  PT_PAQUETE_B_SABOR_L: 'PTPaqueteBSaborL',
  PT_PAQUETE_B_SABOR_M: 'PTPaqueteBSaborM',
  PT_PAQUETE_B_SABOR_R: 'PTPaqueteBSaborR',
  PT_PAQUETE_B_SABOR_V: 'PTPaqueteBSaborV',
  TOTAL_FG_INVENTORY_C: 'TotalFGInventoryC',
  PT_PAQUETE_C_SABOR_C: 'PTPaqueteCSaborC',
  PT_PAQUETE_C_SABOR_F: 'PTPaqueteCSaborF',
  PT_PAQUETE_C_SABOR_L: 'PTPaqueteCSaborL',
  PT_PAQUETE_C_SABOR_M: 'PTPaqueteCSaborM',
  PT_PAQUETE_C_SABOR_R: 'PTPaqueteCSaborR',
  PT_PAQUETE_C_SABOR_V: 'PTPaqueteCSaborV',
  TOTAL_FG_INVENTORY_D: 'TotalFGInventoryD',
  PT_PAQUETE_D_SABOR_C: 'PTPaqueteDSaborC',
  PT_PAQUETE_D_SABOR_F: 'PTPaqueteDSaborF',
  PT_PAQUETE_D_SABOR_L: 'PTPaqueteDSaborL',
  PT_PAQUETE_D_SABOR_M: 'PTPaqueteDSaborM',
  PT_PAQUETE_D_SABOR_R: 'PTPaqueteDSaborR',
  PT_PAQUETE_D_SABOR_V: 'PTPaqueteDSaborV',
  TOTAL_FG_INVENTORY_E: 'TotalFGInventoryE',
  PT_PAQUETE_E_SABOR_C: 'PTPaqueteESaborC',
  PT_PAQUETE_E_SABOR_F: 'PTPaqueteESaborF',
  PT_PAQUETE_E_SABOR_L: 'PTPaqueteESaborL',
  PT_PAQUETE_E_SABOR_M: 'PTPaqueteESaborM',
  PT_PAQUETE_E_SABOR_R: 'PTPaqueteESaborR',
  PT_PAQUETE_E_SABOR_V: 'PTPaqueteESaborV',
  TOTAL_FG_INVENTORY_F: 'TotalFGInventoryF',
  PT_PAQUETE_F_SABOR_C: 'PTPaqueteFSaborC',
  PT_PAQUETE_F_SABOR_F: 'PTPaqueteFSaborF',
  PT_PAQUETE_F_SABOR_L: 'PTPaqueteFSaborL',
  PT_PAQUETE_F_SABOR_M: 'PTPaqueteFSaborM',
  PT_PAQUETE_F_SABOR_R: 'PTPaqueteFSaborR',
  PT_PAQUETE_F_SABOR_V: 'PTPaqueteFSaborV',
  PACKING_PRIORITIES_EMP_A: 'PackingPriorities[EmpA]',
  PACKING_PRIORITIES_EMP_B: 'PackingPriorities[EmpB]',
  PACKING_PRIORITIES_EMP_C: 'PackingPriorities[EmpC]',
  PACKING_PRIORITIES_EMP_D: 'PackingPriorities[EmpD]',
  PACKING_PRIORITIES_EMP_E: 'PackingPriorities[EmpE]',
  PACKING_PRIORITIES_EMP_F: 'PackingPriorities[EmpF]',
  FLAVOR_PRIORITIES_SABOR_C: 'FlavorPriorities[SaborC]',
  FLAVOR_PRIORITIES_SABOR_F: 'FlavorPriorities[SaborF]',
  FLAVOR_PRIORITIES_SABOR_L: 'FlavorPriorities[SaborL]',
  FLAVOR_PRIORITIES_SABOR_M: 'FlavorPriorities[SaborM]',
  FLAVOR_PRIORITIES_SABOR_R: 'FlavorPriorities[SaborR]',
  FLAVOR_PRIORITIES_SABOR_V: 'FlavorPriorities[SaborV]',
  FORTNIGHTLY_PRODUCTION_DECISION_HELADO: 'FortnightlyProductionDecisionHelado',
}

const MARKETING_AND_LOGISTICS = {
  PRECIO_EMPAQUE_A: 'PrecioPorGalonEmpaqueA',
  PRECIO_EMPAQUE_B: 'PrecioPorGalonEmpaqueB',
  PRECIO_EMPAQUE_C: 'PrecioPorGalonEmpaqueC',
  PRECIO_EMPAQUE_D: 'PrecioPorGalonEmpaqueD',
  PRECIO_EMPAQUE_E: 'PrecioPorGalonEmpaqueE',
  PRECIO_EMPAQUE_F: 'PrecioPorGalonEmpaqueF',
  DECISION_A_INVERTIR_EN_MERCADEO: 'DecisionDePorcentajeDeLasVentasAInvertirEnMercadeo',
  TRUCKS_DESIRED: 'TrucksDesired',
  MARKET_SHARE_TOTAL: 'MarketShareTotalPercentage',
  VENTA_TOTAL_ACUMULADA: 'VentaTotalnGalonesAcumulada',
  PRECIO_A_COMPETENCIA: 'PrecioACompetencia',
  PRECIO_B_COMPETENCIA: 'PrecioBCompetencia',
  PRECIO_C_COMPETENCIA: 'PrecioCCompetencia',
  PRECIO_D_COMPETENCIA: 'PrecioDCompetencia',
  PRECIO_E_COMPETENCIA: 'PrecioECompetencia',
  PRECIO_F_COMPETENCIA: 'PrecioFCompetencia',
  MARKET_SHARE_EMPAQUE_A: 'AverageMarketShareEmpaqueA',
  MARKET_SHARE_EMPAQUE_B: 'AverageMarketShareEmpaqueB',
  MARKET_SHARE_EMPAQUE_C: 'AverageMarketShareEmpaqueC',
  MARKET_SHARE_EMPAQUE_D: 'AverageMarketShareEmpaqueD',
  MARKET_SHARE_EMPAQUE_E: 'AverageMarketShareEmpaqueE',
  MARKET_SHARE_EMPAQUE_F: 'AverageMarketShareEmpaqueF',
  FILL_RATE_EMPAQUES_GRANDES: 'AverageFillRateEmpaquesGrandes',
  FILL_RATE_EMPAQUES_PEQUEÑOS: 'AverageFillRAteEmpaquesPequeños',
  CAMIONES_COMPETENCIA: 'CamionesCompetenciaFuerte',
  TOTAL_CAMIONES: 'TotalCamiones',
  VENTA_DE_A_SABOR_C: 'VentaDeASaborCparaTablaAcc',
  VENTA_DE_A_SABOR_F: 'VentaDeASaborFparaTablaAcc',
  VENTA_DE_A_SABOR_L: 'VentaDeASaborLparaTablaAcc',
  VENTA_DE_A_SABOR_M: 'VentaDeASaborMparaTablaAcc',
  VENTA_DE_A_SABOR_R: 'VentaDeASaborRparaTablaAcc',
  VENTA_DE_A_SABOR_V: 'VentaDeASaborVparaTablaAcc',
  VENTA_DE_B_SABOR_C: 'VentaDeBSaborCparaTablaAcc',
  VENTA_DE_B_SABOR_F: 'VentaDeBSaborFparaTablaAcc',
  VENTA_DE_B_SABOR_L: 'VentaDeBSaborLparaTablaAcc',
  VENTA_DE_B_SABOR_M: 'VentaDeBSaborMparaTablaAcc',
  VENTA_DE_B_SABOR_R: 'VentaDeBSaborRparaTablaAcc',
  VENTA_DE_B_SABOR_V: 'VentaDeBSaborVparaTablaAcc',
  VENTA_DE_C_SABOR_C: 'VentaDeCSaborCparaTablaAcc',
  VENTA_DE_C_SABOR_F: 'VentaDeCSaborFparaTablaAcc',
  VENTA_DE_C_SABOR_L: 'VentaDeCSaborLparaTablaAcc',
  VENTA_DE_C_SABOR_M: 'VentaDeCSaborMparaTablaAcc',
  VENTA_DE_C_SABOR_R: 'VentaDeCSaborRparaTablaAcc',
  VENTA_DE_C_SABOR_V: 'VentaDeCSaborVparaTablaAcc',
  VENTA_DE_D_SABOR_C: 'VentaDeDSaborCparaTablaAcc',
  VENTA_DE_D_SABOR_F: 'VentaDeDSaborFparaTablaAcc',
  VENTA_DE_D_SABOR_L: 'VentaDeDSaborLparaTablaAcc',
  VENTA_DE_D_SABOR_M: 'VentaDeDSaborMparaTablaAcc',
  VENTA_DE_D_SABOR_R: 'VentaDeDSaborRparaTablaAcc',
  VENTA_DE_D_SABOR_V: 'VentaDeDSaborVparaTablaAcc',
  VENTA_DE_E_SABOR_C: 'VentaDeESaborCparaTablaAcc',
  VENTA_DE_E_SABOR_F: 'VentaDeESaborFparaTablaAcc',
  VENTA_DE_E_SABOR_L: 'VentaDeESaborLparaTablaAcc',
  VENTA_DE_E_SABOR_M: 'VentaDeESaborMparaTablaAcc',
  VENTA_DE_E_SABOR_R: 'VentaDeESaborRparaTablaAcc',
  VENTA_DE_E_SABOR_V: 'VentaDeESaborVparaTablaAcc',
  VENTA_DE_F_SABOR_C: 'VentaDeFSaborCparaTablaAcc',
  VENTA_DE_F_SABOR_F: 'VentaDeFSaborFparaTablaAcc',
  VENTA_DE_F_SABOR_L: 'VentaDeFSaborLparaTablaAcc',
  VENTA_DE_F_SABOR_M: 'VentaDeFSaborMparaTablaAcc',
  VENTA_DE_F_SABOR_R: 'VentaDeFSaborRparaTablaAcc',
  VENTA_DE_F_SABOR_V: 'VentaDeFSaborVparaTablaAcc',
}

const RESULTS = {
  COSTO_ACUMULADO_DE_MATERIALES_VENDIDOS_SIN_LIMPIA_PARA_SABLE:
    'CostoAcumuladoDeMaterialesVendidosSinLimpiaParaSable',
  COSTOS_ACUMULADOS_POR_MANO_DE_OBRA_SIN_LIMPIA_PARA_SABLE:
    'CostosAcumuladosPorManoDeObraSinLimpiaParaSable',
  DEPRECIACION_ACUMULADA_PARA_COSTOS_VARIABLES_DE_PRODUCCIÓN_PARA_SABLE:
    'DepreciacionAcumuladaParaCostosVariablesDeProducciónParaSable',
  UTILITIES_COST_ACUMULADO_PARA_SABLE: 'UtilitiesCostAcumuladoParaSable',
  ACCUMULATED_MAINTENANCE_EXPENSES_PARA_SABLE: 'AccumulatedMaintenanceExpensesParaSable',
  TOTAL_COSTO_DE_VENTAS_PARA_SABLE: 'TotalCostoDeVentasParaSable',
  COSTO_ACUMULADO_DE_CAMIONES_PARA_SABLE: 'CostoAcumuladoDeCamionesParaSable',
  DEPRECIACION_ACUMULADA_DE_CARROS_PARA_SABLE: 'DepreciaciónAcumuladaDeCarrosParaSable',
  ACCUMULATED_EGRESOS_POR_SALES_AND_ADMINISTRATION_PARA_SABLE:
    'AccumulatedEgresosPorSalesAndAdministrationParaSable',
  ACCUMULATED_MARKETING_EXPENSES_PARA_SABLE: 'AccumulatedMarketingExpensesParaSable',
  ACCUMULATED_HIRING_COST_PARA_SABLE: 'AccumulatedHiringCostParaSable',
  ACCUMULATED_FIRING_COST_PARA_SABLE: 'AccumulatedFiringCostParaSable',
  ACCUMULATED_INVENTORY_HOLDING_COST: 'AccumulatedInventoryHoldingCost',
  DEPRECIACION_ACUMULADA_PLANTA_EDIFICIOS_YOTROS_PARA_SABLE:
    'DepreciacionAcumuladaPlantaEdificiosYOtrosParaSable',
  ACCUMULATED_INTEREST_EXPENSES_PARA_SABLE: 'AccumulatedInterestExpensesParaSable',
  UTILIDAD_ANTES_DE_IMPUESTOS_PARA_SABLE: 'UtilidadAntesDeIMpuestosParaSable',
  ACCUMULATED_IMPUESTO_SOBRE_LA_RENTA_PARA_SABLE: 'AccumulatedImpuestoSobreLaRentaParaSable',
  CAJA_PARA_SABLE: 'CajaParaSable',
  CUENTAS_POR_COBRAR_PARA_SABLE: 'CuentasPorCobrarParaSable',
  INVENTARIO_TOTAL_DOLARES_PARA_SABLE: 'InventarioTotalDolaresParaSable',
  TOTAL_ACTIVO_CIRCULANTE_PARA_SABLE: 'TotalActivoCirculanteParaSable',
  TOTAL_ACTIVO_FIJO_PARA_SABLE: 'TotalActivoFijoParaSable',
  TOTAL_ACTIVO_PARA_SABLE: 'TotalActivoParaSable',
  CUENTAS_POR_PAGAR_PROVEEDORES_PARA_SABLE: 'CuentasPorPagarProveedoresParaSable',
  PRESTAMO_PARA_SABLE: 'PrestamoParaSable',
  TOTAL_PASIVO_PARA_SABLE: 'TotalPasivoParaSable',
  ACCIONES_PARA_SABLE: 'AccionesParaSable',
  UTILIDADES_RETENIDAS_PARA_SABLE: 'UtilidadesRetenidasParaSable',
  TOTAL_CAPITAL_PARA_SABLE: 'TotalCapitalParaSable',
  TOTAL_PASIVO_MAS_CAPITAL_PARA_SABLE: 'TotalPasivoMasCapitalParaSable',
  ACCUMULATED_TOTAL_SALES_PARA_SABLE: 'AccumulatedTotalSalesParaSable',
  UTILIDAD_BRUTA_ACUMULADA_PARA_SABLE: 'UtilidadBrutaAcumuladaParaSable',
  ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE: 'AccumulatedNetIncomeAfterTaxesParaSable',
  TOTAL_ACCUMULATED_OVERHEAD_EXPENSES_PARA_SABLE: 'TotalAccumulatedOverheadExpensesParaSable',
  DIAS_DE_COBRO: 'DiasDeCobro',
  DEBT_AMORTIZATION_PERCENTAGE_TRIMESTER_DECISION: 'DebtAmortizationPercentageTrimesterDecision',
}

const INVENTORY = {
  CANTIDAD_PEDIDA_EMPAQUES_A: 'CantidadPedidaEmpaquesA',
  PUNTO_DE_REORDEN_EMPAQUES_A: 'PuntoDeReordenEmpaquesA',
  CANTIDAD_PEDIDA_EMPAQUES_B: 'CantidadPedidaEmpaquesB',
  PUNTO_DE_REORDEN_EMPAQUES_B: 'PuntoDeReordenEmpaquesB',
  CANTIDAD_PEDIDA_EMPAQUES_C: 'CantidadPedidaEmpaquesC',
  PUNTO_DE_REORDEN_EMPAQUES_C: 'PuntoDeReordenEmpaquesC',
  CANTIDAD_PEDIDA_EMPAQUES_D: 'CantidadPedidaEmpaquesD',
  PUNTO_DE_REORDEN_EMPAQUES_D: 'PuntoDeReordenEmpaquesD',
  CANTIDAD_PEDIDA_EMPAQUES_E: 'CantidadPedidaEmpaquesE',
  PUNTO_DE_REORDEN_EMPAQUES_E: 'PuntoDeReordenEmpaquesE',
  CANTIDAD_PEDIDA_EMPAQUES_F: 'CantidadPedidaEmpaquesF',
  PUNTO_DE_REORDEN_EMPAQUES_F: 'PuntoDeReordenEmpaquesF',
  CANTIDAD_PEDIDA_LECHE: 'CantidadPedidaLeche',
  PUNTO_DE_REORDEN_LECHE: 'PuntoDeReordenLeche',
  CANTIDAD_PEDIDA_OTROS: 'CantidadPedidaOtros',
  PUNTO_DE_REORDEN_OTROS: 'PuntoDeReordenOtros',
  INVENTARIO_DE_EMPAQUES_A_PARA_TABLA: 'InventarioDeEmpaquesAparaTabla',
  INVENTARIO_DE_EMPAQUES_B_PARA_TABLA: 'InventarioDeEmpaquesBparaTabla',
  INVENTARIO_DE_EMPAQUES_C_PARA_TABLA: 'InventarioDeEmpaquesCparaTabla',
  INVENTARIO_DE_EMPAQUES_D_PARA_TABLA: 'InventarioDeEmpaquesDparaTabla',
  INVENTARIO_DE_EMPAQUES_E_PARA_TABLA: 'InventarioDeEmpaquesEparaTabla',
  INVENTARIO_DE_EMPAQUES_F_PARA_TABLA: 'InventarioDeEmpaquesFparaTabla',
  INVENTARIO_DE_LECHE: 'InventarioDeLeche',
  INVENTARIO_DE_OTROS: 'InventarioDeOtros',
}

const EXCEL = {
  TIME: 'Time',
  EMPAQUE_A_FLAVOR_DECISION: 'EmpaqueAFlavorDecision',
  EMPAQUE_B_FLAVOR_DECISIONS: 'EmpaqueBFlavorDecisions',
  EMPAQUE_C_FLAVOR_DECISIONS: 'EmpaqueCFlavorDecisions',
  EMPAQUE_D_FLAVOR_DECISIONS: 'EmpaqueDFlavorDecisions',
  EMPAQUE_E_FLAVOR_DECISIONS: 'EmpaqueEFlavorDecisions',
  EMPAQUE_F_FLAVOR_DECISIONS: 'EmpaqueFFlavorDecisions',
  TOTAL_FG_INVENTORY_A: 'TotalFGInventoryA',
  TOTAL_FG_INVENTORY_B: 'TotalFGInventoryB',
  TOTAL_FG_INVENTORY_C: 'TotalFGInventoryC',
  TOTAL_FG_INVENTORY_D: 'TotalFGInventoryD',
  TOTAL_FG_INVENTORY_E: 'TotalFGInventoryE',
  TOTAL_FG_INVENTORY_F: 'TotalFGInventoryF',
  WIP_IN_AGEING_TANKS: 'WIPInAgeingTanks',
  INVENTORY_OF_PRODUCT_NOT_YET_FROZEN: 'InventoryOfProductNotYetFrozen',
  INVENTORY_OF_PRODUCT_READY_FOR_SALE: 'InventoryOfProductReadyForSale',
  PRECIO_A_COMPETENCIA: 'PrecioACompetencia',
  PRECIO_B_COMPETENCIA: 'PrecioBCompetencia',
  PRECIO_C_COMPETENCIA: 'PrecioCCompetencia',
  PRECIO_D_COMPETENCIA: 'PrecioDCompetencia',
  PRECIO_E_COMPETENCIA: 'PrecioECompetencia',
  PRECIO_F_COMPETENCIA: 'PrecioFCompetencia',
  AVERAGE_MARKET_SHARE_EMPAQUE_A: 'AverageMarketShareEmpaqueA',
  AVERAGE_MARKET_SHARE_EMPAQUE_B: 'AverageMarketShareEmpaqueB',
  AVERAGE_MARKET_SHARE_EMPAQUE_C: 'AverageMarketShareEmpaqueC',
  AVERAGE_MARKET_SHARE_EMPAQUE_D: 'AverageMarketShareEmpaqueD',
  AVERAGE_MARKET_SHARE_EMPAQUE_E: 'AverageMarketShareEmpaqueE',
  AVERAGE_MARKET_SHARE_EMPAQUE_F: 'AverageMarketShareEmpaqueF',
  CAMIONES_COMPETENCIA_FUERTE: 'CamionesCompetenciaFuerte',
  TOTAL_CAMIONES: 'TotalCamiones',
  AVERAGE_FILL_RATE_EMPAQUES_GRANDES: 'AverageFillRateEmpaquesGrandes',
  AVERAGE_FILL_RATE_EMPAQUES_PEQUEÑOS: 'AverageFillRAteEmpaquesPequeños',
  VENTA_DE_A_SABOR_C_PARA_TABLA_ACC: 'VentaDeASaborCparaTablaAcc',
  VENTA_DE_A_SABOR_F_PARA_TABLA_ACC: 'VentaDeASaborFparaTablaAcc',
  VENTA_DE_A_SABOR_L_PARA_TABLA_ACC: 'VentaDeASaborLparaTablaAcc',
  VENTA_DE_A_SABOR_M_PARA_TABLA_ACC: 'VentaDeASaborMparaTablaAcc',
  VENTA_DE_A_SABOR_R_PARA_TABLA_ACC: 'VentaDeASaborRparaTablaAcc',
  VENTA_DE_A_SABOR_V_PARA_TABLA_ACC: 'VentaDeASaborVparaTablaAcc',
  VENTA_DE_B_SABOR_C_PARA_TABLA_ACC: 'VentaDeBSaborCparaTablaAcc',
  VENTA_DE_B_SABOR_F_PARA_TABLA_ACC: 'VentaDeBSaborFparaTablaAcc',
  VENTA_DE_B_SABOR_L_PARA_TABLA_ACC: 'VentaDeBSaborLparaTablaAcc',
  VENTA_DE_B_SABOR_M_PARA_TABLA_ACC: 'VentaDeBSaborMparaTablaAcc',
  VENTA_DE_B_SABOR_R_PARA_TABLA_ACC: 'VentaDeBSaborRparaTablaAcc',
  VENTA_DE_B_SABOR_V_PARA_TABLA_ACC: 'VentaDeBSaborVparaTablaAcc',
  VENTA_DE_C_SABOR_C_PARA_TABLA_ACC: 'VentaDeCSaborCparaTablaAcc',
  VENTA_DE_C_SABOR_F_PARA_TABLA_ACC: 'VentaDeCSaborFparaTablaAcc',
  VENTA_DE_C_SABOR_L_PARA_TABLA_ACC: 'VentaDeCSaborLparaTablaAcc',
  VENTA_DE_C_SABOR_M_PARA_TABLA_ACC: 'VentaDeCSaborMparaTablaAcc',
  VENTA_DE_C_SABOR_R_PARA_TABLA_ACC: 'VentaDeCSaborRparaTablaAcc',
  VENTA_DE_C_SABOR_V_PARA_TABLA_ACC: 'VentaDeCSaborVparaTablaAcc',
  VENTA_DE_D_SABOR_C_PARA_TABLA_ACC: 'VentaDeDSaborCparaTablaAcc',
  VENTA_DE_D_SABOR_F_PARA_TABLA_ACC: 'VentaDeDSaborFparaTablaAcc',
  VENTA_DE_D_SABOR_L_PARA_TABLA_ACC: 'VentaDeDSaborLparaTablaAcc',
  VENTA_DE_D_SABOR_M_PARA_TABLA_ACC: 'VentaDeDSaborMparaTablaAcc',
  VENTA_DE_D_SABOR_R_PARA_TABLA_ACC: 'VentaDeDSaborRparaTablaAcc',
  VENTA_DE_D_SABOR_V_PARA_TABLA_ACC: 'VentaDeDSaborVparaTablaAcc',
  VENTA_DE_E_SABOR_C_PARA_TABLA_ACC: 'VentaDeESaborCparaTablaAcc',
  VENTA_DE_E_SABOR_F_PARA_TABLA_ACC: 'VentaDeESaborFparaTablaAcc',
  VENTA_DE_E_SABOR_L_PARA_TABLA_ACC: 'VentaDeESaborLparaTablaAcc',
  VENTA_DE_E_SABOR_M_PARA_TABLA_ACC: 'VentaDeESaborMparaTablaAcc',
  VENTA_DE_E_SABOR_R_PARA_TABLA_ACC: 'VentaDeESaborRparaTablaAcc',
  VENTA_DE_E_SABOR_V_PARA_TABLA_ACC: 'VentaDeESaborVparaTablaAcc',
  VENTA_DE_F_SABOR_C_PARA_TABLA_ACC: 'VentaDeFSaborCparaTablaAcc',
  VENTA_DE_F_SABOR_F_PARA_TABLA_ACC: 'VentaDeFSaborFparaTablaAcc',
  VENTA_DE_F_SABOR_L_PARA_TABLA_ACC: 'VentaDeFSaborLparaTablaAcc',
  VENTA_DE_F_SABOR_M_PARA_TABLA_ACC: 'VentaDeFSaborMparaTablaAcc',
  VENTA_DE_F_SABOR_R_PARA_TABLA_ACC: 'VentaDeFSaborRparaTablaAcc',
  VENTA_DE_F_SABOR_V_PARA_TABLA_ACC: 'VentaDeFSaborVparaTablaAcc',
  INVENTARIO_DE_EMPAQUES_A_PARA_TABLA: 'InventarioDeEmpaquesAparaTabla',
  INVENTARIO_DE_EMPAQUES_B_PARA_TABLA: 'InventarioDeEmpaquesBparaTabla',
  INVENTARIO_DE_EMPAQUES_C_PARA_TABLA: 'InventarioDeEmpaquesCparaTabla',
  INVENTARIO_DE_EMPAQUES_D_PARA_TABLA: 'InventarioDeEmpaquesDparaTabla',
  INVENTARIO_DE_EMPAQUES_E_PARA_TABLA: 'InventarioDeEmpaquesEparaTabla',
  INVENTARIO_DE_EMPAQUES_F_PARA_TABLA: 'InventarioDeEmpaquesFparaTabla',
  INVENTARIO_DE_LECHE: 'InventarioDeLeche',
  INVENTARIO_DE_OTROS: 'InventarioDeOtros',
  ACCUMULATED_TOTAL_SALES_PARA_SABLE: 'AccumulatedTotalSalesParaSable',
  COSTO_ACUMULADO_DE_MATERIALES_VENDIDOS_SIN_LIMPIA_PARA_SABLE:
    'CostoAcumuladoDeMaterialesVendidosSinLimpiaParaSable',
  COSTOS_ACUMULADOS_POR_MANO_DE_OBRA_SIN_LIMPIA_PARA_SABLE:
    'CostosAcumuladosPorManoDeObraSinLimpiaParaSable',
  DEPRECIACION_ACUMULADA_PARA_COSTOS_VARIABLES_DE_PRODUCCIÓN_PARA_SABLE:
    'DepreciacionAcumuladaParaCostosVariablesDeProducciónParaSable',
  UTILITIES_COST_ACUMULADO_PARA_SABLE: 'UtilitiesCostAcumuladoParaSable',
  ACCUMULATED_MAINTENANCE_EXPENSES_PARA_SABLE: 'AccumulatedMaintenanceExpensesParaSable',
  TOTAL_COSTO_DE_VENTAS_PARA_SABLE: 'TotalCostoDeVentasParaSable',
  UTILIDAD_BRUTA_ACUMULADA_PARA_SABLE: 'UtilidadBrutaAcumuladaParaSable',
  COSTO_ACUMULADO_DE_CAMIONES_PARA_SABLE: 'CostoAcumuladoDeCamionesParaSable',
  DEPRECIACIÓN_ACUMULADA_DE_CARROS_PARA_SABLE: 'DepreciaciónAcumuladaDeCarrosParaSable',
  ACCUMULATED_EGRESOS_POR_SALES_AND_ADMINISTRATION_PARA_SABLE:
    'AccumulatedEgresosPorSalesAndAdministrationParaSable',
  ACCUMULATED_MARKETING_EXPENSES_PARA_SABLE: 'AccumulatedMarketingExpensesParaSable',
  ACCUMULATED_HIRING_COST_PARA_SABLE: 'AccumulatedHiringCostParaSable',
  ACCUMULATED_FIRING_COST_PARA_SABLE: 'AccumulatedFiringCostParaSable',
  ACCUMULATED_INVENTORY_HOLDING_COST: 'AccumulatedInventoryHoldingCost',
  DEPRECIACION_ACUMULADA_PLANTA_EDIFICIOS_Y_OTROS_PARA_SABLE:
    'DepreciacionAcumuladaPlantaEdificiosYOtrosParaSable',
  ACCUMULATED_INTEREST_EXPENSES_PARA_SABLE: 'AccumulatedInterestExpensesParaSable',
  TOTAL_ACCUMULATED_OVERHEAD_EXPENSES_PARA_SABLE: 'TotalAccumulatedOverheadExpensesParaSable',
  UTILIDAD_ANTES_DE_IMPUESTOS_PARA_SABLE: 'UtilidadAntesDeIMpuestosParaSable',
  ACCUMULATED_IMPUESTO_SOBRE_LA_RENTA_PARA_SABLE: 'AccumulatedImpuestoSobreLaRentaParaSable',
  ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE: 'AccumulatedNetIncomeAfterTaxesParaSable',
  CAJA_PARA_SABLE: 'CajaParaSable',
  CUENTAS_POR_COBRAR_PARA_SABLE: 'CuentasPorCobrarParaSable',
  INVENTARIO_TOTAL_DOLARES_PARA_SABLE: 'InventarioTotalDolaresParaSable',
  TOTAL_ACTIVO_CIRCULANTE_PARA_SABLE: 'TotalActivoCirculanteParaSable',
  TOTAL_ACTIVO_FIJO_PARA_SABLE: 'TotalActivoFijoParaSable',
  TOTAL_ACTIVO_PARA_SABLE: 'TotalActivoParaSable',
  CUENTAS_POR_PAGAR_PROVEEDORES_PARA_SABLE: 'CuentasPorPagarProveedoresParaSable',
  PRESTAMO_PARA_SABLE: 'PrestamoParaSable',
  TOTAL_PASIVO_PARA_SABLE: 'TotalPasivoParaSable',
  ACCIONES_PARA_SABLE: 'AccionesParaSable',
  UTILIDADES_RETENIDAS_PARA_SABLE: 'UtilidadesRetenidasParaSable',
  TOTAL_CAPITAL_PARA_SABLE: 'TotalCapitalParaSable',
  TOTAL_PASIVO_MAS_CAPITAL_PARA_SABLE: 'TotalPasivoMasCapitalParaSable',
}

const HEADER = {
  TIME: 'Time',
}

export { HEADER, PRODUCTS, OPERATIONS, MARKETING_AND_LOGISTICS, INVENTORY, RESULTS, EXCEL }
