import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9ED',
    boxShadow: 'none',
    color: '#73879C',
    marginBottom: '1.5rem',
  },
  value: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
})

export default useStyles
