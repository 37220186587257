import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Slider, withStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 800,
    minWidth: 200,
    width: '30vw',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  sliderPaper: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: '.875rem',
    width: '2rem',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
}))

const CustomSlider = withStyles({
  root: {
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 13,
    width: 13,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -6,
    marginLeft: -7,
  },
  active: {},
  track: {
    height: 3,
    marginRight: 5,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
    marginLeft: 3,
  },
})(Slider)

function valuetext(value) {
  return `${value}`
}

const RangeSlider = ({ id, initialValue, change, max, min, step }) => {
  const [value, setValue] = React.useState(initialValue)

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
  }

  const classes = useStyles()

  const handleSliderChangeCommitted = (event, newValue) => {
    change({ id, value: newValue })
  }

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={6} sm={7}>
        <CustomSlider
          id={id}
          value={typeof value === 'number' ? value : 0}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-always"
          marks
          step={typeof step !== 'number' ? 0.01 : step}
          min={typeof min !== 'number' ? 0 : min}
          max={typeof max !== 'number' ? 1 : max}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
        />
      </Grid>
      <Grid item xl="auto" xs="auto" sm="auto">
        <Paper className={classes.sliderPaper}>{`${value}`}</Paper>
      </Grid>
    </Grid>
  )
}

export default RangeSlider
