import { PRODUCTS } from 'constants/forioVariables'

const {
  PACKAGING_DECISION_EMP_A,
  PACKAGING_DECISION_EMP_B,
  PACKAGING_DECISION_EMP_C,
  PACKAGING_DECISION_EMP_D,
  PACKAGING_DECISION_EMP_E,
  PACKAGING_DECISION_EMP_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_C,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_F,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_L,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_M,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_R,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_V,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V,
} = PRODUCTS

const getWhichColumnsHaveError = columns => {
  const compare = item => Number(Object.values(item)[0].toFixed(2)) !== 1
  const columnsWithErrors = columns.filter(item => item && Object.values(item)[0] && compare(item))
  return columnsWithErrors.map(item => Object.keys(item)[0])
}

const sumFlavorsVariablesPackageA = variables => {
  const total =
    variables[EMPAQUE_A_FLAVOR_DECISION_SABOR_C] +
    variables[EMPAQUE_A_FLAVOR_DECISION_SABOR_F] +
    variables[EMPAQUE_A_FLAVOR_DECISION_SABOR_L] +
    variables[EMPAQUE_A_FLAVOR_DECISION_SABOR_M] +
    variables[EMPAQUE_A_FLAVOR_DECISION_SABOR_R] +
    variables[EMPAQUE_A_FLAVOR_DECISION_SABOR_V]

  return total
}

const sumFlavorsVariablesPackageB = variables => {
  const total =
    variables[EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C] +
    variables[EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F] +
    variables[EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L] +
    variables[EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M] +
    variables[EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R] +
    variables[EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V]

  return total
}

const sumFlavorsVariablesPackageC = variables => {
  const total =
    variables[EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C] +
    variables[EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F] +
    variables[EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L] +
    variables[EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M] +
    variables[EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R] +
    variables[EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V]

  return total
}

const sumFlavorsVariablesPackageD = variables => {
  const total =
    variables[EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C] +
    variables[EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F] +
    variables[EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L] +
    variables[EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M] +
    variables[EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R] +
    variables[EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V]

  return total
}

const sumFlavorsVariablesPackageE = variables => {
  const total =
    variables[EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C] +
    variables[EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F] +
    variables[EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L] +
    variables[EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M] +
    variables[EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R] +
    variables[EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V]

  return total
}

const sumFlavorsVariablesPackageF = variables => {
  const total =
    variables[EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C] +
    variables[EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F] +
    variables[EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L] +
    variables[EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M] +
    variables[EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R] +
    variables[EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V]

  return total
}

const validateFlavorsVariables = variablesToValidate => {
  let error = {}

  const packageA = sumFlavorsVariablesPackageA(variablesToValidate)
  const packageB = sumFlavorsVariablesPackageB(variablesToValidate)
  const packageC = sumFlavorsVariablesPackageC(variablesToValidate)
  const packageD = sumFlavorsVariablesPackageD(variablesToValidate)
  const packageE = sumFlavorsVariablesPackageE(variablesToValidate)
  const packageF = sumFlavorsVariablesPackageF(variablesToValidate)

  const columnsToValidate = [
    { packageA },
    { packageB },
    { packageC },
    { packageD },
    { packageE },
    { packageF },
  ]

  if (
    (packageA && Number(packageA.toFixed(2)) !== 1) ||
    (packageB && Number(packageB.toFixed(2)) !== 1) ||
    (packageC && Number(packageC.toFixed(2)) !== 1) ||
    (packageD && Number(packageD.toFixed(2)) !== 1) ||
    (packageE && Number(packageE.toFixed(2)) !== 1) ||
    (packageF && Number(packageF.toFixed(2)) !== 1)
  ) {
    error = {
      message: 'There was an error, please check values',
      error: true,
      whichColumns: getWhichColumnsHaveError(columnsToValidate),
    }
  }

  return error
}

const sumPackagingVariables = variables => {
  const total =
    variables[PACKAGING_DECISION_EMP_A] +
    variables[PACKAGING_DECISION_EMP_B] +
    variables[PACKAGING_DECISION_EMP_C] +
    variables[PACKAGING_DECISION_EMP_D] +
    variables[PACKAGING_DECISION_EMP_E] +
    variables[PACKAGING_DECISION_EMP_F]

  return total
}

const validatePackagingVariables = variablesToValidate => {
  const variablesSum = sumPackagingVariables(variablesToValidate)

  const error = variablesSum && Number(variablesSum.toFixed(2)) !== 1
  return error
}

const validateVariables = variablesToValidate => {
  const packagingError = validatePackagingVariables(variablesToValidate)
  const flavorsError = validateFlavorsVariables(variablesToValidate)

  if (packagingError || (flavorsError && flavorsError.error)) {
    return {
      message: 'There was an error, please check values',
      error: true,
    }
  }

  return {}
}

const getVariablesChanged = (currentValues, newValues) => {
  let variablesChanged = {}
  for (const variable in newValues) {
    if (currentValues[`${variable}`] !== newValues[`${variable}`]) {
      variablesChanged = {
        ...variablesChanged,
        [`${variable}`]: newValues[`${variable}`],
      }
    }
  }

  return variablesChanged
}

export {
  getVariablesChanged,
  validatePackagingVariables,
  validateVariables,
  validateFlavorsVariables,
  sumFlavorsVariablesPackageA,
  sumFlavorsVariablesPackageB,
  sumFlavorsVariablesPackageC,
  sumFlavorsVariablesPackageD,
  sumFlavorsVariablesPackageE,
  sumFlavorsVariablesPackageF,
  sumPackagingVariables,
}
