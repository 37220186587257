import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { OPERATIONS } from 'constants/forioVariables'
import { changeVariable } from 'actions/operationsActions'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { changeValue as onChangeValue } from 'common'

const {
  PRODUCTION,
  DESIRED_ICE_CREAM_PRODUCTION,
  DESIRED_AGEING,
  DESIRED_OVERRUN,
} = CONSTANTS.OPERATIONS

const { PRODUCTION_DECISION_HELADO, AGEING, OVERRUN_PERCENTAGE } = OPERATIONS

const returnElements = (variables, changeValue) => [
  createListRowForVariable(
    PRODUCTION_DECISION_HELADO,
    DESIRED_ICE_CREAM_PRODUCTION,
    variables,
    changeValue,
  ),
  createListRowForVariable(AGEING, DESIRED_AGEING, variables, changeValue),
  createListRowForVariable(OVERRUN_PERCENTAGE, DESIRED_OVERRUN, variables, changeValue),
]

const ListProduction = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('production', dispatch, changeVariable)
  const variables = useSelector(state => state.operations.currentValues.production)
  return <List items={returnElements(variables, changeValue)} listTitle={PRODUCTION} />
}
export default ListProduction
