import {
  CHANGE_VARIABLE_MARKETING_AND_LOGISTICS,
  CLEAR_NEW_VALUES,
  CLEAR_VALUES,
  GET_VARIABLES_PRICING,
  GET_VARIABLES_MARKETING,
  GET_VARIABLES_LOGISTICS,
  GET_VARIABLES_MARKET_SHARE_TOTAL,
  GET_VARIABLES_PERIOD_SALES,
  GET_VARIABLES_MARKET_PRICE,
  GET_VARIABLES_MARKET_SHARES,
  GET_VARIABLES_FILL_RATES,
  GET_VARIABLES_FLEET,
  GET_VARIABLES_SALES_PACKAGE_A,
  GET_VARIABLES_SALES_PACKAGE_B,
  GET_VARIABLES_SALES_PACKAGE_C,
  GET_VARIABLES_SALES_PACKAGE_D,
  GET_VARIABLES_SALES_PACKAGE_E,
  GET_VARIABLES_SALES_PACKAGE_F,
} from 'constants/actionTypes'

const initialMarketingAndLogistics = {
  currentValues: {
    pricing: {},
    marketing: {},
    logistics: {},
    marketShare: {},
    periodSales: {},
    marketPrice: {},
    marketShares: {},
    fillRates: {},
    fleet: {},
    salesPackageA: {},
    salesPackageB: {},
    salesPackageC: {},
    salesPackageD: {},
    salesPackageE: {},
    salesPackageF: {},
  },
  newValues: {},
}

const MarketingAndLogistics = (state = initialMarketingAndLogistics, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialMarketingAndLogistics
    case GET_VARIABLES_PRICING:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          pricing: action.variables,
        },
      }
    case GET_VARIABLES_MARKETING:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          marketing: action.variables,
        },
      }
    case GET_VARIABLES_LOGISTICS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          logistics: action.variables,
        },
      }
    case GET_VARIABLES_MARKET_SHARE_TOTAL:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          marketShare: action.variables,
        },
      }
    case GET_VARIABLES_PERIOD_SALES:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          periodSales: action.variables,
        },
      }
    case GET_VARIABLES_MARKET_PRICE:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          marketPrice: action.variables,
        },
      }
    case GET_VARIABLES_MARKET_SHARES:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          marketShares: action.variables,
        },
      }
    case GET_VARIABLES_FILL_RATES:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          fillRates: action.variables,
        },
      }
    case GET_VARIABLES_FLEET:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          fleet: action.variables,
        },
      }
    case GET_VARIABLES_SALES_PACKAGE_A:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          salesPackageA: action.variables,
        },
      }
    case GET_VARIABLES_SALES_PACKAGE_B:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          salesPackageB: action.variables,
        },
      }
    case GET_VARIABLES_SALES_PACKAGE_C:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          salesPackageC: action.variables,
        },
      }
    case GET_VARIABLES_SALES_PACKAGE_D:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          salesPackageD: action.variables,
        },
      }
    case GET_VARIABLES_SALES_PACKAGE_E:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          salesPackageE: action.variables,
        },
      }
    case GET_VARIABLES_SALES_PACKAGE_F:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          salesPackageF: action.variables,
        },
      }
    case CHANGE_VARIABLE_MARKETING_AND_LOGISTICS:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default MarketingAndLogistics
