import * as XLSX from 'xlsx/xlsx.mjs'
import { Fab, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { excelFileDownloaded, fetchVariablesExcel } from 'actions/excelActions'
import { getExcelData } from './excelDataService'

const useStyle = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    textTransform: 'capitalize',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const createExcelWorkbook = excelSheetsData => {
  const workbook = XLSX.utils.book_new()

  Object.keys(excelSheetsData).forEach(sheetName => {
    const worksheet = XLSX.utils.aoa_to_sheet(excelSheetsData[sheetName].data)
    worksheet['!merges'] = excelSheetsData[sheetName].merges
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
  })

  XLSX.writeFile(workbook, 'CreamBow.xlsx')
}

const ExcelButton = () => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const variables = useSelector(state => state.excel)
  const { downloadReady } = variables
  const handleExcelButton = async () => {
    dispatch(fetchVariablesExcel())
  }

  useEffect(() => {
    if (downloadReady) {
      const { variablesExcel } = variables.currentValues
      const excelData = getExcelData(variablesExcel)
      createExcelWorkbook(excelData)
      dispatch(excelFileDownloaded)
    }
  }, [downloadReady, variables, dispatch])

  return (
    <Fab
      onClick={handleExcelButton}
      variant="extended"
      size="small"
      aria-label="run"
      className={classes.margin}
    >
      <Typography fontWeight="fontWeightLight" fontSize={8} className={classes.title}>
        Export Data
      </Typography>
    </Fab>
  )
}

export default ExcelButton
