import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { MARKETING_AND_LOGISTICS, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const { FILL_RATE_EMPAQUES_GRANDES, FILL_RATE_EMPAQUES_PEQUEÑOS } = MARKETING_AND_LOGISTICS

const { TIME } = HEADER
const { AVERAGE_FILL_RATES, LARGE_PACKAGES, SMALL_PACKAGES } = CONSTANTS.MARKETING_AND_LOGISTICS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      getChartLineForVariable(
        FILL_RATE_EMPAQUES_GRANDES,
        LARGE_PACKAGES,
        CHART_COLORS.COLOR_A,
        variables,
      ),
      getChartLineForVariable(
        FILL_RATE_EMPAQUES_PEQUEÑOS,
        SMALL_PACKAGES,
        CHART_COLORS.COLOR_B,
        variables,
      ),
    ],
  }
}

const ChartFillRates = () => {
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.fillRates)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart={AVERAGE_FILL_RATES} />
  ) : null
}

export default ChartFillRates
