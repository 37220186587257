import React from 'react'
import { useSelector } from 'react-redux'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import BaseSalesChart from './BaseSalesChart'

const { SALES_0_1_GL } = CONSTANTS.MARKETING_AND_LOGISTICS
const { CHOCOLATE, STRAWBERRY, LEMON, MANGO, RUM, VANILLA } = CONSTANTS.PRODUCTS

const {
  VENTA_DE_E_SABOR_C,
  VENTA_DE_E_SABOR_F,
  VENTA_DE_E_SABOR_L,
  VENTA_DE_E_SABOR_M,
  VENTA_DE_E_SABOR_R,
  VENTA_DE_E_SABOR_V,
} = MARKETING_AND_LOGISTICS

const flavorsToVariableMapping = {
  [CHOCOLATE]: VENTA_DE_E_SABOR_C,
  [STRAWBERRY]: VENTA_DE_E_SABOR_F,
  [LEMON]: VENTA_DE_E_SABOR_L,
  [MANGO]: VENTA_DE_E_SABOR_M,
  [RUM]: VENTA_DE_E_SABOR_R,
  [VANILLA]: VENTA_DE_E_SABOR_V,
}

const ChartSalesPackageE = () => {
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.salesPackageE)
  return (
    <BaseSalesChart
      title={SALES_0_1_GL}
      variables={variables}
      flavorsToVariableMapping={flavorsToVariableMapping}
    />
  )
}

export default ChartSalesPackageE
