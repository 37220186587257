import { sendPostRequest, sendGetRequest } from 'services/fetchService'
import { saveOnLocalStorage, localStorageKeys } from 'services/localstorage'
import { createJoinTeamLink } from '../utilities/appServiceUtilities'
import * as actions from '../constants/actionTypes'
import * as API_PATHS from '../constants/fetchApi'

export const createTeam = data => {
  return async dispatch => {
    dispatch({ type: actions.CREATE_TEAM })
    try {
      const response = await sendPostRequest(API_PATHS.CREATE_TEAM, data)
      if (!response.data.outcome) {
        dispatch({
          type: actions.CREATE_TEAM_FAILED,
          payload: response.data.message,
        })
        return
      }

      saveOnLocalStorage(localStorageKeys.TEAM_INFORMATION, response.data.payload)
      const joinTeamLink = createJoinTeamLink(response.data.payload)

      dispatch({ type: actions.CREATE_TEAM_SUCCESSFUL, payload: joinTeamLink })
    } catch (err) {
      dispatch({ type: actions.CREATE_TEAM_FAILED, payload: err.message })
    }
  }
}

export const resetCreateTeamStore = () => {
  return async dispatch => {
    dispatch({ type: actions.RESET_CREATE_TEAM })
  }
}

export const joinTeam = data => {
  return async dispatch => {
    dispatch({ type: actions.JOIN_TEAM })
    try {
      const response = await sendPostRequest(API_PATHS.JOIN_TEAM, data)
      if (!response.data.outcome) {
        dispatch({
          type: actions.JOIN_TEAM_FAILED,
          payload: response.data.message,
        })
        return
      }

      saveOnLocalStorage(localStorageKeys.TEAM_INFORMATION, response.data.payload)
      const joinTeamLink = createJoinTeamLink(response.data.payload)

      dispatch({ type: actions.JOIN_TEAM_SUCCESSFUL, payload: joinTeamLink })
    } catch (err) {
      dispatch({ type: actions.JOIN_TEAM_FAILED, payload: err.message })
    }
  }
}

export const changeRequest = async data => {
  try {
    const response = await sendPostRequest(API_PATHS.CHANGE_REQUEST, data)

    if (response.data.outcome) {
      saveOnLocalStorage(localStorageKeys.TEAM_INFORMATION, response.data.payload)
    }

    return response.data
  } catch (err) {
    return { outcome: false, message: err.message }
  }
}

export const getTeam = async teamId => {
  try {
    const response = await sendGetRequest(API_PATHS.GET_TEAM, teamId)

    if (response.data.outcome) {
      saveOnLocalStorage(localStorageKeys.TEAM_INFORMATION, response.data.payload)
    }

    return response.data
  } catch (err) {
    return { outcome: false, message: err.message }
  }
}

export const getSimulations = () => {
  return async dispatch => {
    dispatch({ type: actions.GET_SIMULATIONS })
    try {
      const response = await sendGetRequest(API_PATHS.GET_SIMULATIONS, '', {
        productName: 'Creambow',
      })
      if (!response.data.outcome) {
        dispatch({
          type: actions.GET_SIMULATIONS_FAILED,
          payload: response.data.message,
        })
        return
      }

      dispatch({
        type: actions.GET_SIMULATIONS_SUCCESSFUL,
        payload: response.data.payload,
      })
    } catch (err) {
      dispatch({ type: actions.GET_SIMULATIONS_FAILED, payload: err.message })
    }
  }
}
