const CONSTANTS = {
  INVENTORY: {
    INVENTORY_PER_PACKAGE: 'Inventory Management Per Package',
    PACKAGE: 'Package',
    REORDEN_QUANTITY: 'Reorder Quantity',
    REORDEN_POINT: 'Reorder Point',
    EMPAQUE_A: '5 Gl',
    EMPAQUE_B: '2.5 Gl',
    EMPAQUE_C: '1 Gl',
    EMPAQUE_D: '0.5 Gl',
    EMPAQUE_E: '0.1 Gl',
    EMPAQUE_F: '0.0125 Gl',
    TOTAL: 'Total',
    RAW_MATERIALS: 'Raw Materials Management',
    MATERIAL: 'Material',
    MILK: 'Milk',
    OTHERS: 'Others',
    PACKAGING_MATERIAL: 'Packaging Material Inventory',
    RAW_MATERIALS_INVENTORY: 'Raw Materials Inventory',
  },
  OPERATIONS: {
    EQUIPMENT_AND_FACILITIES: 'Equipment and Facilities',
    DESIRED_PASTEURIZERS: 'Desired Pasteurizers',
    DESIRED_AGING_TANKS: 'Desired Aging Tanks',
    DESIRED_BATCH_FREEZERS: 'Desired Batch Freezers',
    DESIRED_COLD_STORAGE_SPACE: 'Desired Cold Storage Space',
    PRODUCTION: 'Production',
    DESIRED_ICE_CREAM_PRODUCTION: 'Desired Ice Cream Production Volume (biweekly Gl production)',
    DESIRED_AGEING: 'Desired Ageing (hours)',
    DESIRED_OVERRUN: 'Desired Overrun %',
    HUMAN_RESOURCES: 'Human Resources',
    AVERAGE_MARKET_SALARY: 'Average Market Salary per Person',
    DESIRED_MONTHLY_SALARY: 'Desired Monthly Salary per Person',
    DESIRED_PERSONNEL: 'Desired Personnel per Shift',
    HUMAN_LABOR_IMPACT: 'Human labor impact on capacity',
    ROOKIES_TEXT: 'Rookies',
    EXPERTS_TEXT: 'Experts',
    MAINTENANCE: 'Maintenance',
    SPENDING_ON_MAINTENANCE: 'Desired Biweekly Spending on Maintenance ($)',
    EQUIPMENT: 'Equipment',
    MACHINE_CAPACITY: 'Machine condition impact on capacity',
    PASTEURIZATION_CAPACITY: 'Total Pasteurization Capacity per Hour (Gl/h)',
    AVAILABLE_PASTEURIZERS: 'Available Pasteurizers',
    AVAILABLE_FREEZING: 'Available Freezing Capacity (Gl/h)',
    MIXERS: 'Mixers',
    CAPACITY_PER_MATURATION: 'Capacity per Maturation Tank (Gl)',
    AVAILABLE_MATURATION_TANKS: 'Available Maturation Tanks',
    WIP_AGEING_TANKS_TEXT: 'WIP in Ageing Tanks',
    PRODUCT_COLD_STORAGE: 'Product in Cold Storage (Presale)',
    PRODUCT_FOR_SALE: 'Product Ready for Sale',
    PERCEIVED_QUALITY_TEXT: 'Perceived Quality',
    QUALITY_FROM_AGEING: 'Quality Impact From Ageing',
    QUALITY_FROM_OVERRUN: 'Quality Impact From Overrun',
    QUALITY_FROM_MACHINE: 'Quality Impact From Machine Conditions',
  },
  PRODUCTS: {
    TOTAL: 'Total',
    PACKAGING: 'Packaging',
    FLAVORS_PRIORITY: 'Flavors Priority',
    PACKAGING_PRIORITY: 'Packaging Priority',
    FLAVOR: 'Flavor',
    FLAVORS: 'Flavors',
    CHOCOLATE: 'Chocolate',
    STRAWBERRY: 'Strawberry',
    LEMON: 'Lemon',
    MANGO: 'Mango',
    RUM: 'Rum Raisin',
    VANILLA: 'Vanilla',
    FINISHED_INVENTORY_5_0_GL: 'Finished Inventory 5 Gl',
    FINISHED_INVENTORY_2_5_GL: 'Finished Inventory 2.5 Gl',
    FINISHED_INVENTORY_1_0_GL: 'Finished Inventory 1.0 Gl',
    FINISHED_INVENTORY_0_5_GL: 'Finished Inventory 0.5 Gl',
    FINISHED_INVENTORY_0_1_GL: 'Finished Inventory 0.1 Gl',
    FINISHED_INVENTORY_0_0_GL: 'Finished Inventory 0.0125 Gl',
    PRODUCTION: 'Production',
    BI_WEEKLY_PRODUCTION: 'Desired Ice Cream Production Volume (biweekly Gl production)',
  },
  MARKETING_AND_LOGISTICS: {
    PRICING: 'Pricing',
    PRECIO_PER_GI_EMPAQUE_A: 'Price per Gl (5 Gl)',
    PRECIO_PER_GI_EMPAQUE_B: 'Price per Gl (2.5 Gl)',
    PRECIO_PER_GI_EMPAQUE_C: 'Price per Gl (1 Gl)',
    PRECIO_PER_GI_EMPAQUE_D: 'Price per Gl (0.5 Gl)',
    PRECIO_PER_GI_EMPAQUE_E: 'Price per Gl (0.1 Gl)',
    PRECIO_PER_GI_EMPAQUE_F: 'Price per Gl (0.0125 Gl)',
    MARKETING: 'Marketing',
    SALES_TO_INVEST: 'Fraction of Sales to invest in Marketing',
    LOGISTICS: 'Logistics',
    DELIVERY_TRUCKS: 'Desired Delivery Trucks',
    MARKET_SHARE: 'Market Share Total',
    PERIOD_SALES: 'Period Sales (Gl)',
    AVERAGE_MARKET_PRICE: 'Average Market Price',
    MARKET_SHARES: 'Market Shares by Packaging',
    AVERAGE_FILL_RATES: 'Average Fill Rates',
    LARGE_PACKAGES: 'Large Packages',
    SMALL_PACKAGES: 'Small Packages',
    FLEET: 'Fleet',
    COMPETITION: 'Competition',
    OWNED: 'Owned',
    SALES_5_GL: 'Sales 5 Gl',
    SALES_2_5_GL: 'Sales 2.5 Gl',
    SALES_1_GL: 'Sales 1 Gl',
    SALES_0_5_GL: 'Sales 0.5 Gl',
    SALES_0_1_GL: 'Sales 0.1 Gl',
    SALES_0_0_GL: 'Sales 0.0125 Gl',
  },
  RESULTS: {
    MATERIALS: 'Materials',
    LABOR: 'Labor',
    DEPRECIATION_PLANT_AND_EQUIPMENT: 'Depreciation - plant and equipment',
    UTILITIES: 'Utilities',
    MAINTENANCE_PRODUCTION_RELATED: 'Maintenance - production related',
    COST_OF_GOODS_MANUFACTURED: 'Cost of Goods Manufactured',
    DISTRIBUTION_COSTS: 'Distribution Costs',
    TRUCKS_DEPRECIATION: 'Trucks Depreciation',
    SALES_AND_ADMINISTRATION_EXPENSES: 'Sales and Administration Expenses',
    MARKETING_EXPENSES: 'Marketing Expenses',
    HIRING_EXPENSES: 'Hiring Expenses',
    FIRING_EXPENSES: 'Firing Expenses',
    INVENTORY_MAINTENANCE_COSTS: 'Inventory Maintenance Costs',
    DEPRECIATION_OF_NON_MANUFACTURING_FACILITIES: 'Depreciation of non-manufacturing facilities',
    INTEREST_EXPENSES: 'Interest Expenses',
    INCOME_BEFORE_TAXES: 'Income Before Taxes',
    INCOME_TAX: 'Income Tax',
    CASH: 'Cash',
    ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
    INVENTORIES: 'Inventories',
    CURRENT_ASSETS: 'Current Assets',
    NET_FIXED_ASSETS: 'Net Fixed Assets',
    TOTAL_ASSETS: 'Total Assets',
    ACCOUNTS_PAYABLE: 'Accounts Payable',
    LOANS: 'Loans',
    LIABILITIES: 'Liabilities',
    CAPITAL_STOCK: 'Capital Stock',
    RETAINED_EARNINGS: 'Retained Earnings',
    EQUITY: 'Equity',
    LIABILITIES_PLUS_EQUITY: 'Liabilities Plus Equity',
    SALES: 'Sales',
    GROSS_MARGIN: 'Gross Margin',
    NET_INCOME: 'Net Income',
    PROFIT_AND_LOSS_STATEMENT: 'Profit and Loss Statement',
    TOTAL_OVERHEAD_PLUS_INTEREST: 'Total Overhead Plus Interest',
    NET_PROFIT: 'Net Profit',
    DAYS_UNTIL_PAYMENT: 'Days Until Payment',
    DESIRED_DEBT_AMORTIZATION: 'Desired Debt Amortization %',
    FINANCES: 'Finances',
  },
  EXCEL: {
    TIME: 'Time',
    EMPAQUE_A: '5 Gl',
    EMPAQUE_B: '2.5 Gl',
    EMPAQUE_C: '1 Gl',
    EMPAQUE_D: '0.5 Gl',
    EMPAQUE_E: '0.1 Gl',
    EMPAQUE_F: '0.0125 Gl',
    TOTAL: 'Total',
    CHOCOLATE: 'Chocolate',
    STRAWBERRY: 'Strawberry',
    LEMON: 'Lemon',
    MANGO: 'Mango',
    RUM: 'Rum Raisin',
    VANILLA: 'Vanilla',
    WIP_IN_AGEING_TANKS: 'WIP in Ageing Tanks (Gl)',
    PRODUCT_IN_COLD_STORAGE: 'Product in Cold Storage (Gl)',
    PRODUCT_READY_FOR_SALE: 'Product Ready for Sale (Gl)',
    PRODUCTS: 'Products',
    OPERATIONS: 'Operations',
    MARKETING_AND_LOGISTICS: 'Marketing and Logistics',
    INVENTORY: 'Inventory',
    FINANCES_AND_RESULTS: 'Finances and results',
    SALES_5_GL: 'Sales 5 Gl',
    SALES_2_5_GL: 'Sales 2.5 Gl',
    SALES_1_GL: 'Sales 1 Gl',
    SALES_0_5_GL: 'Sales 0.5 Gl',
    SALES_0_1_GL: 'Sales 0.1 Gl',
    SALES_0_0_GL: 'Sales 0.0125 Gl',
    AVERAGE_MARKET_PRICE: 'Average Market Price',
    MARKET_SHARES_BY_PACKAGING: 'Market Shares by Packaging',
    FLEET: 'Fleet',
    AVERAGE_FILL_RATES: 'Average Fill Rates',
    COMPETITION: 'Competition',
    OWNED: 'Owned',
    LARGE_PACKAGES: 'Large Packages',
    SMALL_PACKAGES: 'Small Packages',
    PACKAGING_MATERIAL_INVENTORY: 'Packaging Material Inventory',
    RAW_MATERIALS_INVENTORY: 'Raw Materials Inventory',
    MILK: 'Milk',
    OTHERS: 'Others',
    INCOME_STATEMENT: 'Income Statement',
    BALANCE_SHEET: 'Balance Sheet',
    SALES: 'Sales',
    RAW_MATERIALS_COSTS: 'Raw Materials Costs',
    LABOR_FORCE_COSTS: 'Labor Force Costs',
    PRODUCTION_EQUIPMENT_DEPRECIATION: 'Production Equipment Depreciation',
    UTILITIES_COSTS: 'Utilities Costs',
    MAINTENANCE_COSTS: 'Maintenance Costs',
    SALES_TOTAL_COSTS: 'Sales Total Costs',
    RAW_PROFITS: 'Raw Profits',
    DELIVERY_TRUCKS_COSTS: 'Delivery Trucks Costs',
    TRUCKS_DEPRECIATION: 'Trucks Depreciation',
    SALES_AND_ADMINISTRATION_COSTS: 'Sales and Administration Costs',
    MARKETING_COSTS: 'Marketing Costs',
    HIRING_COSTS: 'Hiring Costs',
    FIRING_COSTS: 'Firing Costs',
    INVENTORY_HOLDING_COSTS: 'Inventory Holding Costs',
    BUILDINGS_DEPRECIATION: 'Buildings Depreciation',
    INTEREST_RELATED_COSTS: 'Interest Related Costs',
    OVERHEAD_COSTS: 'Overhead Costs',
    PROFIT_BEFORE_TAXES: 'Profit Before Taxes',
    INCOME_TAX: 'Income Tax',
    NET_PROFIT: 'Net Profit',
    CASH: 'Cash',
    ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
    CURRENT_ASSETS: 'Current Assets',
    NET_FIXED_ASSETS: 'Net Fixed Assets',
    TOTAL_ASSETS: 'Total Assets',
    ACCOUNTS_PAYABLE: 'Accounts Payable',
    LOANS: 'Loans',
    TOTAL_LIABILITIES: 'Total Liabilities',
    STOCKS: 'Stocks',
    RETAINED_EARNINGS: 'Retained Earnings',
    TOTAL_CAPITAL: 'Total Capital',
    TOTAL_LIABILITIES_PLUS_CAPITAL: 'Total Liabilities Plus Capital',
  },
}
export { CONSTANTS }
