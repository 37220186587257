import {
  CLEAR_NEW_VALUES,
  CHANGE_VARIABLE_OPERATIONS,
  GET_VARIABLES_EQUIPMENT_AND_FACILITIES,
  GET_VARIABLES_PRODUCTION,
  GET_VARIABLES_HUMAN_RESOURCES,
  GET_VARIABLES_MAINTENANCE,
  GET_VARIABLES_EQUIPMENT,
  GET_VARIABLES_CHART,
  GET_VARIABLES_QUALITY,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { OPERATIONS } from 'constants/forioVariables'

const {
  PASTEURIZERS_DESIRED,
  MATURATION_TANKS_DESIRED,
  BATIDORAS_DESIRED,
  CUARTO_FRIO_DESIRED,
  PRODUCTION_DECISION_HELADO,
  AGEING,
  OVERRUN_PERCENTAGE,
  SALARIES_IN_ECONOMY_SMOOTHED,
  MONTHLY_SALARY_PER_PERSON,
  PERSONNEL_DESIRED_PER_SHIFT,
  CAPACITY_FACTOR_POR_PERSONAL,
  ROOKIES,
  EXPERTS,
  DECISION_INVERTIR_MANTENIMIENTO,
  CAPACIDAD_DE_LAS_MAQUINAS,
  PASTEURIZATION_CAPACITY_AVAILABLE,
  PASTEURIZERS,
  CAPACIDAD_DE_BATIDO,
  BATIDORAS,
  MATURATION_TANK_CAPACITY,
  MATURATION_TANKS,
  WIP_AGEING_TANKS,
  PRODUCT_NOT_YET_FROZEN,
  PRODUCT_READY_FOR_SALE,
  QUALITY_POR_AGING,
  QUALITY_POR_OVERRUN,
  QUALITY_POR_MAQUINAS,
  PERCEIVED_QUALITY,
} = OPERATIONS

const equipmentAndFacilities = [
  PASTEURIZERS_DESIRED,
  MATURATION_TANKS_DESIRED,
  BATIDORAS_DESIRED,
  CUARTO_FRIO_DESIRED,
]

const getVariablesEquipmentAndFacilities = variables => ({
  type: GET_VARIABLES_EQUIPMENT_AND_FACILITIES,
  variables,
})

const fetchVariablesEquipmentAndFacilities = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(equipmentAndFacilities)
    dispatch(getVariablesEquipmentAndFacilities(variables))
  }
}

const production = [PRODUCTION_DECISION_HELADO, AGEING, OVERRUN_PERCENTAGE]

const getVariablesProduction = variables => ({
  type: GET_VARIABLES_PRODUCTION,
  variables,
})

const fetchVariablesProduction = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(production)
    dispatch(getVariablesProduction(variables))
  }
}

const humanResources = [
  SALARIES_IN_ECONOMY_SMOOTHED,
  MONTHLY_SALARY_PER_PERSON,
  PERSONNEL_DESIRED_PER_SHIFT,
  CAPACITY_FACTOR_POR_PERSONAL,
  ROOKIES,
  EXPERTS,
]

const getVariablesHumanResources = variables => ({
  type: GET_VARIABLES_HUMAN_RESOURCES,
  variables,
})

const fetchVariablesHumanResources = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(humanResources)
    dispatch(getVariablesHumanResources(variables))
  }
}

const maintenance = [DECISION_INVERTIR_MANTENIMIENTO]

const getVariablesMaintenance = variables => ({
  type: GET_VARIABLES_MAINTENANCE,
  variables,
})

const fetchVariablesMaintenance = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(maintenance)
    dispatch(getVariablesMaintenance(variables))
  }
}

const equipment = [
  CAPACIDAD_DE_LAS_MAQUINAS,
  PASTEURIZATION_CAPACITY_AVAILABLE,
  PASTEURIZERS,
  CAPACIDAD_DE_BATIDO,
  BATIDORAS,
  MATURATION_TANKS,
]

const getVariablesEquipment = variables => ({
  type: GET_VARIABLES_EQUIPMENT,
  variables,
})

const fetchVariablesEquipment = () => {
  return async dispatch => {
    const arrayVariables = await getVariablesLastValueByRunId(equipment)
    const maturationTankCapacity = await getVariablesByRunId([MATURATION_TANK_CAPACITY])
    const variables = { ...arrayVariables, ...maturationTankCapacity }
    dispatch(getVariablesEquipment(variables))
  }
}

const chartsVariables = [WIP_AGEING_TANKS, PRODUCT_NOT_YET_FROZEN, PRODUCT_READY_FOR_SALE]

const getVariablesChart = variables => ({
  type: GET_VARIABLES_CHART,
  variables,
})

const fetchVariablesChart = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(chartsVariables)
    dispatch(getVariablesChart(variables))
  }
}

const quality = [QUALITY_POR_AGING, QUALITY_POR_OVERRUN, QUALITY_POR_MAQUINAS, PERCEIVED_QUALITY]

const getVariablesQuality = variables => ({
  type: GET_VARIABLES_QUALITY,
  variables,
})

const fetchVariablesQuality = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(quality)
    dispatch(getVariablesQuality(variables))
  }
}

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_OPERATIONS, payload }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFunctionsFetchOperations = [
  fetchVariablesEquipmentAndFacilities,
  fetchVariablesProduction,
  fetchVariablesHumanResources,
  fetchVariablesMaintenance,
  fetchVariablesEquipment,
  fetchVariablesChart,
  fetchVariablesQuality,
]

export {
  fetchVariablesEquipmentAndFacilities,
  fetchVariablesProduction,
  fetchVariablesHumanResources,
  fetchVariablesMaintenance,
  fetchVariablesEquipment,
  fetchVariablesChart,
  fetchVariablesQuality,
  allFunctionsFetchOperations,
  changeVariable,
  clearNewValues,
}
