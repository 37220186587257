import auth0 from "auth0-js";
import jwtDecode from "jwt-decode";
import { history } from "store/configureStore";
import { setCookie, getCookie, deleteCookie } from "./cookieService";
import { loadLocalStorageValue, localStorageKeys } from "./localstorage";
import CONFIG from "../constants/configAuth0";
import * as ROUTES from "../constants/routes";
import * as COOKIES from "../constants/cookies";

const authSDK = new auth0.WebAuth(CONFIG);

export const login = () => {
  authSDK.authorize();
};

export const getCurrentSession = async () => {
  const cookie = getCookie(COOKIES.AUTH_INFORMATION);

  if (!cookie) {
    login();
    return;
  }

  const decodedToken = jwtDecode(cookie.tokenInfo.idToken);
  const timeNow = Math.floor(new Date().getTime() / 1000);
  const tokenExpirationDate = decodedToken.exp;
  if (timeNow < tokenExpirationDate) {
    return cookie;
  }

  let tryAgain = false;
  let session = null;
  try {
    session = await renewSession();
  } catch (err) {
    if (err.error && err.error === "timeout") {
      tryAgain = true;
    }
  }

  if (tryAgain) {
    session = await renewSession();
  }

  if (session === null) {
    login();
    return;
  }

  return session;
};

export const loginSuccessful = () => {
  return new Promise((resolve, reject) => {
    authSDK.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const authInformation = extractUserDataAndProfileFromToken(authResult);
        return resolve(authInformation);
      } else if (err) {
        logout();
        return reject(err);
      }
    });
  });
};

export const logout = () => {
  deleteCookie(COOKIES.AUTH_INFORMATION);
  authSDK.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: process.env.REACT_APP_AUTH0_RETURN_TO
  });
};

export const renewSession = () => {
  return new Promise((resolve, reject) => {
    authSDK.checkSession({ timeout: 4000 }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const authInformation = extractUserDataAndProfileFromToken(authResult);
        return resolve(authInformation);
      } else if (err) {
        return reject(err);
      }
    });
  });
};

const extractUserDataAndProfileFromToken = authResult => {
  window.location.hash = "";
  const userProfile = {
    email: authResult.idTokenPayload["https://app.processimlabs.com/email"],
    fullName: `${
      authResult.idTokenPayload["https://app.processimlabs.com/first_name"]
    } ${authResult.idTokenPayload["https://app.processimlabs.com/last_name"]}`,
    firstName:
      authResult.idTokenPayload["https://app.processimlabs.com/first_name"],
    lastName:
      authResult.idTokenPayload["https://app.processimlabs.com/last_name"],
    institution:
      authResult.idTokenPayload["https://app.processimlabs.com/institution"],
    role: authResult.idTokenPayload["https://app.processimlabs.com/role"],
    language:
      authResult.idTokenPayload["https://app.processimlabs.com/language"]
  };
  const userTokenDataAndProfile = {
    tokenInfo: authResult,
    userProfile
  };
  setCookie(COOKIES.AUTH_INFORMATION, userTokenDataAndProfile);
  return userTokenDataAndProfile;
};

export const redirectionHandler = () => {
  const createTeamCode = loadLocalStorageValue(
    localStorageKeys.CREATE_TEAM_SIMULATION_ID
  );
  const joinTeamCode = loadLocalStorageValue(localStorageKeys.JOIN_TEAM_ID);
  if (createTeamCode) {
    history.replace(ROUTES.CREATE_TEAM);
  } else if (joinTeamCode) {
    history.replace(ROUTES.JOIN_TEAM);
  } else {
    history.replace(ROUTES.SIMULATIONS);
  }
};
