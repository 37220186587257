import React from 'react'
import Box from '@material-ui/core/Box'
import { useSelector, useDispatch } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/marketingAndLogisticsActions'
import { ListPrincing, ListMarketing, ListLogistics } from './List'
import { MarketShare, PeriodSales } from './Labels'
import {
  ChartMarketPrice,
  ChartMarketShares,
  ChartFillRates,
  ChartFleet,
  ChartSalesPackageA,
  ChartSalesPackageB,
  ChartSalesPackageC,
  ChartSalesPackageD,
  ChartSalesPackageE,
  ChartSalesPackageF,
} from './Graphics'

const MarketingAndLogistics = () => {
  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateMarketingAndLogistics = useSelector(state => state.marketingAndLogistics.currentValues)
  if (validateStates(stateMarketingAndLogistics)) {
    return <Loading />
  }
  return (
    <Box>
      <Box display="flex">
        <Box className="elementWrapper elementToLeft">
          <ListPrincing />
        </Box>
        <Box className="elementWrapper elementToCenter">
          <ListMarketing />
          <ListLogistics />
        </Box>
        <Box className="elementWrapper elementToRight">
          <MarketShare />
          <PeriodSales />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ChartMarketPrice />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartMarketShares />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <ChartFillRates />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartFleet />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <ChartSalesPackageA />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartSalesPackageB />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <ChartSalesPackageC />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartSalesPackageD />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <ChartSalesPackageE />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartSalesPackageF />
        </Box>
      </Box>
    </Box>
  )
}

export default MarketingAndLogistics
