import React from 'react'
import { Box, Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { CONSTANTS } from 'constants/constants'
import { RESULTS } from 'constants/forioVariables'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9ED',
    boxShadow: 'none',
    color: '#73879C',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '2px solid #E6E9ED',
    padding: '0.75rem 0.75rem',
    margin: '0 0.75rem',
    backgroundColor: '#FFFFFF',
  },
  title: {
    color: '#73879C',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
  itemText: {
    fontSize: '.875rem',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
  listLabelp: {
    paddingLeft: '2rem',
  },
  listValuep: {},
  listLabelh3: {
    fontSize: '1.5rem',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
  listValueh3: {
    fontSize: '1.5rem',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
  listLabelh4: {
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
  listValueh4: {
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
  listLabelh5: {
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
  listValueh5: {
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
})
const {
  COSTO_ACUMULADO_DE_MATERIALES_VENDIDOS_SIN_LIMPIA_PARA_SABLE,
  COSTOS_ACUMULADOS_POR_MANO_DE_OBRA_SIN_LIMPIA_PARA_SABLE,
  DEPRECIACION_ACUMULADA_PARA_COSTOS_VARIABLES_DE_PRODUCCIÓN_PARA_SABLE,
  UTILITIES_COST_ACUMULADO_PARA_SABLE,
  ACCUMULATED_MAINTENANCE_EXPENSES_PARA_SABLE,
  TOTAL_COSTO_DE_VENTAS_PARA_SABLE,
  COSTO_ACUMULADO_DE_CAMIONES_PARA_SABLE,
  DEPRECIACION_ACUMULADA_DE_CARROS_PARA_SABLE,
  ACCUMULATED_EGRESOS_POR_SALES_AND_ADMINISTRATION_PARA_SABLE,
  ACCUMULATED_MARKETING_EXPENSES_PARA_SABLE,
  ACCUMULATED_HIRING_COST_PARA_SABLE,
  ACCUMULATED_FIRING_COST_PARA_SABLE,
  ACCUMULATED_INVENTORY_HOLDING_COST,
  DEPRECIACION_ACUMULADA_PLANTA_EDIFICIOS_YOTROS_PARA_SABLE,
  ACCUMULATED_INTEREST_EXPENSES_PARA_SABLE,
  UTILIDAD_ANTES_DE_IMPUESTOS_PARA_SABLE,
  ACCUMULATED_IMPUESTO_SOBRE_LA_RENTA_PARA_SABLE,
  CAJA_PARA_SABLE,
  CUENTAS_POR_COBRAR_PARA_SABLE,
  INVENTARIO_TOTAL_DOLARES_PARA_SABLE,
  TOTAL_ACTIVO_CIRCULANTE_PARA_SABLE,
  TOTAL_ACTIVO_FIJO_PARA_SABLE,
  TOTAL_ACTIVO_PARA_SABLE,
  CUENTAS_POR_PAGAR_PROVEEDORES_PARA_SABLE,
  PRESTAMO_PARA_SABLE,
  TOTAL_PASIVO_PARA_SABLE,
  ACCIONES_PARA_SABLE,
  UTILIDADES_RETENIDAS_PARA_SABLE,
  TOTAL_CAPITAL_PARA_SABLE,
  TOTAL_PASIVO_MAS_CAPITAL_PARA_SABLE,
  ACCUMULATED_TOTAL_SALES_PARA_SABLE,
  UTILIDAD_BRUTA_ACUMULADA_PARA_SABLE,
  ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE,
  TOTAL_ACCUMULATED_OVERHEAD_EXPENSES_PARA_SABLE,
} = RESULTS

const {
  MATERIALS,
  LABOR,
  DEPRECIATION_PLANT_AND_EQUIPMENT,
  UTILITIES,
  MAINTENANCE_PRODUCTION_RELATED,
  COST_OF_GOODS_MANUFACTURED,
  DISTRIBUTION_COSTS,
  TRUCKS_DEPRECIATION,
  SALES_AND_ADMINISTRATION_EXPENSES,
  MARKETING_EXPENSES,
  HIRING_EXPENSES,
  FIRING_EXPENSES,
  INVENTORY_MAINTENANCE_COSTS,
  DEPRECIATION_OF_NON_MANUFACTURING_FACILITIES,
  INTEREST_EXPENSES,
  INCOME_BEFORE_TAXES,
  INCOME_TAX,
  CASH,
  ACCOUNTS_RECEIVABLE,
  INVENTORIES,
  CURRENT_ASSETS,
  NET_FIXED_ASSETS,
  TOTAL_ASSETS,
  ACCOUNTS_PAYABLE,
  LOANS,
  LIABILITIES,
  CAPITAL_STOCK,
  RETAINED_EARNINGS,
  EQUITY,
  LIABILITIES_PLUS_EQUITY,
  SALES,
  GROSS_MARGIN,
  NET_INCOME,
  PROFIT_AND_LOSS_STATEMENT,
  TOTAL_OVERHEAD_PLUS_INTEREST,
} = CONSTANTS.RESULTS

const createListLine = (labelText, variableName, variables, classes, textVariant = 'p') => {
  return {
    id: variableName,
    label: (
      <Typography className={classes[`listLabel${textVariant}`]} variant={textVariant}>
        {labelText}
      </Typography>
    ),
    component: (
      <Typography className={classes[`listValue${textVariant}`]} variant={textVariant}>
        {variables[variableName]}
      </Typography>
    ),
  }
}

const returnElements = (variables, classes) => [
  createListLine(SALES, ACCUMULATED_TOTAL_SALES_PARA_SABLE, variables, classes, 'h3'),
  createListLine(
    MATERIALS,
    COSTO_ACUMULADO_DE_MATERIALES_VENDIDOS_SIN_LIMPIA_PARA_SABLE,
    variables,
    classes,
  ),
  createListLine(
    LABOR,
    COSTOS_ACUMULADOS_POR_MANO_DE_OBRA_SIN_LIMPIA_PARA_SABLE,
    variables,
    classes,
  ),
  createListLine(
    DEPRECIATION_PLANT_AND_EQUIPMENT,
    DEPRECIACION_ACUMULADA_PARA_COSTOS_VARIABLES_DE_PRODUCCIÓN_PARA_SABLE,
    variables,
    classes,
  ),
  createListLine(UTILITIES, UTILITIES_COST_ACUMULADO_PARA_SABLE, variables, classes),
  createListLine(
    MAINTENANCE_PRODUCTION_RELATED,
    ACCUMULATED_MAINTENANCE_EXPENSES_PARA_SABLE,
    variables,
    classes,
  ),
  createListLine(COST_OF_GOODS_MANUFACTURED, TOTAL_COSTO_DE_VENTAS_PARA_SABLE, variables, classes),
  createListLine(GROSS_MARGIN, UTILIDAD_BRUTA_ACUMULADA_PARA_SABLE, variables, classes, 'h3'),
  createListLine(DISTRIBUTION_COSTS, COSTO_ACUMULADO_DE_CAMIONES_PARA_SABLE, variables, classes),
  createListLine(
    TRUCKS_DEPRECIATION,
    DEPRECIACION_ACUMULADA_DE_CARROS_PARA_SABLE,
    variables,
    classes,
  ),
  createListLine(
    SALES_AND_ADMINISTRATION_EXPENSES,
    ACCUMULATED_EGRESOS_POR_SALES_AND_ADMINISTRATION_PARA_SABLE,
    variables,
    classes,
  ),
  createListLine(MARKETING_EXPENSES, ACCUMULATED_MARKETING_EXPENSES_PARA_SABLE, variables, classes),
  createListLine(HIRING_EXPENSES, ACCUMULATED_HIRING_COST_PARA_SABLE, variables, classes),
  createListLine(FIRING_EXPENSES, ACCUMULATED_FIRING_COST_PARA_SABLE, variables, classes),
  createListLine(
    INVENTORY_MAINTENANCE_COSTS,
    ACCUMULATED_INVENTORY_HOLDING_COST,
    variables,
    classes,
  ),
  createListLine(
    DEPRECIATION_OF_NON_MANUFACTURING_FACILITIES,
    DEPRECIACION_ACUMULADA_PLANTA_EDIFICIOS_YOTROS_PARA_SABLE,
    variables,
    classes,
  ),
  createListLine(INTEREST_EXPENSES, ACCUMULATED_INTEREST_EXPENSES_PARA_SABLE, variables, classes),
  createListLine(
    TOTAL_OVERHEAD_PLUS_INTEREST,
    TOTAL_ACCUMULATED_OVERHEAD_EXPENSES_PARA_SABLE,
    variables,
    classes,
    'h5',
  ),
  createListLine(INCOME_BEFORE_TAXES, UTILIDAD_ANTES_DE_IMPUESTOS_PARA_SABLE, variables, classes),
  createListLine(INCOME_TAX, ACCUMULATED_IMPUESTO_SOBRE_LA_RENTA_PARA_SABLE, variables, classes),
  createListLine(
    NET_INCOME,
    ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE,
    variables,
    classes,
    'h3',
  ),
  createListLine(CASH, CAJA_PARA_SABLE, variables, classes),
  createListLine(ACCOUNTS_RECEIVABLE, CUENTAS_POR_COBRAR_PARA_SABLE, variables, classes),
  createListLine(INVENTORIES, INVENTARIO_TOTAL_DOLARES_PARA_SABLE, variables, classes),
  createListLine(CURRENT_ASSETS, TOTAL_ACTIVO_CIRCULANTE_PARA_SABLE, variables, classes, 'h4'),
  createListLine(NET_FIXED_ASSETS, TOTAL_ACTIVO_FIJO_PARA_SABLE, variables, classes),
  createListLine(TOTAL_ASSETS, TOTAL_ACTIVO_PARA_SABLE, variables, classes, 'h4'),
  createListLine(ACCOUNTS_PAYABLE, CUENTAS_POR_PAGAR_PROVEEDORES_PARA_SABLE, variables, classes),
  createListLine(LOANS, PRESTAMO_PARA_SABLE, variables, classes),
  createListLine(LIABILITIES, TOTAL_PASIVO_PARA_SABLE, variables, classes, 'h4'),
  createListLine(CAPITAL_STOCK, ACCIONES_PARA_SABLE, variables, classes),
  createListLine(RETAINED_EARNINGS, UTILIDADES_RETENIDAS_PARA_SABLE, variables, classes),
  createListLine(EQUITY, TOTAL_CAPITAL_PARA_SABLE, variables, classes, 'h4'),
  createListLine(
    LIABILITIES_PLUS_EQUITY,
    TOTAL_PASIVO_MAS_CAPITAL_PARA_SABLE,
    variables,
    classes,
    'h4',
  ),
]
const ResultsList = () => {
  const variables = useSelector(state => state.results.currentValues.variablesResults)

  // return <List items={returnElements(formattedVariables)} />
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <div className={classes.cardTitle}>
        <h2 className={classes.title}> {PROFIT_AND_LOSS_STATEMENT}</h2>
      </div>
      <CardContent>
        <Box>
          {returnElements(variables, classes).map(item => (
            <Box key={item.id} display="flex" justifyContent="space-between" alignItems="center">
              <Box>{item.label}</Box>
              <Box style={{ paddingLeft: '10px' }}>{item.component}</Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
export default ResultsList
