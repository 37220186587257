import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { changeVariable } from 'actions/inventoryActions'
import { INVENTORY } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { changeValue as onChangeValue } from 'common'
import { createColumnCellForVariable } from 'common/UIComponentUtilities'
import { HELPS } from 'constants/helps'

const {
  INVENTORY_PER_PACKAGE,
  PACKAGE,
  REORDEN_QUANTITY,
  REORDEN_POINT,
  EMPAQUE_A,
  EMPAQUE_B,
  EMPAQUE_C,
  EMPAQUE_D,
  EMPAQUE_E,
  EMPAQUE_F,
} = CONSTANTS.INVENTORY

const {
  CANTIDAD_PEDIDA_EMPAQUES_A,
  PUNTO_DE_REORDEN_EMPAQUES_A,
  CANTIDAD_PEDIDA_EMPAQUES_B,
  PUNTO_DE_REORDEN_EMPAQUES_B,
  CANTIDAD_PEDIDA_EMPAQUES_C,
  PUNTO_DE_REORDEN_EMPAQUES_C,
  CANTIDAD_PEDIDA_EMPAQUES_D,
  PUNTO_DE_REORDEN_EMPAQUES_D,
  CANTIDAD_PEDIDA_EMPAQUES_E,
  PUNTO_DE_REORDEN_EMPAQUES_E,
  CANTIDAD_PEDIDA_EMPAQUES_F,
  PUNTO_DE_REORDEN_EMPAQUES_F,
} = INVENTORY

const columns = changeValue => [
  { type: 'label', name: PACKAGE },
  { type: 'inputNumber', name: REORDEN_QUANTITY, onChange: changeValue },
  { type: 'inputNumber', name: REORDEN_POINT, onChange: changeValue },
]

const returnData = variables => [
  [
    EMPAQUE_A,
    createColumnCellForVariable(CANTIDAD_PEDIDA_EMPAQUES_A, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_EMPAQUES_A, variables),
  ],
  [
    EMPAQUE_B,
    createColumnCellForVariable(CANTIDAD_PEDIDA_EMPAQUES_B, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_EMPAQUES_B, variables),
  ],
  [
    EMPAQUE_C,
    createColumnCellForVariable(CANTIDAD_PEDIDA_EMPAQUES_C, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_EMPAQUES_C, variables),
  ],
  [
    EMPAQUE_D,
    createColumnCellForVariable(CANTIDAD_PEDIDA_EMPAQUES_D, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_EMPAQUES_D, variables),
  ],
  [
    EMPAQUE_E,
    createColumnCellForVariable(CANTIDAD_PEDIDA_EMPAQUES_E, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_EMPAQUES_E, variables),
  ],
  [
    EMPAQUE_F,
    createColumnCellForVariable(CANTIDAD_PEDIDA_EMPAQUES_F, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_EMPAQUES_F, variables),
  ],
]

const InventoryPerPackage = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesPerPackage', dispatch, changeVariable)
  const variables = useSelector(state => state.inventory.currentValues.variablesPerPackage)
  return (
    <Table
      titleTable={INVENTORY_PER_PACKAGE}
      columns={columns(changeValue)}
      data={returnData(variables)}
      helpMessage={HELPS[INVENTORY_PER_PACKAGE]}
    />
  )
}

export default InventoryPerPackage
