import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import NumberFormat from 'react-number-format'

const useStyle = makeStyles({
  textField: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #23282c',
    borderRadius: '.25rem',
    color: '#777777',
    fontSize: '.875rem',
    padding: '.375rem .75rem',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
})

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
    />
  )
})

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const validateMaxAndMinValues = (value, { setValue, max, min }, setShowHelper) => {
  let valueValidate = Number(value)
  if (valueValidate < min) {
    valueValidate = min
    setShowHelper(`the value can not be less than ${min}`)
  } else if (valueValidate > max) {
    valueValidate = max
    setShowHelper(`the value can not be greater than ${max}`)
  } else {
    setShowHelper('')
  }

  setValue(valueValidate)
}

const InputNumber = ({ defaultValue, change, id, max, min }) => {
  const classes = useStyle()
  let initialValue = defaultValue
  if (!Number.isInteger(initialValue)) {
    initialValue = Number(initialValue.toFixed(2))
  }
  const [value, setValue] = useState(initialValue)
  const [textHelper, setShowHelper] = useState('')
  const propertyValue = {
    setValue,
    max,
    min,
  }

  const onBlur = (event, currentvalue) => {
    change({ id: event.target.id, value: currentvalue })
    setShowHelper('')
  }

  return (
    <LightTooltip title={textHelper || ''} open={!!textHelper}>
      <TextField
        name="numberformat"
        id={id}
        value={value}
        key={`${id}-textField`}
        onBlur={event => onBlur(event, value)}
        onChange={event =>
          validateMaxAndMinValues(event.target.value, propertyValue, setShowHelper)
        }
        margin="none"
        variant="outlined"
        InputProps={{
          maxLength: 10,
          autoComplete: 'off',
          classes: {
            input: classes.textField,
          },
          inputComponent: NumberFormatCustom,
        }}
      />
    </LightTooltip>
  )
}

InputNumber.defaultProps = {
  defaultValue: '',
  id: '',
  max: 999999999,
  min: 0,
}

export default InputNumber
