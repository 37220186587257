import React from 'react'
import { useSelector } from 'react-redux'
import ListInputDisable from 'components/ListInputDisable'
import { CONSTANTS } from 'constants/constants'
import { OPERATIONS } from 'constants/forioVariables'
import { Card, CardContent, Box } from '@material-ui/core'
import { GaugeChart } from 'components/Charts'
import useStyles from './style'

const { HUMAN_RESOURCES, HUMAN_LABOR_IMPACT, ROOKIES_TEXT, EXPERTS_TEXT } = CONSTANTS.OPERATIONS

const { CAPACITY_FACTOR_POR_PERSONAL, ROOKIES, EXPERTS } = OPERATIONS

const createListRowForVariable = (label, variables, variableId) => {
  return {
    id: `list-${variableId}`,
    label,
    value: variables[variableId],
  }
}

const returnElements = variables => [
  createListRowForVariable(ROOKIES_TEXT, variables, ROOKIES),
  createListRowForVariable(EXPERTS_TEXT, variables, EXPERTS),
]

const WorkForce = () => {
  const classes = useStyles()
  const variables = useSelector(state => state.operations.currentValues.humanResources)

  return (
    <Card className={classes.card}>
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{HUMAN_RESOURCES}</h2>
      </div>
      <CardContent>
        <Box key="workForce" display="flex" alignItems="top">
          <GaugeChart
            titleChart={HUMAN_LABOR_IMPACT}
            value={variables[CAPACITY_FACTOR_POR_PERSONAL].toFixed(2)}
          />
          <ListInputDisable items={returnElements(variables)} />
        </Box>
      </CardContent>
    </Card>
  )
}
export default WorkForce
