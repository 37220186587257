import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import MessagesComponent from '../Message/MessagesComponent'
import 'styles/modalsStyle.css'

const useStyles = makeStyles({
  root: {},
  paper: {
    backgroundColor: '#FFFFFF',
    color: '#73879C',
  },
  buttonRoot: {
    color: '#73879C',
    borderColor: '#73879C',
  },
})

const ShareModal = props => {
  const [copySuccess, setCopySuccess] = useState(false)

  const handleCopyToClipboard = textToCopy => {
    setCopySuccess(true)

    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(textToCopy)
    } else {
      return document.execCommand('copy', true, textToCopy)
    }
  }

  const classes = useStyles()
  const {
    open,
    handleSecondaryButton,
    titleModal,
    messageType,
    shareLink,
    titleSecondaryButton,
  } = props

  const shareLabel = 'Share this link with the classmates that you want joining your team'

  return (
    <Dialog
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      open={open}
      onClose={handleSecondaryButton}
    >
      <DialogTitle>{titleModal}</DialogTitle>
      <DialogContent>
        <p>{shareLabel}</p>
        <MessagesComponent message={shareLink} info={messageType} />
        {copySuccess ? <p>Copy Successful</p> : null}
      </DialogContent>

      <DialogActions>
        <Button
          classes={{ root: classes.buttonRoot }}
          disabled={!messageType}
          variant="outlined"
          onClick={() => handleCopyToClipboard(shareLink)}
        >
          Copy
        </Button>
        <Button
          classes={{ root: classes.buttonRoot }}
          variant="outlined"
          onClick={handleSecondaryButton}
        >
          {titleSecondaryButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ShareModal
