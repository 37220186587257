import axios from 'axios'
import { getCurrentSession, login } from './authService'

export const getConfig = async () => {
  const session = await getCurrentSession()
  if (session) {
    const token = session.tokenInfo.idToken
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
        'ZUMO-API-VERSION': '2.0.0',
        Authorization: `Bearer ${token}`,
      },
    }
    return axiosConfig
  } else {
    login()
  }
}

export const sendGetRequest = async (apiRoute, reference, parameters) => {
  const config = await getConfig()
  if (config) {
    const referenceEncoded = encodeURI(reference)
    return axios({
      method: 'get',
      url: `${apiRoute}${referenceEncoded}`,
      headers: config.headers,
      responseType: 'stream',
      params: parameters,
    })
  }
}

export const sendPostRequest = async (apiRoute, postData) => {
  const config = await getConfig()
  if (config) {
    return axios({
      method: 'post',
      url: apiRoute,
      headers: config.headers,
      data: JSON.stringify(postData),
    })
  }
}
