import React from 'react'
import { OPERATIONS } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import BaseChart from './BaseChart'

const { PRODUCT_READY_FOR_SALE } = OPERATIONS
const { PRODUCT_FOR_SALE } = CONSTANTS.OPERATIONS

const ChartProductForSale = () => {
  return <BaseChart title={PRODUCT_FOR_SALE} variableId={PRODUCT_READY_FOR_SALE} />
}

export default ChartProductForSale
