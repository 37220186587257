import {
  CLEAR_NEW_VALUES,
  CHANGE_VARIABLE_RESULTS,
  GET_VARIABLES_RESULTS,
  GET_VARIABLES_FINANCES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId } from 'services/forio/forio'
import { RESULTS } from 'constants/forioVariables'

const {
  COSTO_ACUMULADO_DE_MATERIALES_VENDIDOS_SIN_LIMPIA_PARA_SABLE,
  COSTOS_ACUMULADOS_POR_MANO_DE_OBRA_SIN_LIMPIA_PARA_SABLE,
  DEPRECIACION_ACUMULADA_PARA_COSTOS_VARIABLES_DE_PRODUCCIÓN_PARA_SABLE,
  UTILITIES_COST_ACUMULADO_PARA_SABLE,
  ACCUMULATED_MAINTENANCE_EXPENSES_PARA_SABLE,
  TOTAL_COSTO_DE_VENTAS_PARA_SABLE,
  COSTO_ACUMULADO_DE_CAMIONES_PARA_SABLE,
  DEPRECIACION_ACUMULADA_DE_CARROS_PARA_SABLE,
  ACCUMULATED_EGRESOS_POR_SALES_AND_ADMINISTRATION_PARA_SABLE,
  ACCUMULATED_MARKETING_EXPENSES_PARA_SABLE,
  ACCUMULATED_HIRING_COST_PARA_SABLE,
  ACCUMULATED_FIRING_COST_PARA_SABLE,
  ACCUMULATED_INVENTORY_HOLDING_COST,
  DEPRECIACION_ACUMULADA_PLANTA_EDIFICIOS_YOTROS_PARA_SABLE,
  ACCUMULATED_INTEREST_EXPENSES_PARA_SABLE,
  UTILIDAD_ANTES_DE_IMPUESTOS_PARA_SABLE,
  ACCUMULATED_IMPUESTO_SOBRE_LA_RENTA_PARA_SABLE,
  CAJA_PARA_SABLE,
  CUENTAS_POR_COBRAR_PARA_SABLE,
  INVENTARIO_TOTAL_DOLARES_PARA_SABLE,
  TOTAL_ACTIVO_CIRCULANTE_PARA_SABLE,
  TOTAL_ACTIVO_FIJO_PARA_SABLE,
  TOTAL_ACTIVO_PARA_SABLE,
  CUENTAS_POR_PAGAR_PROVEEDORES_PARA_SABLE,
  PRESTAMO_PARA_SABLE,
  TOTAL_PASIVO_PARA_SABLE,
  ACCIONES_PARA_SABLE,
  UTILIDADES_RETENIDAS_PARA_SABLE,
  TOTAL_CAPITAL_PARA_SABLE,
  TOTAL_PASIVO_MAS_CAPITAL_PARA_SABLE,
  ACCUMULATED_TOTAL_SALES_PARA_SABLE,
  UTILIDAD_BRUTA_ACUMULADA_PARA_SABLE,
  ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE,
  TOTAL_ACCUMULATED_OVERHEAD_EXPENSES_PARA_SABLE,
  DIAS_DE_COBRO,
  DEBT_AMORTIZATION_PERCENTAGE_TRIMESTER_DECISION,
} = RESULTS

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_RESULTS, payload }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const results = [
  COSTO_ACUMULADO_DE_MATERIALES_VENDIDOS_SIN_LIMPIA_PARA_SABLE,
  COSTOS_ACUMULADOS_POR_MANO_DE_OBRA_SIN_LIMPIA_PARA_SABLE,
  DEPRECIACION_ACUMULADA_PARA_COSTOS_VARIABLES_DE_PRODUCCIÓN_PARA_SABLE,
  UTILITIES_COST_ACUMULADO_PARA_SABLE,
  ACCUMULATED_MAINTENANCE_EXPENSES_PARA_SABLE,
  TOTAL_COSTO_DE_VENTAS_PARA_SABLE,
  COSTO_ACUMULADO_DE_CAMIONES_PARA_SABLE,
  DEPRECIACION_ACUMULADA_DE_CARROS_PARA_SABLE,
  ACCUMULATED_EGRESOS_POR_SALES_AND_ADMINISTRATION_PARA_SABLE,
  ACCUMULATED_MARKETING_EXPENSES_PARA_SABLE,
  ACCUMULATED_HIRING_COST_PARA_SABLE,
  ACCUMULATED_FIRING_COST_PARA_SABLE,
  ACCUMULATED_INVENTORY_HOLDING_COST,
  DEPRECIACION_ACUMULADA_PLANTA_EDIFICIOS_YOTROS_PARA_SABLE,
  ACCUMULATED_INTEREST_EXPENSES_PARA_SABLE,
  UTILIDAD_ANTES_DE_IMPUESTOS_PARA_SABLE,
  ACCUMULATED_IMPUESTO_SOBRE_LA_RENTA_PARA_SABLE,
  CAJA_PARA_SABLE,
  CUENTAS_POR_COBRAR_PARA_SABLE,
  INVENTARIO_TOTAL_DOLARES_PARA_SABLE,
  TOTAL_ACTIVO_CIRCULANTE_PARA_SABLE,
  TOTAL_ACTIVO_FIJO_PARA_SABLE,
  TOTAL_ACTIVO_PARA_SABLE,
  CUENTAS_POR_PAGAR_PROVEEDORES_PARA_SABLE,
  PRESTAMO_PARA_SABLE,
  TOTAL_PASIVO_PARA_SABLE,
  ACCIONES_PARA_SABLE,
  UTILIDADES_RETENIDAS_PARA_SABLE,
  TOTAL_CAPITAL_PARA_SABLE,
  TOTAL_PASIVO_MAS_CAPITAL_PARA_SABLE,
  ACCUMULATED_TOTAL_SALES_PARA_SABLE,
  UTILIDAD_BRUTA_ACUMULADA_PARA_SABLE,
  ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE,
  TOTAL_ACCUMULATED_OVERHEAD_EXPENSES_PARA_SABLE,
]

const getVariablesResults = variables => ({
  type: GET_VARIABLES_RESULTS,
  variables,
})

const fetchVariablesResults = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(results)
    dispatch(getVariablesResults(variables))
  }
}

const finances = [DIAS_DE_COBRO, DEBT_AMORTIZATION_PERCENTAGE_TRIMESTER_DECISION]

const getVariablesFinances = variables => ({
  type: GET_VARIABLES_FINANCES,
  variables,
})

const fetchVariablesFinances = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(finances)
    dispatch(getVariablesFinances(variables))
  }
}

const allFunctionsFetchResults = [fetchVariablesResults, fetchVariablesFinances]

export {
  allFunctionsFetchResults,
  fetchVariablesResults,
  fetchVariablesFinances,
  changeVariable,
  clearNewValues,
}
