import React from "react";
import { withRouter } from "react-router-dom";
import LogoutButton from "../../common/logoutButton";
import "../../styles/header.css";

const HeaderComponent = props => {
  const { showLogoutButton } = props;

  let logoutButton = null;

  if (showLogoutButton) {
    logoutButton = <LogoutButton />;
  }

  return (
    <div className="customHeader">
      <div className="headerLeft">
        <img
          className="logo"
          alt="logo"
          src="assets/images/pl-logo.png"
          width={150}
        />
      </div>
      <div className="headerRight">
        <div className="studentActions">{logoutButton}</div>
      </div>
    </div>
  );
};

export default withRouter(HeaderComponent);
