import React from 'react'
import { OPERATIONS } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import BaseChart from './BaseChart'

const { WIP_AGEING_TANKS } = OPERATIONS
const { WIP_AGEING_TANKS_TEXT } = CONSTANTS.OPERATIONS

const ChartAgeingTanks = () => {
  return <BaseChart title={WIP_AGEING_TANKS_TEXT} variableId={WIP_AGEING_TANKS} />
}

export default ChartAgeingTanks
