const CLEAR_NEW_VALUES = 'CLEAR_NEW_VALUES'
const CLEAR_VALUES = 'CLEAR_VALUES'

// HEADER
const GET_TIME = 'GET_TIME'

// INVENTORY
const GET_VARIABLES_PER_PACKAGE = 'GET_VARIABLES_PER_PACKAGE'
const GET_VARIABLES_RAW_MATERIALS = 'GET_VARIABLES_RAW_MATERIALS'
const GET_VARIABLES_CHART_PACKAGING_MATERIAL = 'GET_VARIABLES_CHART_PACKAGING_MATERIAL'
const GET_VARIABLES_CHART_RAW_MATERIALS = 'GET_VARIABLES_CHART_RAW_MATERIALS'
const CHANGE_VARIABLE_INVENTORY = 'CHANGE_VARIABLE_INVENTORY'

// OPERATIONS
const GET_VARIABLES_EQUIPMENT_AND_FACILITIES = 'GET_VARIABLES_EQUIPMENT_AND_FACILITIES'
const GET_VARIABLES_PRODUCTION = 'GET_VARIABLES_PRODUCTION'
const GET_VARIABLES_HUMAN_RESOURCES = 'GET_VARIABLES_HUMAN_RESOURCES'
const GET_VARIABLES_MAINTENANCE = 'GET_VARIABLES_MAINTENANCE'
const GET_VARIABLES_EQUIPMENT = 'GET_VARIABLES_EQUIPMENT'
const GET_VARIABLES_CHART = 'GET_VARIABLES_CHART'
const GET_VARIABLES_QUALITY = 'GET_VARIABLES_QUALITY'
const CHANGE_VARIABLE_OPERATIONS = 'CHANGE_VARIABLE_OPERATIONS'

// PRODUCTS
const GET_VARIABLES_PACKAGING = 'GET_VARIABLES_PACKAGING'
const GET_VARIABLES_FLAVORS = 'GET_VARIABLES_FLAVORS'
const GET_VARIABLES_INVENTORY_FG_PACKAGE_A = 'GET_VARIABLES_INVENTORY_FG_PACKAGE_A'
const GET_VARIABLES_INVENTORY_FG_PACKAGE_B = 'GET_VARIABLES_INVENTORY_FG_PACKAGE_B'
const GET_VARIABLES_INVENTORY_FG_PACKAGE_C = 'GET_VARIABLES_INVENTORY_FG_PACKAGE_C'
const GET_VARIABLES_INVENTORY_FG_PACKAGE_D = 'GET_VARIABLES_INVENTORY_FG_PACKAGE_D'
const GET_VARIABLES_INVENTORY_FG_PACKAGE_E = 'GET_VARIABLES_INVENTORY_FG_PACKAGE_E'
const GET_VARIABLES_INVENTORY_FG_PACKAGE_F = 'GET_VARIABLES_INVENTORY_FG_PACKAGE_F'
const GET_VARIABLES_PACKAGING_PRIORITY = 'GET_VARIABLES_PACKAGING_PRIORITY'
const GET_VARIABLES_FLAVORS_PRIORITY = 'GET_VARIABLES_FLAVORS_PRIORITY'
const GET_VARIABLES_BI_WEEKLY_PRODUCTION = 'GET_VARIABLES_BI_WEEKLY_PRODUCTION'
const CHANGE_VARIABLE_PRODUCTS = 'CHANGE_VARIABLE_PRODUCTS'

// MARKETING AND LOGISTICS
const GET_VARIABLES_PRICING = 'GET_VARIABLES_PRICING'
const GET_VARIABLES_MARKETING = 'GET_VARIABLES_MARKETING'
const GET_VARIABLES_LOGISTICS = 'GET_VARIABLES_LOGISTICS'
const GET_VARIABLES_MARKET_SHARE_TOTAL = 'GET_VARIABLES_MARKET_SHARE'
const GET_VARIABLES_PERIOD_SALES = 'GET_VARIABLES_PERIOD_SALES'
const GET_VARIABLES_MARKET_PRICE = 'GET_VARIABLES_MARKET_PRICE'
const GET_VARIABLES_MARKET_SHARES = 'GET_VARIABLES_MARKET_SHARES'
const GET_VARIABLES_FILL_RATES = 'GET_VARIABLES_FILL_RATES'
const GET_VARIABLES_FLEET = 'GET_VARIABLES_FLEET'
const GET_VARIABLES_SALES_PACKAGE_A = 'GET_VARIABLES_SALES_PACKAGE_A'
const GET_VARIABLES_SALES_PACKAGE_B = 'GET_VARIABLES_SALES_PACKAGE_B'
const GET_VARIABLES_SALES_PACKAGE_C = 'GET_VARIABLES_SALES_PACKAGE_C'
const GET_VARIABLES_SALES_PACKAGE_D = 'GET_VARIABLES_SALES_PACKAGE_D'
const GET_VARIABLES_SALES_PACKAGE_E = 'GET_VARIABLES_SALES_PACKAGE_E'
const GET_VARIABLES_SALES_PACKAGE_F = 'GET_VARIABLES_SALES_PACKAGE_F'
const CHANGE_VARIABLE_MARKETING_AND_LOGISTICS = 'CHANGE_VARIABLE_MARKETING_AND_LOGISTICS'

// RESULTS
const CHANGE_VARIABLE_RESULTS = 'CHANGE_VARIABLE_RESULTS'
const GET_VARIABLES_RESULTS = 'GET_VARIABLES_RESULTS'
const GET_VARIABLES_FINANCES = 'GET_VARIABLES_FINANCES'

// APP BACKEND
const CREATE_TEAM = 'CREATE_TEAM'
const CREATE_TEAM_SUCCESSFUL = 'CREATE_TEAM_SUCCESSFUL'
const CREATE_TEAM_FAILED = 'CREATE_TEAM_FAILED'
const RESET_CREATE_TEAM = 'RESET_CREATE_TEAM'
const JOIN_TEAM = 'JOIN_TEAM'
const JOIN_TEAM_SUCCESSFUL = 'JOIN_TEAM_SUCCESSFUL'
const JOIN_TEAM_FAILED = 'JOIN_TEAM_FAILED'
const GET_SIMULATIONS = 'GET_SIMULATIONS'
const GET_SIMULATIONS_SUCCESSFUL = 'GET_SIMULATIONS_SUCCESSFUL'
const GET_SIMULATIONS_FAILED = 'GET_SIMULATIONS_FAILED'

// Excel
const GET_VARIABLES_EXCEL = 'GET_VARIABLES_EXCEL'
const EXCEL_FILE_DOWNLOADED = 'EXCEL_FILE_DOWNLOADED'

export {
  CLEAR_NEW_VALUES,
  CLEAR_VALUES,
  GET_TIME,
  GET_VARIABLES_PER_PACKAGE,
  GET_VARIABLES_RAW_MATERIALS,
  GET_VARIABLES_CHART_PACKAGING_MATERIAL,
  GET_VARIABLES_CHART_RAW_MATERIALS,
  CREATE_TEAM,
  CREATE_TEAM_SUCCESSFUL,
  CREATE_TEAM_FAILED,
  RESET_CREATE_TEAM,
  JOIN_TEAM,
  JOIN_TEAM_SUCCESSFUL,
  JOIN_TEAM_FAILED,
  GET_SIMULATIONS,
  GET_SIMULATIONS_SUCCESSFUL,
  GET_SIMULATIONS_FAILED,
  GET_VARIABLES_EQUIPMENT_AND_FACILITIES,
  GET_VARIABLES_PRODUCTION,
  GET_VARIABLES_HUMAN_RESOURCES,
  GET_VARIABLES_MAINTENANCE,
  GET_VARIABLES_EQUIPMENT,
  GET_VARIABLES_CHART,
  GET_VARIABLES_QUALITY,
  GET_VARIABLES_PACKAGING,
  GET_VARIABLES_FLAVORS,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_A,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_B,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_C,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_D,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_E,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_F,
  GET_VARIABLES_PACKAGING_PRIORITY,
  GET_VARIABLES_FLAVORS_PRIORITY,
  GET_VARIABLES_BI_WEEKLY_PRODUCTION,
  GET_VARIABLES_PRICING,
  GET_VARIABLES_MARKETING,
  GET_VARIABLES_LOGISTICS,
  GET_VARIABLES_MARKET_SHARE_TOTAL,
  GET_VARIABLES_PERIOD_SALES,
  GET_VARIABLES_MARKET_PRICE,
  GET_VARIABLES_MARKET_SHARES,
  GET_VARIABLES_FILL_RATES,
  GET_VARIABLES_FLEET,
  GET_VARIABLES_SALES_PACKAGE_A,
  GET_VARIABLES_SALES_PACKAGE_B,
  GET_VARIABLES_SALES_PACKAGE_C,
  GET_VARIABLES_SALES_PACKAGE_D,
  GET_VARIABLES_SALES_PACKAGE_E,
  GET_VARIABLES_SALES_PACKAGE_F,
  GET_VARIABLES_RESULTS,
  GET_VARIABLES_FINANCES,
  GET_VARIABLES_EXCEL,
  EXCEL_FILE_DOWNLOADED,
  CHANGE_VARIABLE_INVENTORY,
  CHANGE_VARIABLE_OPERATIONS,
  CHANGE_VARIABLE_PRODUCTS,
  CHANGE_VARIABLE_MARKETING_AND_LOGISTICS,
  CHANGE_VARIABLE_RESULTS,
}
