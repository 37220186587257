import React from "react";
import { footerStyle } from "./style";

const Footer = () => {
  const classes = footerStyle();

  return (
    <div className={classes.footerBox}>
      <p className={classes.footerText}>
        All rights are reserved. Distribution is prohibited.
        <a className={classes.footerLink} href="http://processimlabs.com">
          {" "}
          Processim Labs
        </a>
      </p>
    </div>
  );
};

export default Footer;
