import {
  CLEAR_NEW_VALUES,
  CHANGE_VARIABLE_PRODUCTS,
  GET_VARIABLES_PACKAGING,
  GET_VARIABLES_FLAVORS,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_A,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_B,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_C,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_D,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_E,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_F,
  GET_VARIABLES_PACKAGING_PRIORITY,
  GET_VARIABLES_FLAVORS_PRIORITY,
  GET_VARIABLES_BI_WEEKLY_PRODUCTION,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { PRODUCTS } from 'constants/forioVariables'

const {
  PACKAGING_DECISION_EMP_A,
  PACKAGING_DECISION_EMP_B,
  PACKAGING_DECISION_EMP_C,
  PACKAGING_DECISION_EMP_D,
  PACKAGING_DECISION_EMP_E,
  PACKAGING_DECISION_EMP_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_C,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_F,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_L,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_M,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_R,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_V,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V,
  TOTAL_FG_INVENTORY_A,
  PT_PAQUETE_A_SABOR_C,
  PT_PAQUETE_A_SABOR_F,
  PT_PAQUETE_A_SABOR_L,
  PT_PAQUETE_A_SABOR_M,
  PT_PAQUETE_A_SABOR_R,
  PT_PAQUETE_A_SABOR_V,
  TOTAL_FG_INVENTORY_B,
  PT_PAQUETE_B_SABOR_C,
  PT_PAQUETE_B_SABOR_F,
  PT_PAQUETE_B_SABOR_L,
  PT_PAQUETE_B_SABOR_M,
  PT_PAQUETE_B_SABOR_R,
  PT_PAQUETE_B_SABOR_V,
  TOTAL_FG_INVENTORY_C,
  PT_PAQUETE_C_SABOR_C,
  PT_PAQUETE_C_SABOR_F,
  PT_PAQUETE_C_SABOR_L,
  PT_PAQUETE_C_SABOR_M,
  PT_PAQUETE_C_SABOR_R,
  PT_PAQUETE_C_SABOR_V,
  TOTAL_FG_INVENTORY_D,
  PT_PAQUETE_D_SABOR_C,
  PT_PAQUETE_D_SABOR_F,
  PT_PAQUETE_D_SABOR_L,
  PT_PAQUETE_D_SABOR_M,
  PT_PAQUETE_D_SABOR_R,
  PT_PAQUETE_D_SABOR_V,
  TOTAL_FG_INVENTORY_E,
  PT_PAQUETE_E_SABOR_C,
  PT_PAQUETE_E_SABOR_F,
  PT_PAQUETE_E_SABOR_L,
  PT_PAQUETE_E_SABOR_M,
  PT_PAQUETE_E_SABOR_R,
  PT_PAQUETE_E_SABOR_V,
  TOTAL_FG_INVENTORY_F,
  PT_PAQUETE_F_SABOR_C,
  PT_PAQUETE_F_SABOR_F,
  PT_PAQUETE_F_SABOR_L,
  PT_PAQUETE_F_SABOR_M,
  PT_PAQUETE_F_SABOR_R,
  PT_PAQUETE_F_SABOR_V,
  PACKING_PRIORITIES_EMP_A,
  PACKING_PRIORITIES_EMP_B,
  PACKING_PRIORITIES_EMP_C,
  PACKING_PRIORITIES_EMP_D,
  PACKING_PRIORITIES_EMP_E,
  PACKING_PRIORITIES_EMP_F,
  FLAVOR_PRIORITIES_SABOR_C,
  FLAVOR_PRIORITIES_SABOR_F,
  FLAVOR_PRIORITIES_SABOR_L,
  FLAVOR_PRIORITIES_SABOR_M,
  FLAVOR_PRIORITIES_SABOR_R,
  FLAVOR_PRIORITIES_SABOR_V,
  FORTNIGHTLY_PRODUCTION_DECISION_HELADO,
} = PRODUCTS

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_PRODUCTS, payload }
}

const packaging = [
  PACKAGING_DECISION_EMP_A,
  PACKAGING_DECISION_EMP_B,
  PACKAGING_DECISION_EMP_C,
  PACKAGING_DECISION_EMP_D,
  PACKAGING_DECISION_EMP_E,
  PACKAGING_DECISION_EMP_F,
]

const getVariablesPackaging = variables => ({
  type: GET_VARIABLES_PACKAGING,
  variables,
})

const fetchVariablesPackaging = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(packaging)
    dispatch(getVariablesPackaging(variables))
  }
}

const flavors = [
  EMPAQUE_A_FLAVOR_DECISION_SABOR_C,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_F,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_L,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_M,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_R,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_V,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V,
]

const getVariablesFlavors = variables => ({
  type: GET_VARIABLES_FLAVORS,
  variables,
})

const fetchVariablesFlavors = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(flavors)
    dispatch(getVariablesFlavors(variables))
  }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const inventoryPackageA = [
  TOTAL_FG_INVENTORY_A,
  PT_PAQUETE_A_SABOR_C,
  PT_PAQUETE_A_SABOR_F,
  PT_PAQUETE_A_SABOR_L,
  PT_PAQUETE_A_SABOR_M,
  PT_PAQUETE_A_SABOR_R,
  PT_PAQUETE_A_SABOR_V,
]

const getVariablesInventoryPackageA = variables => ({
  type: GET_VARIABLES_INVENTORY_FG_PACKAGE_A,
  variables,
})

const fetchVariablesInventoryPackageA = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(inventoryPackageA)
    dispatch(getVariablesInventoryPackageA(variables))
  }
}

const inventoryPackageB = [
  TOTAL_FG_INVENTORY_B,
  PT_PAQUETE_B_SABOR_C,
  PT_PAQUETE_B_SABOR_F,
  PT_PAQUETE_B_SABOR_L,
  PT_PAQUETE_B_SABOR_M,
  PT_PAQUETE_B_SABOR_R,
  PT_PAQUETE_B_SABOR_V,
]

const getVariablesInventoryPackageB = variables => ({
  type: GET_VARIABLES_INVENTORY_FG_PACKAGE_B,
  variables,
})

const fetchVariablesInventoryPackageB = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(inventoryPackageB)
    dispatch(getVariablesInventoryPackageB(variables))
  }
}
const inventoryPackageC = [
  TOTAL_FG_INVENTORY_C,
  PT_PAQUETE_C_SABOR_C,
  PT_PAQUETE_C_SABOR_F,
  PT_PAQUETE_C_SABOR_L,
  PT_PAQUETE_C_SABOR_M,
  PT_PAQUETE_C_SABOR_R,
  PT_PAQUETE_C_SABOR_V,
]

const getVariablesInventoryPackageC = variables => ({
  type: GET_VARIABLES_INVENTORY_FG_PACKAGE_C,
  variables,
})

const fetchVariablesInventoryPackageC = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(inventoryPackageC)
    dispatch(getVariablesInventoryPackageC(variables))
  }
}

const inventoryPackageD = [
  TOTAL_FG_INVENTORY_D,
  PT_PAQUETE_D_SABOR_C,
  PT_PAQUETE_D_SABOR_F,
  PT_PAQUETE_D_SABOR_L,
  PT_PAQUETE_D_SABOR_M,
  PT_PAQUETE_D_SABOR_R,
  PT_PAQUETE_D_SABOR_V,
]

const getVariablesInventoryPackageD = variables => ({
  type: GET_VARIABLES_INVENTORY_FG_PACKAGE_D,
  variables,
})

const fetchVariablesInventoryPackageD = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(inventoryPackageD)
    dispatch(getVariablesInventoryPackageD(variables))
  }
}

const inventoryPackageE = [
  TOTAL_FG_INVENTORY_E,
  PT_PAQUETE_E_SABOR_C,
  PT_PAQUETE_E_SABOR_F,
  PT_PAQUETE_E_SABOR_L,
  PT_PAQUETE_E_SABOR_M,
  PT_PAQUETE_E_SABOR_R,
  PT_PAQUETE_E_SABOR_V,
]

const getVariablesInventoryPackageE = variables => ({
  type: GET_VARIABLES_INVENTORY_FG_PACKAGE_E,
  variables,
})

const fetchVariablesInventoryPackageE = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(inventoryPackageE)
    dispatch(getVariablesInventoryPackageE(variables))
  }
}

const inventoryPackageF = [
  TOTAL_FG_INVENTORY_F,
  PT_PAQUETE_F_SABOR_C,
  PT_PAQUETE_F_SABOR_F,
  PT_PAQUETE_F_SABOR_L,
  PT_PAQUETE_F_SABOR_M,
  PT_PAQUETE_F_SABOR_R,
  PT_PAQUETE_F_SABOR_V,
]

const getVariablesInventoryPackageF = variables => ({
  type: GET_VARIABLES_INVENTORY_FG_PACKAGE_F,
  variables,
})

const fetchVariablesInventoryPackageF = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(inventoryPackageF)
    dispatch(getVariablesInventoryPackageF(variables))
  }
}

const packagingPriority = [
  PACKING_PRIORITIES_EMP_A,
  PACKING_PRIORITIES_EMP_B,
  PACKING_PRIORITIES_EMP_C,
  PACKING_PRIORITIES_EMP_D,
  PACKING_PRIORITIES_EMP_E,
  PACKING_PRIORITIES_EMP_F,
]

const getVariablesPackagingPriority = variables => ({
  type: GET_VARIABLES_PACKAGING_PRIORITY,
  variables,
})

const fetchVariablesPackagingPriority = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(packagingPriority)
    dispatch(getVariablesPackagingPriority(variables))
  }
}

const flavorsPriority = [
  FLAVOR_PRIORITIES_SABOR_C,
  FLAVOR_PRIORITIES_SABOR_F,
  FLAVOR_PRIORITIES_SABOR_L,
  FLAVOR_PRIORITIES_SABOR_M,
  FLAVOR_PRIORITIES_SABOR_R,
  FLAVOR_PRIORITIES_SABOR_V,
]

const getVariablesFlavorsPriority = variables => ({
  type: GET_VARIABLES_FLAVORS_PRIORITY,
  variables,
})

const fetchVariablesFlavorsPriority = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(flavorsPriority)
    dispatch(getVariablesFlavorsPriority(variables))
  }
}

const biweeklyProduction = [FORTNIGHTLY_PRODUCTION_DECISION_HELADO]

const getVariablesBiweeklyProduction = variables => ({
  type: GET_VARIABLES_BI_WEEKLY_PRODUCTION,
  variables,
})

const fetchVariablesBiweeklyProduction = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(biweeklyProduction)
    dispatch(getVariablesBiweeklyProduction(variables))
  }
}

const allFunctionsFetchProducts = [
  fetchVariablesPackaging,
  fetchVariablesFlavors,
  fetchVariablesInventoryPackageA,
  fetchVariablesInventoryPackageB,
  fetchVariablesInventoryPackageC,
  fetchVariablesInventoryPackageD,
  fetchVariablesInventoryPackageE,
  fetchVariablesInventoryPackageF,
  fetchVariablesPackagingPriority,
  fetchVariablesFlavorsPriority,
  fetchVariablesBiweeklyProduction,
]

export {
  allFunctionsFetchProducts,
  changeVariable,
  clearNewValues,
  fetchVariablesPackaging,
  fetchVariablesFlavors,
  fetchVariablesInventoryPackageA,
  fetchVariablesInventoryPackageB,
  fetchVariablesInventoryPackageC,
  fetchVariablesInventoryPackageD,
  fetchVariablesInventoryPackageE,
  fetchVariablesInventoryPackageF,
  fetchVariablesPackagingPriority,
  fetchVariablesFlavorsPriority,
  fetchVariablesBiweeklyProduction,
}
