import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { OPERATIONS } from 'constants/forioVariables'
import { changeVariable } from 'actions/operationsActions'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { changeValue as onChangeValue } from 'common'
import { HELPS } from 'constants/helps'

const {
  EQUIPMENT_AND_FACILITIES,
  DESIRED_PASTEURIZERS,
  DESIRED_AGING_TANKS,
  DESIRED_BATCH_FREEZERS,
  DESIRED_COLD_STORAGE_SPACE,
} = CONSTANTS.OPERATIONS

const {
  PASTEURIZERS_DESIRED,
  MATURATION_TANKS_DESIRED,
  BATIDORAS_DESIRED,
  CUARTO_FRIO_DESIRED,
} = OPERATIONS

const returnElements = (variables, changeValue) => [
  createListRowForVariable(
    PASTEURIZERS_DESIRED,
    DESIRED_PASTEURIZERS,
    variables,
    changeValue,
    HELPS[PASTEURIZERS_DESIRED],
  ),
  createListRowForVariable(
    MATURATION_TANKS_DESIRED,
    DESIRED_AGING_TANKS,
    variables,
    changeValue,
    HELPS[MATURATION_TANKS_DESIRED],
  ),
  createListRowForVariable(
    BATIDORAS_DESIRED,
    DESIRED_BATCH_FREEZERS,
    variables,
    changeValue,
    HELPS[BATIDORAS_DESIRED],
  ),
  createListRowForVariable(
    CUARTO_FRIO_DESIRED,
    DESIRED_COLD_STORAGE_SPACE,
    variables,
    changeValue,
    HELPS[CUARTO_FRIO_DESIRED],
  ),
]
const ListEquipmentAndFacilities = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('equipmentAndFacilities', dispatch, changeVariable)
  const variables = useSelector(state => state.operations.currentValues.equipmentAndFacilities)
  return (
    <List items={returnElements(variables, changeValue)} listTitle={EQUIPMENT_AND_FACILITIES} />
  )
}
export default ListEquipmentAndFacilities
