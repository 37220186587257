import {
  CHANGE_VARIABLE_PRODUCTS,
  CLEAR_NEW_VALUES,
  CLEAR_VALUES,
  GET_VARIABLES_PACKAGING,
  GET_VARIABLES_FLAVORS,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_A,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_B,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_C,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_D,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_E,
  GET_VARIABLES_INVENTORY_FG_PACKAGE_F,
  GET_VARIABLES_PACKAGING_PRIORITY,
  GET_VARIABLES_FLAVORS_PRIORITY,
  GET_VARIABLES_BI_WEEKLY_PRODUCTION,
} from 'constants/actionTypes'

const initialProducts = {
  currentValues: {
    variablesPackaging: {},
    variablesFlavors: {},
    variablesChartInventoryPackageA: {},
    variablesChartInventoryPackageB: {},
    variablesChartInventoryPackageC: {},
    variablesChartInventoryPackageD: {},
    variablesChartInventoryPackageE: {},
    variablesChartInventoryPackageF: {},
    variablesPackagingPriority: {},
    variablesFlavorsPriority: {},
    variablesBiweeklyProduction: {},
  },
  newValues: {},
}

const products = (state = initialProducts, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialProducts
    case GET_VARIABLES_PACKAGING:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesPackaging: action.variables,
        },
      }
    case GET_VARIABLES_FLAVORS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesFlavors: action.variables,
        },
      }
    case GET_VARIABLES_INVENTORY_FG_PACKAGE_A:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartInventoryPackageA: action.variables,
        },
      }
    case GET_VARIABLES_INVENTORY_FG_PACKAGE_B:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartInventoryPackageB: action.variables,
        },
      }
    case GET_VARIABLES_INVENTORY_FG_PACKAGE_C:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartInventoryPackageC: action.variables,
        },
      }
    case GET_VARIABLES_INVENTORY_FG_PACKAGE_D:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartInventoryPackageD: action.variables,
        },
      }
    case GET_VARIABLES_INVENTORY_FG_PACKAGE_E:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartInventoryPackageE: action.variables,
        },
      }
    case GET_VARIABLES_INVENTORY_FG_PACKAGE_F:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartInventoryPackageF: action.variables,
        },
      }
    case GET_VARIABLES_PACKAGING_PRIORITY:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesPackagingPriority: action.variables,
        },
      }
    case GET_VARIABLES_FLAVORS_PRIORITY:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesFlavorsPriority: action.variables,
        },
      }
    case GET_VARIABLES_BI_WEEKLY_PRODUCTION:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesBiweeklyProduction: action.variables,
        },
      }
    case CHANGE_VARIABLE_PRODUCTS:
      if (
        action.payload.variable === 'variablesPackaging' ||
        action.payload.variable === 'variablesFlavors'
      ) {
        return {
          ...state,
          newValues: {
            ...state.newValues,
            [`${action.payload.variable}`]: {
              ...state.currentValues[`${action.payload.variable}`],
              ...state.newValues[`${action.payload.variable}`],
              [`${action.payload.id}`]: action.payload.value,
            },
          },
        }
      }
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default products
