const changeValue = (variable, dispatch, functionDispatch) => {
  return ({ id, value }) => {
    const payload = {
      id,
      value: Number(value),
      variable,
    }
    dispatch(functionDispatch(payload))
  }
}

const changeValueSliderRange = (id, variable, dispatch, functionDispatch) => {
  return () => {
    const inputSlider = document.getElementById(id).children[2]
    const { value } = inputSlider
    const payload = {
      id,
      value: Number(value),
      variable,
    }
    dispatch(functionDispatch(payload))
  }
}

export { changeValue, changeValueSliderRange }
