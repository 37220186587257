import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

const MessagesComponent = props => {
  const { warning, messageType, info, message } = props;
  let severityType = "error";

  if (warning) {
    severityType = "warning";
  } else if (messageType) {
    severityType = "success";
  } else if (info) {
    severityType = "info";
  }

  return (
    <Alert severity={severityType}>
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  );
};

export default MessagesComponent;
