import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputDisable from 'components/InputDisable'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { OPERATIONS } from 'constants/forioVariables'
import { changeVariable } from 'actions/operationsActions'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { changeValue as onChangeValue } from 'common'

const {
  HUMAN_RESOURCES,
  AVERAGE_MARKET_SALARY,
  DESIRED_MONTHLY_SALARY,
  DESIRED_PERSONNEL,
} = CONSTANTS.OPERATIONS

const {
  SALARIES_IN_ECONOMY_SMOOTHED,
  MONTHLY_SALARY_PER_PERSON,
  PERSONNEL_DESIRED_PER_SHIFT,
} = OPERATIONS

const returnElements = (variables, changeValue) => [
  {
    id: `1-HumanResourcesList`,
    label: AVERAGE_MARKET_SALARY,
    component: <InputDisable value={Math.round(variables[SALARIES_IN_ECONOMY_SMOOTHED])} />,
  },
  createListRowForVariable(
    MONTHLY_SALARY_PER_PERSON,
    DESIRED_MONTHLY_SALARY,
    variables,
    changeValue,
  ),
  createListRowForVariable(PERSONNEL_DESIRED_PER_SHIFT, DESIRED_PERSONNEL, variables, changeValue),
]

const ListHumanResources = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('humanResources', dispatch, changeVariable)
  const variables = useSelector(state => state.operations.currentValues.humanResources)
  return <List items={returnElements(variables, changeValue)} listTitle={HUMAN_RESOURCES} />
}
export default ListHumanResources
