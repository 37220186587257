import React from 'react'
import { OPERATIONS } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import BaseChart from './BaseChart'

const { PRODUCT_NOT_YET_FROZEN } = OPERATIONS
const { PRODUCT_COLD_STORAGE } = CONSTANTS.OPERATIONS

const ChartProductColdStorage = () => {
  return <BaseChart title={PRODUCT_COLD_STORAGE} variableId={PRODUCT_NOT_YET_FROZEN} />
}

export default ChartProductColdStorage
