const CHART_COLORS = {
  COLOR_A: '#26B99A',
  COLOR_B: '#34495E',
  COLOR_C: '#BDC3C7',
  COLOR_D: '#3498DB',
  COLOR_E: '#9B59B6',
  COLOR_F: '#8abb6f',
  COLOR_G: '#759c6a',
  COLOR_H: '#bfd3b7',
}

export { CHART_COLORS }
