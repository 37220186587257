import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import headerReducer from './headerReducer'
import appBackendReducer from './appBackendReducer'
import userReducer from '../store/User'
import inventoryReducer from './inventoryReducer'
import marketingAndLogisticsReducer from './marketingAndLogisticsReducer'
import operationsReducer from './operationsReducer'
import productsReducer from './productsReducer'
import resultsReducer from './resultsReducer'
import excelReducer from './excelReducer'

const rootReducer = combineReducers({
  routing: routerReducer,
  inventory: inventoryReducer,
  marketingAndLogistics: marketingAndLogisticsReducer,
  operations: operationsReducer,
  products: productsReducer,
  results: resultsReducer,
  header: headerReducer,
  user: userReducer,
  appBackend: appBackendReducer,
  excel: excelReducer,
})

export default rootReducer
