import React from "react";
import { Button, Modal } from "@material-ui/core";
import "styles/modalsStyle.css";

const ConfirmationModal = props => {
  const {
    openConfirmModal,
    headerContent,
    confirmationBody,
    confirmButton,
    onConfirm,
    cancelButton,
    onCancel
  } = props;

  return (
    <Modal open={openConfirmModal}>
      <div className="modal">
        <h2>{headerContent}</h2>
        {confirmationBody}
        <div className="modalActions marginTop">
          <Button variant="outlined" onClick={onConfirm}>
            {confirmButton}
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            {cancelButton}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
