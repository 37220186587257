import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { INVENTORY, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const {
  INVENTARIO_DE_EMPAQUES_A_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_B_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_C_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_D_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_E_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_F_PARA_TABLA,
} = INVENTORY

const { TIME } = HEADER
const {
  PACKAGING_MATERIAL,
  EMPAQUE_A,
  EMPAQUE_B,
  EMPAQUE_C,
  EMPAQUE_D,
  EMPAQUE_E,
  EMPAQUE_F,
} = CONSTANTS.INVENTORY

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      getChartLineForVariable(
        INVENTARIO_DE_EMPAQUES_A_PARA_TABLA,
        EMPAQUE_A,
        CHART_COLORS.COLOR_A,
        variables,
      ),
      getChartLineForVariable(
        INVENTARIO_DE_EMPAQUES_B_PARA_TABLA,
        EMPAQUE_B,
        CHART_COLORS.COLOR_B,
        variables,
      ),
      getChartLineForVariable(
        INVENTARIO_DE_EMPAQUES_C_PARA_TABLA,
        EMPAQUE_C,
        CHART_COLORS.COLOR_C,
        variables,
      ),
      getChartLineForVariable(
        INVENTARIO_DE_EMPAQUES_D_PARA_TABLA,
        EMPAQUE_D,
        CHART_COLORS.COLOR_D,
        variables,
      ),
      getChartLineForVariable(
        INVENTARIO_DE_EMPAQUES_E_PARA_TABLA,
        EMPAQUE_E,
        CHART_COLORS.COLOR_E,
        variables,
      ),
      getChartLineForVariable(
        INVENTARIO_DE_EMPAQUES_F_PARA_TABLA,
        EMPAQUE_F,
        CHART_COLORS.COLOR_F,
        variables,
      ),
    ],
  }
}

const ChartPackagingMaterial = () => {
  const variables = useSelector(
    state => state.inventory.currentValues.variablesChartPackagingMaterial,
  )
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart={PACKAGING_MATERIAL} />
  ) : null
}
export default ChartPackagingMaterial
