import React from 'react'
import Box from '@material-ui/core/Box'
import { useSelector, useDispatch } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/operationsActions'
import {
  ListEquipmentAndFacilities,
  ListProduction,
  ListHumanResources,
  ListMaintenance,
} from './List'
import PerceivedQuality from './PerceivedQuality'
import Equipment from './Equipment'
import WorkForce from './WorkForce'
import { ChartAgeingTanks, ChartProductColdStorage, ChartProductForSale } from './Graphics'

const Operations = () => {
  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateOperations = useSelector(state => state.operations.currentValues)
  if (validateStates(stateOperations)) {
    return <Loading />
  }
  return (
    <div>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ListEquipmentAndFacilities />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ListProduction />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ListHumanResources />
          <ListMaintenance />
        </Box>
        <Box className="elementWrapper elementToRight">
          <WorkForce />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <Equipment />
        </Box>
        <Box className="elementWrapper elementToRight">
          <PerceivedQuality />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ChartAgeingTanks />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartProductColdStorage />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ChartProductForSale />
        </Box>
      </Box>
    </div>
  )
}

export default Operations
