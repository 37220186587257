import { isJsonString } from "../utilities/dataValidationUtilities";

export const setCookie = (cookieName, cookieData) => {
  const today = new Date();
  const thirtyDaysOnFuture = today.setDate(today.getDate() + 30);
  const formattedExpirationDate = new Date(thirtyDaysOnFuture);

  const expires = `expires=${formattedExpirationDate.toGMTString()}`;
  document.cookie = `${cookieName}=${encodeURI(
    JSON.stringify(cookieData)
  )};${expires};path=/`;
};

export const getCookie = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const entries = decodedCookie.split(";");
  for (let i = 0; i < entries.length; i += 1) {
    let entry = entries[i];
    while (entry.charAt(0) === " ") {
      entry = entry.substring(1);
    }
    if (entry.indexOf(name) === 0) {
      try {
        const jsonData = decodeURI(entry.substring(name.length, entry.length));
        const jsonVerified = isJsonString(jsonData);
        if (jsonVerified) return JSON.parse(jsonData);
        return false;
      } catch (e) {
        return false;
      }
    }
  }
};

export const deleteCookie = cookieName => {
  const deletedCookieDate = "expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = `${cookieName}=;${deletedCookieDate}`;
};
