import { CONSTANTS } from 'constants/constants'
import { RESULTS } from 'constants/forioVariables'
import { useDispatch, useSelector } from 'react-redux'
import { changeValue as onChangeValue } from 'common'
import { changeVariable } from 'actions/productsActions'
import React from 'react'
import List from 'components/List'
import { createListRowForVariable } from 'common/UIComponentUtilities'

const { DAYS_UNTIL_PAYMENT, DESIRED_DEBT_AMORTIZATION, FINANCES } = CONSTANTS.RESULTS

const { DIAS_DE_COBRO, DEBT_AMORTIZATION_PERCENTAGE_TRIMESTER_DECISION } = RESULTS

const returnData = (variables, changeValue) => [
  createListRowForVariable(DIAS_DE_COBRO, DAYS_UNTIL_PAYMENT, variables, changeValue),
  createListRowForVariable(
    DEBT_AMORTIZATION_PERCENTAGE_TRIMESTER_DECISION,
    DESIRED_DEBT_AMORTIZATION,
    variables,
    changeValue,
  ),
]

const Packaging = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesFinances', dispatch, changeVariable)
  const variables = useSelector(state => state.results.currentValues.variablesFinances)

  return <List listTitle={FINANCES} items={returnData(variables, changeValue)} />
}

export default Packaging
