import React from 'react'
import InputNumber from 'components/InputNumber'

const createColumnCellForVariable = (variableId, variables) => ({
  id: variableId,
  value: variables[variableId],
})

const createListRowForVariable = (variableId, label, variables, changeValue, helpMessage) => {
  return {
    id: `list-${variableId}`,
    label,
    component: (
      <InputNumber
        defaultValue={variables[variableId]}
        id={variableId}
        change={event => changeValue(event)}
      />
    ),
    helpMessage,
  }
}

const getChartLineForVariable = (variableId, label, color, variables) => ({
  label,
  fill: false,
  borderColor: color,
  data: variables[variableId],
})

export { createColumnCellForVariable, createListRowForVariable, getChartLineForVariable }
