import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles({
  textField: {
    backgroundColor: '#eee',
    opacity: 1,
    border: '1px solid #ccc',
    borderRadius: '0px',
    color: '#555',
    fontSize: '.875rem',
    padding: '6px 12px',
    cursor: 'not-allowed',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
  error: {
    border: '1px solid red',
  },
})

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { ...other } = props

  return <NumberFormat {...other} getInputRef={ref} thousandSeparator />
})

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
}

const InputDisable = ({ value, errorsToValidate, error, id }) => {
  let isError = false
  if (errorsToValidate && error) {
    isError = errorsToValidate && errorsToValidate.indexOf(error) > -1
  }

  const classes = useStyle()

  return (
    <TextField
      name="numberformat"
      id="formatted-numberformat-input"
      key={`${id}-textField`}
      value={value}
      margin="none"
      variant="outlined"
      borderRadius={0}
      InputProps={{
        classes: {
          input: classes.textField,
          inputComponent: NumberFormatCustom,
        },
      }}
      disabled
      className={isError ? classes.error : null}
    />
  )
}

export default InputDisable
