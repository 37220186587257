const saveOnLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const loadLocalStorageValue = key => {
  return JSON.parse(localStorage.getItem(key));
};

const remove = key => {
  localStorage.removeItem(key);
};

//  LocalStorageKeys

const localStorageKeys = {
  TEAM_INFORMATION: "TEAM_INFORMATION",
  CREATE_TEAM_SIMULATION_ID: "CREATE_TEAM_SIMULATION_ID",
  JOIN_TEAM_ID: "JOIN_TEAM_ID",
  JOIN_TEAM_NAME: "JOIN_TEAM_NAME"
};

export { saveOnLocalStorage, loadLocalStorageValue, remove, localStorageKeys };
