import React from 'react'
import { useSelector } from 'react-redux'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import BaseMarketChart from './BaseMarketChart'

const { MARKET_SHARES } = CONSTANTS.MARKETING_AND_LOGISTICS
const { EMPAQUE_A, EMPAQUE_B, EMPAQUE_C, EMPAQUE_D, EMPAQUE_E, EMPAQUE_F } = CONSTANTS.INVENTORY

const {
  MARKET_SHARE_EMPAQUE_A,
  MARKET_SHARE_EMPAQUE_B,
  MARKET_SHARE_EMPAQUE_C,
  MARKET_SHARE_EMPAQUE_D,
  MARKET_SHARE_EMPAQUE_E,
  MARKET_SHARE_EMPAQUE_F,
} = MARKETING_AND_LOGISTICS

const packageToVariableMapping = {
  [EMPAQUE_A]: MARKET_SHARE_EMPAQUE_A,
  [EMPAQUE_B]: MARKET_SHARE_EMPAQUE_B,
  [EMPAQUE_C]: MARKET_SHARE_EMPAQUE_C,
  [EMPAQUE_D]: MARKET_SHARE_EMPAQUE_D,
  [EMPAQUE_E]: MARKET_SHARE_EMPAQUE_E,
  [EMPAQUE_F]: MARKET_SHARE_EMPAQUE_F,
}

const ChartMarketShares = () => {
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.marketShares)
  return (
    <BaseMarketChart
      title={MARKET_SHARES}
      variables={variables}
      packageToVariableMapping={packageToVariableMapping}
    />
  )
}

export default ChartMarketShares
