import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const CustomLinearProgress = withStyles({
  root: {
    height: 20,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: '#BDC3C7',
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#1ABB9C',
  },
})(LinearProgress)

const ListItems = ({ items }) => {
  return (
    <Box width="100%" marginLeft="25px">
      {items.map(item => (
        <Box
          key={item.id}
          display="flex"
          justifyContent="left"
          alignItems="center"
          marginBottom="1.5rem"
        >
          <Box width="45%">
            <CustomLinearProgress variant="determinate" value={item.value} />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <p style={{ margin: '0px' }}>0%</p>
              <p style={{ margin: '0px' }}>100%</p>
            </Box>
          </Box>
          <Box style={{ paddingLeft: '10px' }}>
            <p style={{ marginTop: '0px' }}>{item.label}</p>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default ListItems
