import { CONSTANTS } from 'constants/constants'

const { EXCEL } = CONSTANTS
const STEP_SIZE = 360
const Flavors = [
  EXCEL.CHOCOLATE,
  EXCEL.STRAWBERRY,
  EXCEL.LEMON,
  EXCEL.MANGO,
  EXCEL.RUM,
  EXCEL.VANILLA,
]
const Packages = [
  EXCEL.EMPAQUE_A,
  EXCEL.EMPAQUE_B,
  EXCEL.EMPAQUE_C,
  EXCEL.EMPAQUE_D,
  EXCEL.EMPAQUE_E,
  EXCEL.EMPAQUE_F,
]

const sheetProducts = data => {
  const productsSheet = {
    data: [],
    merges: [],
  }

  const headerLayout = {
    [EXCEL.EMPAQUE_A]: [EXCEL.TOTAL, ...Flavors],
    [EXCEL.EMPAQUE_B]: [EXCEL.TOTAL, ...Flavors],
    [EXCEL.EMPAQUE_C]: [EXCEL.TOTAL, ...Flavors],
    [EXCEL.EMPAQUE_D]: [EXCEL.TOTAL, ...Flavors],
    [EXCEL.EMPAQUE_E]: [EXCEL.TOTAL, ...Flavors],
    [EXCEL.EMPAQUE_F]: [EXCEL.TOTAL, ...Flavors],
  }

  const { firstRow, secondRow, merges } = generateHeadersFromLayout(headerLayout)

  productsSheet.data.push(firstRow)
  productsSheet.data.push(secondRow)
  productsSheet.merges.push(...merges)

  const tableVariables = [
    data.TotalFGInventoryA,
    data.EmpaqueAFlavorDecision.SaborC,
    data.EmpaqueAFlavorDecision.SaborF,
    data.EmpaqueAFlavorDecision.SaborL,
    data.EmpaqueAFlavorDecision.SaborM,
    data.EmpaqueAFlavorDecision.SaborR,
    data.EmpaqueAFlavorDecision.SaborV,
    data.TotalFGInventoryB,
    data.EmpaqueBFlavorDecisions.SaborC,
    data.EmpaqueBFlavorDecisions.SaborF,
    data.EmpaqueBFlavorDecisions.SaborL,
    data.EmpaqueBFlavorDecisions.SaborM,
    data.EmpaqueBFlavorDecisions.SaborR,
    data.EmpaqueBFlavorDecisions.SaborV,
    data.TotalFGInventoryC,
    data.EmpaqueCFlavorDecisions.SaborC,
    data.EmpaqueCFlavorDecisions.SaborF,
    data.EmpaqueCFlavorDecisions.SaborL,
    data.EmpaqueCFlavorDecisions.SaborM,
    data.EmpaqueCFlavorDecisions.SaborR,
    data.EmpaqueCFlavorDecisions.SaborV,
    data.TotalFGInventoryD,
    data.EmpaqueDFlavorDecisions.SaborC,
    data.EmpaqueDFlavorDecisions.SaborF,
    data.EmpaqueDFlavorDecisions.SaborL,
    data.EmpaqueDFlavorDecisions.SaborM,
    data.EmpaqueDFlavorDecisions.SaborR,
    data.EmpaqueDFlavorDecisions.SaborV,
    data.TotalFGInventoryE,
    data.EmpaqueEFlavorDecisions.SaborC,
    data.EmpaqueEFlavorDecisions.SaborF,
    data.EmpaqueEFlavorDecisions.SaborL,
    data.EmpaqueEFlavorDecisions.SaborM,
    data.EmpaqueEFlavorDecisions.SaborR,
    data.EmpaqueEFlavorDecisions.SaborV,
    data.TotalFGInventoryF,
    data.EmpaqueFFlavorDecisions.SaborC,
    data.EmpaqueFFlavorDecisions.SaborF,
    data.EmpaqueFFlavorDecisions.SaborL,
    data.EmpaqueFFlavorDecisions.SaborM,
    data.EmpaqueFFlavorDecisions.SaborR,
    data.EmpaqueFFlavorDecisions.SaborV,
  ]
  const tableData = populateTableData(data.Time, tableVariables)
  productsSheet.data.push(...tableData)
  return productsSheet
}

const sheetOperations = data => {
  const operationsSheet = {
    data: [],
    merges: [],
  }

  const firstRow = [
    EXCEL.TIME,
    EXCEL.WIP_IN_AGEING_TANKS,
    EXCEL.PRODUCT_IN_COLD_STORAGE,
    EXCEL.PRODUCT_READY_FOR_SALE,
  ]

  operationsSheet.data.push(firstRow)

  const tableVariables = [
    data.WIPInAgeingTanks,
    data.InventoryOfProductNotYetFrozen,
    data.InventoryOfProductReadyForSale,
  ]
  const tableData = populateTableData(data.Time, tableVariables)
  operationsSheet.data.push(...tableData)
  return operationsSheet
}

const sheetMarketing = data => {
  const marketingSheet = {
    data: [],
    merges: [],
  }

  const headerLayout = {
    [EXCEL.AVERAGE_MARKET_PRICE]: Packages,
    [EXCEL.MARKET_SHARES_BY_PACKAGING]: Packages,
    [EXCEL.FLEET]: [EXCEL.COMPETITION, EXCEL.OWNED],
    [EXCEL.AVERAGE_FILL_RATES]: [EXCEL.LARGE_PACKAGES, EXCEL.SMALL_PACKAGES],
    [EXCEL.SALES_5_GL]: Flavors,
    [EXCEL.SALES_2_5_GL]: Flavors,
    [EXCEL.SALES_1_GL]: Flavors,
    [EXCEL.SALES_0_5_GL]: Flavors,
    [EXCEL.SALES_0_1_GL]: Flavors,
    [EXCEL.SALES_0_0_GL]: Flavors,
  }

  const { firstRow, secondRow, merges } = generateHeadersFromLayout(headerLayout)

  marketingSheet.data.push(firstRow)
  marketingSheet.data.push(secondRow)
  marketingSheet.merges.push(...merges)

  const tableVariables = [
    data.PrecioACompetencia,
    data.PrecioBCompetencia,
    data.PrecioCCompetencia,
    data.PrecioDCompetencia,
    data.PrecioECompetencia,
    data.PrecioFCompetencia,
    data.AverageMarketShareEmpaqueA,
    data.AverageMarketShareEmpaqueB,
    data.AverageMarketShareEmpaqueC,
    data.AverageMarketShareEmpaqueD,
    data.AverageMarketShareEmpaqueE,
    data.AverageMarketShareEmpaqueF,
    data.CamionesCompetenciaFuerte,
    data.TotalCamiones,
    data.AverageFillRateEmpaquesGrandes,
    data.AverageFillRAteEmpaquesPequeños,
    data.VentaDeASaborCparaTablaAcc,
    data.VentaDeASaborFparaTablaAcc,
    data.VentaDeASaborLparaTablaAcc,
    data.VentaDeASaborMparaTablaAcc,
    data.VentaDeASaborRparaTablaAcc,
    data.VentaDeASaborVparaTablaAcc,
    data.VentaDeBSaborCparaTablaAcc,
    data.VentaDeBSaborFparaTablaAcc,
    data.VentaDeBSaborLparaTablaAcc,
    data.VentaDeBSaborMparaTablaAcc,
    data.VentaDeBSaborRparaTablaAcc,
    data.VentaDeBSaborVparaTablaAcc,
    data.VentaDeCSaborCparaTablaAcc,
    data.VentaDeCSaborFparaTablaAcc,
    data.VentaDeCSaborLparaTablaAcc,
    data.VentaDeCSaborMparaTablaAcc,
    data.VentaDeCSaborRparaTablaAcc,
    data.VentaDeCSaborVparaTablaAcc,
    data.VentaDeDSaborCparaTablaAcc,
    data.VentaDeDSaborFparaTablaAcc,
    data.VentaDeDSaborLparaTablaAcc,
    data.VentaDeDSaborMparaTablaAcc,
    data.VentaDeDSaborRparaTablaAcc,
    data.VentaDeDSaborVparaTablaAcc,
    data.VentaDeESaborCparaTablaAcc,
    data.VentaDeESaborFparaTablaAcc,
    data.VentaDeESaborLparaTablaAcc,
    data.VentaDeESaborMparaTablaAcc,
    data.VentaDeESaborRparaTablaAcc,
    data.VentaDeESaborVparaTablaAcc,
    data.VentaDeFSaborCparaTablaAcc,
    data.VentaDeFSaborFparaTablaAcc,
    data.VentaDeFSaborLparaTablaAcc,
    data.VentaDeFSaborMparaTablaAcc,
    data.VentaDeFSaborRparaTablaAcc,
    data.VentaDeFSaborVparaTablaAcc,
  ]
  const tableData = populateTableData(data.Time, tableVariables)
  marketingSheet.data.push(...tableData)
  return marketingSheet
}

const sheetInventory = data => {
  const inventorySheet = {
    data: [],
    merges: [],
  }

  const headerLayout = {
    [EXCEL.PACKAGING_MATERIAL_INVENTORY]: Packages,
    [EXCEL.RAW_MATERIALS_INVENTORY]: [EXCEL.MILK, EXCEL.OTHERS],
  }

  const { firstRow, secondRow, merges } = generateHeadersFromLayout(headerLayout)

  inventorySheet.data.push(firstRow)
  inventorySheet.data.push(secondRow)
  inventorySheet.merges.push(...merges)

  const tableVariables = [
    data.InventarioDeEmpaquesAparaTabla,
    data.InventarioDeEmpaquesBparaTabla,
    data.InventarioDeEmpaquesCparaTabla,
    data.InventarioDeEmpaquesDparaTabla,
    data.InventarioDeEmpaquesEparaTabla,
    data.InventarioDeEmpaquesFparaTabla,
    data.InventarioDeLeche,
    data.InventarioDeOtros,
  ]
  const tableData = populateTableData(data.Time, tableVariables)
  inventorySheet.data.push(...tableData)
  return inventorySheet
}

const sheetFinances = data => {
  const financesSheet = {
    data: [],
    merges: [],
  }

  const headerLayout = {
    [EXCEL.INCOME_STATEMENT]: [
      EXCEL.SALES,
      EXCEL.RAW_MATERIALS_COSTS,
      EXCEL.LABOR_FORCE_COSTS,
      EXCEL.PRODUCTION_EQUIPMENT_DEPRECIATION,
      EXCEL.UTILITIES_COSTS,
      EXCEL.MAINTENANCE_COSTS,
      EXCEL.SALES_TOTAL_COSTS,
      EXCEL.RAW_PROFITS,
      EXCEL.DELIVERY_TRUCKS_COSTS,
      EXCEL.TRUCKS_DEPRECIATION,
      EXCEL.SALES_AND_ADMINISTRATION_COSTS,
      EXCEL.MARKETING_COSTS,
      EXCEL.HIRING_COSTS,
      EXCEL.FIRING_COSTS,
      EXCEL.INVENTORY_HOLDING_COSTS,
      EXCEL.BUILDINGS_DEPRECIATION,
      EXCEL.INTEREST_RELATED_COSTS,
      EXCEL.OVERHEAD_COSTS,
      EXCEL.PROFIT_BEFORE_TAXES,
      EXCEL.INCOME_TAX,
      EXCEL.NET_PROFIT,
    ],
    [EXCEL.BALANCE_SHEET]: [
      EXCEL.CASH,
      EXCEL.ACCOUNTS_RECEIVABLE,
      EXCEL.INVENTORY,
      EXCEL.CURRENT_ASSETS,
      EXCEL.NET_FIXED_ASSETS,
      EXCEL.TOTAL_ASSETS,
      EXCEL.ACCOUNTS_PAYABLE,
      EXCEL.LOANS,
      EXCEL.TOTAL_LIABILITIES,
      EXCEL.STOCKS,
      EXCEL.RETAINED_EARNINGS,
      EXCEL.TOTAL_CAPITAL,
      EXCEL.TOTAL_LIABILITIES_PLUS_CAPITAL,
    ],
  }

  const { firstRow, secondRow, merges } = generateHeadersFromLayout(headerLayout)

  financesSheet.data.push(firstRow)
  financesSheet.data.push(secondRow)
  financesSheet.merges.push(...merges)

  const tableVariables = [
    data.AccumulatedTotalSalesParaSable,
    data.CostoAcumuladoDeMaterialesVendidosSinLimpiaParaSable,
    data.CostosAcumuladosPorManoDeObraSinLimpiaParaSable,
    data.DepreciacionAcumuladaParaCostosVariablesDeProducciónParaSable,
    data.UtilitiesCostAcumuladoParaSable,
    data.AccumulatedMaintenanceExpensesParaSable,
    data.TotalCostoDeVentasParaSable,
    data.UtilidadBrutaAcumuladaParaSable,
    data.CostoAcumuladoDeCamionesParaSable,
    data.DepreciaciónAcumuladaDeCarrosParaSable,
    data.AccumulatedEgresosPorSalesAndAdministrationParaSable,
    data.AccumulatedMarketingExpensesParaSable,
    data.AccumulatedHiringCostParaSable,
    data.AccumulatedFiringCostParaSable,
    data.AccumulatedInventoryHoldingCost,
    data.DepreciacionAcumuladaPlantaEdificiosYOtrosParaSable,
    data.AccumulatedInterestExpensesParaSable,
    data.TotalAccumulatedOverheadExpensesParaSable,
    data.UtilidadAntesDeIMpuestosParaSable,
    data.AccumulatedImpuestoSobreLaRentaParaSable,
    data.AccumulatedNetIncomeAfterTaxesParaSable,
    data.CajaParaSable,
    data.CuentasPorCobrarParaSable,
    data.InventarioTotalDolaresParaSable,
    data.TotalActivoCirculanteParaSable,
    data.TotalActivoFijoParaSable,
    data.TotalActivoParaSable,
    data.CuentasPorPagarProveedoresParaSable,
    data.PrestamoParaSable,
    data.TotalPasivoParaSable,
    data.AccionesParaSable,
    data.UtilidadesRetenidasParaSable,
    data.TotalCapitalParaSable,
    data.TotalPasivoMasCapitalParaSable,
  ]
  const tableData = populateTableData(data.Time, tableVariables)
  financesSheet.data.push(...tableData)
  return financesSheet
}

const populateTableData = (time, data) => {
  const timeLength = time.length
  const dataRows = []
  for (let timeCheckpoint = 0; timeCheckpoint <= timeLength; timeCheckpoint += STEP_SIZE) {
    const currentRow = []
    currentRow.push(time[timeCheckpoint])
    data.forEach(variable => {
      currentRow.push(variable[timeCheckpoint])
    })
    dataRows.push(currentRow)
  }

  return dataRows
}

const generateHeadersFromLayout = layout => {
  const firstRow = ['']
  const secondRow = [EXCEL.TIME]
  const merges = []

  Object.keys(layout).forEach(rowName => {
    const lengthBeforePush = firstRow.length
    firstRow.push(rowName, ...Array(layout[rowName].length - 1).fill(''))
    merges.push({
      s: { r: 0, c: lengthBeforePush },
      e: { r: 0, c: firstRow.length - 1 },
    })
    secondRow.push(...layout[rowName])
  })

  return { firstRow, secondRow, merges }
}

export const getExcelData = data => {
  const excelData = {
    [EXCEL.PRODUCTS]: sheetProducts(data),
    [EXCEL.OPERATIONS]: sheetOperations(data),
    [EXCEL.MARKETING_AND_LOGISTICS]: sheetMarketing(data),
    [EXCEL.INVENTORY]: sheetInventory(data),
    [EXCEL.FINANCES_AND_RESULTS]: sheetFinances(data),
  }
  return excelData
}
