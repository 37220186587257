import { GET_VARIABLES_EXCEL, EXCEL_FILE_DOWNLOADED } from 'constants/actionTypes'
import { getVariablesByRunId } from 'services/forio/forio'
import { EXCEL } from 'constants/forioVariables'

const variablesExcel = Object.keys(EXCEL).map(x => {
  return EXCEL[x]
})

const getVariablesExcel = variables => ({
  type: GET_VARIABLES_EXCEL,
  variables,
})

const fetchVariablesExcel = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesExcel)
    dispatch(getVariablesExcel(variables))
  }
}

const excelFileDownloaded = () => ({
  type: EXCEL_FILE_DOWNLOADED,
})

export { fetchVariablesExcel, excelFileDownloaded }
