import React from 'react'
import { useSelector } from 'react-redux'
import ListInputDisable from 'components/ListInputDisable'
import { CONSTANTS } from 'constants/constants'
import { OPERATIONS } from 'constants/forioVariables'
import { Card, CardContent, Box } from '@material-ui/core'
import { GaugeChart } from 'components/Charts'
import useStyles from './style'

const {
  EQUIPMENT,
  MACHINE_CAPACITY,
  PASTEURIZATION_CAPACITY,
  AVAILABLE_PASTEURIZERS,
  AVAILABLE_FREEZING,
  MIXERS,
  CAPACITY_PER_MATURATION,
  AVAILABLE_MATURATION_TANKS,
} = CONSTANTS.OPERATIONS

const {
  CAPACIDAD_DE_LAS_MAQUINAS,
  PASTEURIZATION_CAPACITY_AVAILABLE,
  PASTEURIZERS,
  CAPACIDAD_DE_BATIDO,
  BATIDORAS,
  MATURATION_TANK_CAPACITY,
  MATURATION_TANKS,
} = OPERATIONS

const createListRowForVariable = (label, variables, variableId, toFixed = false) => {
  const value = toFixed ? variables[variableId].toFixed(2) : variables[variableId]
  return {
    id: `list-${variableId}`,
    label,
    value,
  }
}

const returnElements = variables => [
  createListRowForVariable(
    PASTEURIZATION_CAPACITY,
    variables,
    PASTEURIZATION_CAPACITY_AVAILABLE,
    true,
  ),
  createListRowForVariable(AVAILABLE_PASTEURIZERS, variables, PASTEURIZERS),
  createListRowForVariable(AVAILABLE_FREEZING, variables, CAPACIDAD_DE_BATIDO, true),
  createListRowForVariable(MIXERS, variables, BATIDORAS),
  createListRowForVariable(CAPACITY_PER_MATURATION, variables, MATURATION_TANK_CAPACITY),
  createListRowForVariable(AVAILABLE_MATURATION_TANKS, variables, MATURATION_TANKS),
]

const Equipment = () => {
  const classes = useStyles()
  const variables = useSelector(state => state.operations.currentValues.equipment)

  return (
    <Card className={classes.card}>
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{EQUIPMENT}</h2>
      </div>
      <CardContent>
        <Box key="equipment" display="flex" alignItems="top">
          <GaugeChart
            titleChart={MACHINE_CAPACITY}
            value={variables[CAPACIDAD_DE_LAS_MAQUINAS].toFixed(2)}
          />
          <ListInputDisable items={returnElements(variables)} />
        </Box>
      </CardContent>
    </Card>
  )
}
export default Equipment
