import {
  CLEAR_NEW_VALUES,
  CHANGE_VARIABLE_OPERATIONS,
  CLEAR_VALUES,
  GET_VARIABLES_EQUIPMENT_AND_FACILITIES,
  GET_VARIABLES_PRODUCTION,
  GET_VARIABLES_HUMAN_RESOURCES,
  GET_VARIABLES_MAINTENANCE,
  GET_VARIABLES_EQUIPMENT,
  GET_VARIABLES_CHART,
  GET_VARIABLES_QUALITY,
} from 'constants/actionTypes'

const initialOperations = {
  currentValues: {
    equipmentAndFacilities: {},
    production: {},
    humanResources: {},
    maintenance: {},
    equipment: {},
    chartsVariables: {},
    quality: {},
  },
  newValues: {},
}

const Operations = (state = initialOperations, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialOperations
    case GET_VARIABLES_EQUIPMENT_AND_FACILITIES:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          equipmentAndFacilities: action.variables,
        },
      }
    case GET_VARIABLES_PRODUCTION:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          production: action.variables,
        },
      }
    case GET_VARIABLES_HUMAN_RESOURCES:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          humanResources: action.variables,
        },
      }
    case GET_VARIABLES_MAINTENANCE:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          maintenance: action.variables,
        },
      }
    case GET_VARIABLES_EQUIPMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          equipment: action.variables,
        },
      }
    case GET_VARIABLES_CHART:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          chartsVariables: action.variables,
        },
      }
    case GET_VARIABLES_QUALITY:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          quality: action.variables,
        },
      }
    case CHANGE_VARIABLE_OPERATIONS:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default Operations
