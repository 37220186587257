import { CONSTANTS } from 'constants/constants'
import { PRODUCTS } from 'constants/forioVariables'
import { useDispatch, useSelector } from 'react-redux'
import { changeValue as onChangeValue } from 'common'
import { changeVariable } from 'actions/productsActions'
import React from 'react'
import List from 'components/List'
import RangeSlider from 'components/RangeSlider'
import { HELPS } from 'constants/helps'

const { EMPAQUE_A, EMPAQUE_B, EMPAQUE_C, EMPAQUE_D, EMPAQUE_E, EMPAQUE_F } = CONSTANTS.INVENTORY

const { PACKAGING_PRIORITY } = CONSTANTS.PRODUCTS

const {
  PACKING_PRIORITIES_EMP_A,
  PACKING_PRIORITIES_EMP_B,
  PACKING_PRIORITIES_EMP_C,
  PACKING_PRIORITIES_EMP_D,
  PACKING_PRIORITIES_EMP_E,
  PACKING_PRIORITIES_EMP_F,
} = PRODUCTS

const createListRowForVariable = (variableId, label, variables, changeValue) => {
  return {
    id: `list-${variableId}`,
    label,
    component: (
      <RangeSlider
        id={variableId}
        initialValue={variables[variableId]}
        change={changeValue}
        min={1}
        max={6}
        step={1}
      />
    ),
  }
}

const returnData = (variables, changeValue) => {
  return [
    createListRowForVariable(PACKING_PRIORITIES_EMP_A, EMPAQUE_A, variables, changeValue),
    createListRowForVariable(PACKING_PRIORITIES_EMP_B, EMPAQUE_B, variables, changeValue),
    createListRowForVariable(PACKING_PRIORITIES_EMP_C, EMPAQUE_C, variables, changeValue),
    createListRowForVariable(PACKING_PRIORITIES_EMP_D, EMPAQUE_D, variables, changeValue),
    createListRowForVariable(PACKING_PRIORITIES_EMP_E, EMPAQUE_E, variables, changeValue),
    createListRowForVariable(PACKING_PRIORITIES_EMP_F, EMPAQUE_F, variables, changeValue),
  ]
}

const Packaging = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesPackagingPriority', dispatch, changeVariable)
  const variables = useSelector(state => state.products.currentValues.variablesPackagingPriority)
  return (
    <List
      listTitle={PACKAGING_PRIORITY}
      items={returnData(variables, changeValue)}
      helpMessage={HELPS[PACKAGING_PRIORITY]}
    />
  )
}

export default Packaging
