import React from "react";
import { Button, Modal } from "@material-ui/core";
import MessagesComponent from "../Message/MessagesComponent";
import "styles/modalsStyle.css";

const ErrorModal = props => {
  const {
    openErrorModal,
    title,
    message,
    handleErrorModal,
    retryText,
    hasRetry,
    handleRetry,
    warning
  } = props;
  let customTitle = "Error";
  let actionButton = null;

  if (title) {
    customTitle = title;
  }

  if (hasRetry) {
    actionButton = (
      <Button variant="outlined" onClick={handleRetry}>
        {retryText}
      </Button>
    );
  } else {
    actionButton = (
      <Button variant="outlined" onClick={handleErrorModal}>
        Ok
      </Button>
    );
  }

  return (
    <Modal open={openErrorModal}>
      <div className="modal">
        <h2>{customTitle}</h2>
        <MessagesComponent message={message} warning={warning} />
        <div className="modalActions marginTop">{actionButton}</div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
