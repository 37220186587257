import React from 'react'
import Box from '@material-ui/core/Box'
import InputDisable from 'components/InputDisable'

const ListInputDisable = ({ items }) => {
  return (
    <Box width="100%" marginLeft="25px">
      {items.map(item => (
        <Box key={item.id} display="flex" justifyContent="right" alignItems="center">
          <Box>
            <p>{item.label}</p>
          </Box>
          <Box style={{ paddingLeft: '10px' }}>
            <InputDisable value={item.value} />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default ListInputDisable
