import React from 'react'
import MUIDataTable from 'mui-datatables'
import InputDisable from 'components/InputDisable'
import { Card, CardContent } from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles'
import InputNumber from 'components/InputNumber'
import HelpButton from 'components/HelpButton'

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: '#FFFFFF !important',
          border: '1px solid #E6E9ED',
          color: '#73879C',
          fontSize: '.875rem',
          fontWeight: '400',
          padding: '8px',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
        },
      },
      MUIDataTableBodyCell: {
        root: {
          border: '1px solid #E6E9ED',
          color: '#73879C',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
          fontSize: '.875rem',
          padding: '8px',
        },
      },
    },
  })

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9ED',
    boxShadow: 'none',
    color: '#73879C',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '2px solid #E6E9ED',
    padding: '0.75rem 0.75rem',
    margin: '0 0.75rem',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#73879C',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
})

const options = {
  filter: false,
  viewColumns: false,
  download: false,
  print: false,
  search: false,
  sort: false,
  pagination: false,
  selectableRows: 'none',
  responsive: 'scroll',
  rowHover: false,
}

const returnColumns = columns => {
  return columns.map(column => {
    const { type, name } = column
    switch (type) {
      case 'inputNumber':
        return {
          name,
          options: {
            customBodyRender: value => {
              if (value) {
                if (value.disabled) {
                  return (
                    <InputDisable
                      value={value.value}
                      errorsToValidate={column.whichColumns}
                      error={column.column}
                    />
                  )
                } else {
                  return (
                    <InputNumber
                      defaultValue={value.value}
                      id={value.id}
                      label={name}
                      change={column.onChange}
                      max={column.max}
                      min={column.min}
                    />
                  )
                }
              } else {
                return null
              }
            },
          },
        }
      case 'label':
        return name
      default:
        return name
    }
  })
}

const Table = ({ titleTable, columns, data, helpMessage }) => {
  const classes = useStyles()
  let title = null
  let helpButton = null

  if (helpMessage) {
    helpButton = <HelpButton helpTitle={titleTable} helpMessage={helpMessage} />
  }

  if (titleTable) {
    title = (
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{titleTable}</h2>
        {helpButton}
      </div>
    )
  }

  return (
    <Card className={classes.card}>
      {title}
      <CardContent>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={returnColumns(columns)} options={options} />
        </MuiThemeProvider>
      </CardContent>
    </Card>
  )
}

Table.defaultProps = {
  titleTable: '',
  columns: [],
  data: [],
}

export default Table
