import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import { changeVariable } from 'actions/marketingAndLogisticsActions'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { changeValue as onChangeValue } from 'common'

const { MARKETING, SALES_TO_INVEST } = CONSTANTS.MARKETING_AND_LOGISTICS

const { DECISION_A_INVERTIR_EN_MERCADEO } = MARKETING_AND_LOGISTICS

const returnElements = (variables, changeValue) => [
  createListRowForVariable(
    DECISION_A_INVERTIR_EN_MERCADEO,
    SALES_TO_INVEST,
    variables,
    changeValue,
  ),
]

const ListPricing = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('marketing', dispatch, changeVariable)
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.marketing)
  return <List items={returnElements(variables, changeValue)} listTitle={MARKETING} />
}

export default ListPricing
