import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { changeVariable } from 'actions/productsActions'
import { changeValue as onChangeValue } from 'common'
import { CONSTANTS } from 'constants/constants'
import { PRODUCTS } from 'constants/forioVariables'
import { createColumnCellForVariable } from 'common/UIComponentUtilities'
import {
  validateFlavorsVariables,
  sumFlavorsVariablesPackageA,
  sumFlavorsVariablesPackageB,
  sumFlavorsVariablesPackageC,
  sumFlavorsVariablesPackageD,
  sumFlavorsVariablesPackageE,
  sumFlavorsVariablesPackageF,
} from 'common/utils'
import { getVariablesOfTheState } from 'common/states'
import Title from 'components/TitleTablesValidation'

const { EMPAQUE_A, EMPAQUE_B, EMPAQUE_C, EMPAQUE_D, EMPAQUE_E, EMPAQUE_F } = CONSTANTS.INVENTORY
const { CHOCOLATE, STRAWBERRY, LEMON, MANGO, RUM, VANILLA, FLAVOR, FLAVORS } = CONSTANTS.PRODUCTS

const {
  EMPAQUE_A_FLAVOR_DECISION_SABOR_C,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_F,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_L,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_M,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_R,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_V,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V,
} = PRODUCTS

const columns = (changeValue, whichColumns) => [
  { type: 'label', name: FLAVOR },
  { type: 'inputNumber', name: EMPAQUE_A, onChange: changeValue, whichColumns, column: 'packageA' },
  { type: 'inputNumber', name: EMPAQUE_B, onChange: changeValue, whichColumns, column: 'packageB' },
  { type: 'inputNumber', name: EMPAQUE_C, onChange: changeValue, whichColumns, column: 'packageC' },
  { type: 'inputNumber', name: EMPAQUE_D, onChange: changeValue, whichColumns, column: 'packageD' },
  { type: 'inputNumber', name: EMPAQUE_E, onChange: changeValue, whichColumns, column: 'packageE' },
  { type: 'inputNumber', name: EMPAQUE_F, onChange: changeValue, whichColumns, column: 'packageF' },
]

const returnData = (variables, variablesToSum) => [
  [
    CHOCOLATE,
    createColumnCellForVariable(EMPAQUE_A_FLAVOR_DECISION_SABOR_C, variables),
    createColumnCellForVariable(EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C, variables),
    createColumnCellForVariable(EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C, variables),
    createColumnCellForVariable(EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C, variables),
    createColumnCellForVariable(EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C, variables),
    createColumnCellForVariable(EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C, variables),
  ],
  [
    STRAWBERRY,
    createColumnCellForVariable(EMPAQUE_A_FLAVOR_DECISION_SABOR_F, variables),
    createColumnCellForVariable(EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F, variables),
    createColumnCellForVariable(EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F, variables),
    createColumnCellForVariable(EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F, variables),
    createColumnCellForVariable(EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F, variables),
    createColumnCellForVariable(EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F, variables),
  ],
  [
    LEMON,
    createColumnCellForVariable(EMPAQUE_A_FLAVOR_DECISION_SABOR_L, variables),
    createColumnCellForVariable(EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L, variables),
    createColumnCellForVariable(EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L, variables),
    createColumnCellForVariable(EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L, variables),
    createColumnCellForVariable(EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L, variables),
    createColumnCellForVariable(EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L, variables),
  ],
  [
    MANGO,
    createColumnCellForVariable(EMPAQUE_A_FLAVOR_DECISION_SABOR_M, variables),
    createColumnCellForVariable(EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M, variables),
    createColumnCellForVariable(EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M, variables),
    createColumnCellForVariable(EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M, variables),
    createColumnCellForVariable(EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M, variables),
    createColumnCellForVariable(EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M, variables),
  ],
  [
    RUM,
    createColumnCellForVariable(EMPAQUE_A_FLAVOR_DECISION_SABOR_R, variables),
    createColumnCellForVariable(EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R, variables),
    createColumnCellForVariable(EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R, variables),
    createColumnCellForVariable(EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R, variables),
    createColumnCellForVariable(EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R, variables),
    createColumnCellForVariable(EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R, variables),
  ],
  [
    VANILLA,
    createColumnCellForVariable(EMPAQUE_A_FLAVOR_DECISION_SABOR_V, variables),
    createColumnCellForVariable(EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V, variables),
    createColumnCellForVariable(EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V, variables),
    createColumnCellForVariable(EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V, variables),
    createColumnCellForVariable(EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V, variables),
    createColumnCellForVariable(EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V, variables),
  ],
  [
    'Total',
    { disabled: true, value: sumFlavorsVariablesPackageA(variablesToSum).toFixed(2) },
    { disabled: true, value: sumFlavorsVariablesPackageB(variablesToSum).toFixed(2) },
    { disabled: true, value: sumFlavorsVariablesPackageC(variablesToSum).toFixed(2) },
    { disabled: true, value: sumFlavorsVariablesPackageD(variablesToSum).toFixed(2) },
    { disabled: true, value: sumFlavorsVariablesPackageE(variablesToSum).toFixed(2) },
    { disabled: true, value: sumFlavorsVariablesPackageF(variablesToSum).toFixed(2) },
  ],
]

const InventoryPerPackage = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesFlavors', dispatch, changeVariable)
  const variables = useSelector(state => state.products.currentValues.variablesFlavors)
  const productsNewVariables = useSelector(state => state.products.newValues)
  const validationErrors = validateFlavorsVariables({
    ...getVariablesOfTheState(productsNewVariables),
  })
  const variablesToSum = productsNewVariables.variablesFlavors || variables

  return (
    <Table
      titleTable={
        <Title
          title={FLAVORS}
          validationText="(Adding all values in a column should be equal to 1)"
          isError={validationErrors && validationErrors.error}
        />
      }
      columns={columns(changeValue, validationErrors.whichColumns)}
      data={returnData(variables, variablesToSum)}
    />
  )
}

export default InventoryPerPackage
