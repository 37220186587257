import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/inventoryActions'
import Box from '@material-ui/core/Box'
import { FinancesList, ResultsList } from './List'
import { NetProfit } from './Labels'

const Results = () => {
  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateResults = useSelector(state => state.results.currentValues)
  if (validateStates(stateResults)) {
    return <Loading />
  }
  return (
    <Box>
      <Box display="flex" justifyContent="center"></Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <NetProfit />
        </Box>
        <Box className="elementWrapper elementToRight"></Box>
        <Box className="elementWrapper elementToLeft">
          <FinancesList />
        </Box>
        <Box className="elementWrapper elementToRight"></Box>
        <Box className="elementWrapper elementToLeft">
          <ResultsList />
        </Box>
      </Box>
    </Box>
  )
}

export default Results
