import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import { changeVariable } from 'actions/marketingAndLogisticsActions'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { changeValue as onChangeValue } from 'common'
import { HELPS } from 'constants/helps'

const { LOGISTICS, DELIVERY_TRUCKS } = CONSTANTS.MARKETING_AND_LOGISTICS

const { TRUCKS_DESIRED } = MARKETING_AND_LOGISTICS

const returnElements = (variables, changeValue) => [
  createListRowForVariable(
    TRUCKS_DESIRED,
    DELIVERY_TRUCKS,
    variables,
    changeValue,
    HELPS[TRUCKS_DESIRED],
  ),
]

const ListPricing = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('logistics', dispatch, changeVariable)
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.logistics)
  return <List items={returnElements(variables, changeValue)} listTitle={LOGISTICS} />
}

export default ListPricing
