import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Card, CardContent, Box } from '@material-ui/core'
import { CONSTANTS } from 'constants/constants'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import useStyles from './style'

const { PERIOD_SALES } = CONSTANTS.MARKETING_AND_LOGISTICS

const { VENTA_TOTAL_ACUMULADA } = MARKETING_AND_LOGISTICS

const PeriodSales = () => {
  const classes = useStyles()
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.periodSales)
  const value = variables[VENTA_TOTAL_ACUMULADA].toFixed(2)

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box key={VENTA_TOTAL_ACUMULADA} alignItems="left">
          <Box style={{ marginBottom: '10px' }}>
            <Typography className={classes.value}>{value}</Typography>
          </Box>
          <Box>
            <p style={{ marginBottom: 0 }}>{PERIOD_SALES}</p>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
export default PeriodSales
