import { GET_VARIABLES_EXCEL, EXCEL_FILE_DOWNLOADED } from 'constants/actionTypes'

const initialExcel = {
  downloadReady: false,
  currentValues: {
    variablesExcel: {},
  },
}

const excelReducer = (state = initialExcel, action) => {
  switch (action.type) {
    case GET_VARIABLES_EXCEL:
      return {
        ...state,
        downloadReady: true,
        currentValues: {
          ...state.currentValues,
          variablesExcel: action.variables,
        },
      }
    case EXCEL_FILE_DOWNLOADED:
      return {
        ...state,
        downloadReady: false,
      }
    default:
      return state
  }
}

export default excelReducer
