import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const { TIME } = HEADER
const { EMPAQUE_A, EMPAQUE_B, EMPAQUE_C, EMPAQUE_D, EMPAQUE_E, EMPAQUE_F } = CONSTANTS.INVENTORY

const returnData = (variables, packageToVariableMapping, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      getChartLineForVariable(
        packageToVariableMapping[EMPAQUE_A],
        EMPAQUE_A,
        CHART_COLORS.COLOR_A,
        variables,
      ),
      getChartLineForVariable(
        packageToVariableMapping[EMPAQUE_B],
        EMPAQUE_B,
        CHART_COLORS.COLOR_B,
        variables,
      ),
      getChartLineForVariable(
        packageToVariableMapping[EMPAQUE_C],
        EMPAQUE_C,
        CHART_COLORS.COLOR_C,
        variables,
      ),
      getChartLineForVariable(
        packageToVariableMapping[EMPAQUE_D],
        EMPAQUE_D,
        CHART_COLORS.COLOR_D,
        variables,
      ),
      getChartLineForVariable(
        packageToVariableMapping[EMPAQUE_E],
        EMPAQUE_E,
        CHART_COLORS.COLOR_E,
        variables,
      ),
      getChartLineForVariable(
        packageToVariableMapping[EMPAQUE_F],
        EMPAQUE_F,
        CHART_COLORS.COLOR_F,
        variables,
      ),
    ],
  }
}

const BaseMarketChart = props => {
  const { variables, title, packageToVariableMapping } = props
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, packageToVariableMapping, time)} titleChart={title} />
  ) : null
}

export default BaseMarketChart
