import { CONSTANTS } from 'constants/constants'
import { PRODUCTS } from 'constants/forioVariables'
import { useDispatch, useSelector } from 'react-redux'
import { changeValue as onChangeValue } from 'common'
import { changeVariable } from 'actions/productsActions'
import React from 'react'
import List from 'components/List'
import RangeSlider from 'components/RangeSlider'
import { HELPS } from 'constants/helps'

const { FLAVORS_PRIORITY, CHOCOLATE, STRAWBERRY, LEMON, MANGO, RUM, VANILLA } = CONSTANTS.PRODUCTS

const {
  FLAVOR_PRIORITIES_SABOR_C,
  FLAVOR_PRIORITIES_SABOR_F,
  FLAVOR_PRIORITIES_SABOR_L,
  FLAVOR_PRIORITIES_SABOR_M,
  FLAVOR_PRIORITIES_SABOR_R,
  FLAVOR_PRIORITIES_SABOR_V,
} = PRODUCTS

const createListRowForVariable = (variableId, label, variables, changeValue) => {
  return {
    id: `list-${variableId}`,
    label,
    component: (
      <RangeSlider
        id={variableId}
        initialValue={variables[variableId]}
        change={changeValue}
        min={1}
        max={6}
        step={1}
      />
    ),
  }
}

const returnData = (variables, changeValue) => {
  return [
    createListRowForVariable(FLAVOR_PRIORITIES_SABOR_C, CHOCOLATE, variables, changeValue),
    createListRowForVariable(FLAVOR_PRIORITIES_SABOR_F, STRAWBERRY, variables, changeValue),
    createListRowForVariable(FLAVOR_PRIORITIES_SABOR_L, LEMON, variables, changeValue),
    createListRowForVariable(FLAVOR_PRIORITIES_SABOR_M, MANGO, variables, changeValue),
    createListRowForVariable(FLAVOR_PRIORITIES_SABOR_R, RUM, variables, changeValue),
    createListRowForVariable(FLAVOR_PRIORITIES_SABOR_V, VANILLA, variables, changeValue),
  ]
}

const Packaging = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesFlavorsPriority', dispatch, changeVariable)
  const variables = useSelector(state => state.products.currentValues.variablesFlavorsPriority)
  return (
    <List
      listTitle={FLAVORS_PRIORITY}
      items={returnData(variables, changeValue)}
      helpMessage={HELPS[FLAVORS_PRIORITY]}
    />
  )
}

export default Packaging
