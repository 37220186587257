import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { MARKETING_AND_LOGISTICS, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const { CAMIONES_COMPETENCIA, TOTAL_CAMIONES } = MARKETING_AND_LOGISTICS

const { TIME } = HEADER
const { FLEET, COMPETITION, OWNED } = CONSTANTS.MARKETING_AND_LOGISTICS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      getChartLineForVariable(CAMIONES_COMPETENCIA, COMPETITION, CHART_COLORS.COLOR_A, variables),
      getChartLineForVariable(TOTAL_CAMIONES, OWNED, CHART_COLORS.COLOR_B, variables),
    ],
  }
}

const ChartFleet = () => {
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.fleet)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart={FLEET} />
  ) : null
}

export default ChartFleet
