import React from "react";
import { Button } from "@material-ui/core";
import "styles/buttonStyles.css";

const NavigationButton = props => {
  const { history, route, disabled, label } = props;

  const goToPage = () => {
    history.push(route);
  };

  return (
    <div className="bottomArea">
      <Button
        disabled={disabled}
        variant="outlined"
        onClick={() => (route ? goToPage() : props.action())}
      >
        {label}
      </Button>
    </div>
  );
};

export default NavigationButton;
