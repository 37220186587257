import {
  INVENTORY,
  OPERATIONS,
  PRODUCTS,
  MARKETING_AND_LOGISTICS,
  RESULTS,
} from 'constants/forioVariables'

const {
  PASTEURIZERS_DESIRED,
  MATURATION_TANKS_DESIRED,
  BATIDORAS_DESIRED,
  CUARTO_FRIO_DESIRED,
  PRODUCTION_DECISION_HELADO,
  AGEING,
  OVERRUN_PERCENTAGE,
  MONTHLY_SALARY_PER_PERSON,
  PERSONNEL_DESIRED_PER_SHIFT,
  DECISION_INVERTIR_MANTENIMIENTO,
} = OPERATIONS

const {
  PACKAGING_DECISION_EMP_A,
  PACKAGING_DECISION_EMP_B,
  PACKAGING_DECISION_EMP_C,
  PACKAGING_DECISION_EMP_D,
  PACKAGING_DECISION_EMP_E,
  PACKAGING_DECISION_EMP_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_C,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_F,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_L,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_M,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_R,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_V,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V,
  FLAVOR_PRIORITIES_SABOR_C,
  FLAVOR_PRIORITIES_SABOR_F,
  FLAVOR_PRIORITIES_SABOR_L,
  FLAVOR_PRIORITIES_SABOR_M,
  FLAVOR_PRIORITIES_SABOR_R,
  FLAVOR_PRIORITIES_SABOR_V,
  PACKING_PRIORITIES_EMP_A,
  PACKING_PRIORITIES_EMP_B,
  PACKING_PRIORITIES_EMP_C,
  PACKING_PRIORITIES_EMP_D,
  PACKING_PRIORITIES_EMP_E,
  PACKING_PRIORITIES_EMP_F,
} = PRODUCTS

const {
  CANTIDAD_PEDIDA_EMPAQUES_A,
  PUNTO_DE_REORDEN_EMPAQUES_A,
  CANTIDAD_PEDIDA_EMPAQUES_B,
  PUNTO_DE_REORDEN_EMPAQUES_B,
  CANTIDAD_PEDIDA_EMPAQUES_C,
  PUNTO_DE_REORDEN_EMPAQUES_C,
  CANTIDAD_PEDIDA_EMPAQUES_D,
  PUNTO_DE_REORDEN_EMPAQUES_D,
  CANTIDAD_PEDIDA_EMPAQUES_E,
  PUNTO_DE_REORDEN_EMPAQUES_E,
  CANTIDAD_PEDIDA_EMPAQUES_F,
  PUNTO_DE_REORDEN_EMPAQUES_F,
  CANTIDAD_PEDIDA_LECHE,
  PUNTO_DE_REORDEN_LECHE,
  CANTIDAD_PEDIDA_OTROS,
  PUNTO_DE_REORDEN_OTROS,
} = INVENTORY

const {
  PRECIO_EMPAQUE_A,
  PRECIO_EMPAQUE_B,
  PRECIO_EMPAQUE_C,
  PRECIO_EMPAQUE_D,
  PRECIO_EMPAQUE_E,
  PRECIO_EMPAQUE_F,
  DECISION_A_INVERTIR_EN_MERCADEO,
  TRUCKS_DESIRED,
} = MARKETING_AND_LOGISTICS

const { DIAS_DE_COBRO, DEBT_AMORTIZATION_PERCENTAGE_TRIMESTER_DECISION } = RESULTS

const allowUpdate = [
  // INVENTORY
  CANTIDAD_PEDIDA_EMPAQUES_A,
  PUNTO_DE_REORDEN_EMPAQUES_A,
  CANTIDAD_PEDIDA_EMPAQUES_B,
  PUNTO_DE_REORDEN_EMPAQUES_B,
  CANTIDAD_PEDIDA_EMPAQUES_C,
  PUNTO_DE_REORDEN_EMPAQUES_C,
  CANTIDAD_PEDIDA_EMPAQUES_D,
  PUNTO_DE_REORDEN_EMPAQUES_D,
  CANTIDAD_PEDIDA_EMPAQUES_E,
  PUNTO_DE_REORDEN_EMPAQUES_E,
  CANTIDAD_PEDIDA_EMPAQUES_F,
  PUNTO_DE_REORDEN_EMPAQUES_F,
  CANTIDAD_PEDIDA_LECHE,
  PUNTO_DE_REORDEN_LECHE,
  CANTIDAD_PEDIDA_OTROS,
  PUNTO_DE_REORDEN_OTROS,

  // OPERATIONS
  PASTEURIZERS_DESIRED,
  MATURATION_TANKS_DESIRED,
  BATIDORAS_DESIRED,
  CUARTO_FRIO_DESIRED,
  PRODUCTION_DECISION_HELADO,
  AGEING,
  OVERRUN_PERCENTAGE,
  MONTHLY_SALARY_PER_PERSON,
  PERSONNEL_DESIRED_PER_SHIFT,
  DECISION_INVERTIR_MANTENIMIENTO,

  // PRODUCTS
  PACKAGING_DECISION_EMP_A,
  PACKAGING_DECISION_EMP_B,
  PACKAGING_DECISION_EMP_C,
  PACKAGING_DECISION_EMP_D,
  PACKAGING_DECISION_EMP_E,
  PACKAGING_DECISION_EMP_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_C,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_C,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_F,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_F,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_L,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_L,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_M,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_M,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_R,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_R,
  EMPAQUE_A_FLAVOR_DECISION_SABOR_V,
  EMPAQUE_B_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_C_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_D_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_E_FLAVOR_DECISIONS_SABOR_V,
  EMPAQUE_F_FLAVOR_DECISIONS_SABOR_V,
  FLAVOR_PRIORITIES_SABOR_C,
  FLAVOR_PRIORITIES_SABOR_F,
  FLAVOR_PRIORITIES_SABOR_L,
  FLAVOR_PRIORITIES_SABOR_M,
  FLAVOR_PRIORITIES_SABOR_R,
  FLAVOR_PRIORITIES_SABOR_V,
  PACKING_PRIORITIES_EMP_A,
  PACKING_PRIORITIES_EMP_B,
  PACKING_PRIORITIES_EMP_C,
  PACKING_PRIORITIES_EMP_D,
  PACKING_PRIORITIES_EMP_E,
  PACKING_PRIORITIES_EMP_F,

  // MARKETING AND LOGISTICS
  PRECIO_EMPAQUE_A,
  PRECIO_EMPAQUE_B,
  PRECIO_EMPAQUE_C,
  PRECIO_EMPAQUE_D,
  PRECIO_EMPAQUE_E,
  PRECIO_EMPAQUE_F,
  DECISION_A_INVERTIR_EN_MERCADEO,
  TRUCKS_DESIRED,

  // RESULTS
  DIAS_DE_COBRO,
  DEBT_AMORTIZATION_PERCENTAGE_TRIMESTER_DECISION,
]

const seletedVariablesToUpdate = variables => {
  let variablesToUpdate = {}
  for (const key in variables) {
    if (variables) {
      // eslint-disable-next-line no-loop-func
      allowUpdate.forEach(allow => {
        if (`${key}` === `${allow}`) {
          variablesToUpdate = {
            ...variablesToUpdate,
            [`${key}`]: variables[key],
          }
        }
      })
    }
  }
  return variablesToUpdate
}

export default seletedVariablesToUpdate
