import { CONSTANTS } from './constants'
import { OPERATIONS, MARKETING_AND_LOGISTICS } from './forioVariables'

const { PACKAGING_PRIORITY, FLAVORS_PRIORITY } = CONSTANTS.PRODUCTS
const { INVENTORY_PER_PACKAGE, RAW_MATERIALS } = CONSTANTS.INVENTORY
const {
  PASTEURIZERS_DESIRED,
  MATURATION_TANKS_DESIRED,
  BATIDORAS_DESIRED,
  CUARTO_FRIO_DESIRED,
} = OPERATIONS
const { TRUCKS_DESIRED } = MARKETING_AND_LOGISTICS

const HELPS = {
  [PACKAGING_PRIORITY]: [
    'Priority goes from 1 to 6. Highest number is the highest priority. Equal priority means production is divided evenly.',
  ],
  [FLAVORS_PRIORITY]: [
    'Priority goes from 1 to 6. Highest number is the highest priority. Equal priority means production is divided evenly.',
  ],
  [PASTEURIZERS_DESIRED]: ['Cost: $50,000', 'Rated Capacity: 100 Gl/h'],
  [MATURATION_TANKS_DESIRED]: ['Cost: $50,000', 'Volumetric Capacity: 300 Gl'],
  [BATIDORAS_DESIRED]: [
    'Cost: $50,000',
    'Rated Capacity: 14 Gl/h for 5 Gl package, 7 Gl/h for 0.0125 package.',
    'Capacity affected by setups',
  ],
  [CUARTO_FRIO_DESIRED]: ['Cost per additional Gl of storage:  $15'],
  [TRUCKS_DESIRED]: ['Cost: $25,000', 'Operation cost: $4/h'],
  [INVENTORY_PER_PACKAGE]: [
    'Cost per Gl:',
    '5 Gl: $0.17',
    '2.5 Gl: $0.28',
    '1 Gl: $0.55',
    '0.5 Gl: $0.70',
    '0.1 Gl: $3.00',
    '0.0125 Gl: $20.00',
  ],
  [RAW_MATERIALS]: ['Cost per Gl:', 'Milk: $0.50', 'Others: $0.75'],
}

export { HELPS }
