import { allFunctionsFetchProducts } from 'actions/productsActions'
import { allFunctionsFetchInventory } from 'actions/inventoryActions'
import { allFunctionsFetchHeader } from 'actions/headerActions'
import { allFunctionsFetchOperations } from 'actions/operationsActions'
import { allFunctionsFetchResults } from 'actions/resultsActions'
import { allFunctionsFetchMarketingAndLogistics } from 'actions/marketingAndLogisticsActions'

import { CLEAR_VALUES } from 'constants/actionTypes'

const allDispatch = async dispatch => {
  await dispatch({ type: CLEAR_VALUES })
  await allFunctionsFetchProducts.forEach(fetchFunction => dispatch(fetchFunction()))
  await allFunctionsFetchInventory.forEach(fetchFunction => dispatch(fetchFunction()))
  await allFunctionsFetchHeader.forEach(fetchFunction => dispatch(fetchFunction()))
  await allFunctionsFetchOperations.forEach(fetchFunction => dispatch(fetchFunction()))
  await allFunctionsFetchResults.forEach(fetchFunction => dispatch(fetchFunction()))
  await allFunctionsFetchMarketingAndLogistics.forEach(fetchFunction => dispatch(fetchFunction()))
}

export default allDispatch
