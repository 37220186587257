import {
  GET_VARIABLES_PER_PACKAGE,
  GET_VARIABLES_RAW_MATERIALS,
  GET_VARIABLES_CHART_PACKAGING_MATERIAL,
  GET_VARIABLES_CHART_RAW_MATERIALS,
  CHANGE_VARIABLE_INVENTORY,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { INVENTORY } from 'constants/forioVariables'

const {
  CANTIDAD_PEDIDA_EMPAQUES_A,
  PUNTO_DE_REORDEN_EMPAQUES_A,
  CANTIDAD_PEDIDA_EMPAQUES_B,
  PUNTO_DE_REORDEN_EMPAQUES_B,
  CANTIDAD_PEDIDA_EMPAQUES_C,
  PUNTO_DE_REORDEN_EMPAQUES_C,
  CANTIDAD_PEDIDA_EMPAQUES_D,
  PUNTO_DE_REORDEN_EMPAQUES_D,
  CANTIDAD_PEDIDA_EMPAQUES_E,
  PUNTO_DE_REORDEN_EMPAQUES_E,
  CANTIDAD_PEDIDA_EMPAQUES_F,
  PUNTO_DE_REORDEN_EMPAQUES_F,
  CANTIDAD_PEDIDA_LECHE,
  PUNTO_DE_REORDEN_LECHE,
  CANTIDAD_PEDIDA_OTROS,
  PUNTO_DE_REORDEN_OTROS,
  INVENTARIO_DE_EMPAQUES_A_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_B_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_C_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_D_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_E_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_F_PARA_TABLA,
  INVENTARIO_DE_LECHE,
  INVENTARIO_DE_OTROS,
} = INVENTORY

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_INVENTORY, payload }
}

const variablesPerPackage = [
  CANTIDAD_PEDIDA_EMPAQUES_A,
  PUNTO_DE_REORDEN_EMPAQUES_A,
  CANTIDAD_PEDIDA_EMPAQUES_B,
  PUNTO_DE_REORDEN_EMPAQUES_B,
  CANTIDAD_PEDIDA_EMPAQUES_C,
  PUNTO_DE_REORDEN_EMPAQUES_C,
  CANTIDAD_PEDIDA_EMPAQUES_D,
  PUNTO_DE_REORDEN_EMPAQUES_D,
  CANTIDAD_PEDIDA_EMPAQUES_E,
  PUNTO_DE_REORDEN_EMPAQUES_E,
  CANTIDAD_PEDIDA_EMPAQUES_F,
  PUNTO_DE_REORDEN_EMPAQUES_F,
]

const getVariablesPerPackage = variables => ({
  type: GET_VARIABLES_PER_PACKAGE,
  variables,
})

const fetchVariablesPerPackage = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesPerPackage)
    dispatch(getVariablesPerPackage(variables))
  }
}

const variablesRawMaterials = [
  CANTIDAD_PEDIDA_LECHE,
  PUNTO_DE_REORDEN_LECHE,
  CANTIDAD_PEDIDA_OTROS,
  PUNTO_DE_REORDEN_OTROS,
]

const getVariablesRawMaterials = variables => ({
  type: GET_VARIABLES_RAW_MATERIALS,
  variables,
})

const fetchVariablesRawMaterials = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesRawMaterials)
    dispatch(getVariablesRawMaterials(variables))
  }
}

const variablesChartPackagingMaterial = [
  INVENTARIO_DE_EMPAQUES_A_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_B_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_C_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_D_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_E_PARA_TABLA,
  INVENTARIO_DE_EMPAQUES_F_PARA_TABLA,
]

const getVariablesChartPackagingMaterial = variables => ({
  type: GET_VARIABLES_CHART_PACKAGING_MATERIAL,
  variables,
})

const fetchVariablesChartPackagingMaterial = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesChartPackagingMaterial)
    dispatch(getVariablesChartPackagingMaterial(variables))
  }
}

const variablesChartRawMaterials = [INVENTARIO_DE_LECHE, INVENTARIO_DE_OTROS]

const getVariablesChartRawMaterials = variables => ({
  type: GET_VARIABLES_CHART_RAW_MATERIALS,
  variables,
})

const fetchVariablesChartRawMaterials = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesChartRawMaterials)
    dispatch(getVariablesChartRawMaterials(variables))
  }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFunctionsFetchInventory = [
  fetchVariablesPerPackage,
  fetchVariablesRawMaterials,
  fetchVariablesChartPackagingMaterial,
  fetchVariablesChartRawMaterials,
]

export {
  changeVariable,
  fetchVariablesPerPackage,
  fetchVariablesRawMaterials,
  fetchVariablesChartPackagingMaterial,
  fetchVariablesChartRawMaterials,
  allFunctionsFetchInventory,
  clearNewValues,
}
