import React from 'react'
import { defaults, Bar } from 'react-chartjs-2'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

defaults.global.defaultFontColor = '#73879C'
const options = {
  legend: {
    display: false,
    labels: {
      fontColor: '#73879C',
    },
  },
}

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9ED',
    boxShadow: 'none',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '2px solid #E6E9ED',
    padding: '0.75rem 0.75rem',
    margin: '0 0.75rem',
    backgroundColor: '#FFFFFF',
  },
  title: {
    color: '#73879C',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
})

const BarChart = ({ data, titleChart }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{titleChart}</h2>
      </div>
      <CardContent>
        <Bar data={data} options={options} />
      </CardContent>
    </Card>
  )
}
export default BarChart
