import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Fab, withStyles, Snackbar, Fade } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { makeStyles } from '@material-ui/core/styles'
import { loadLocalStorageValue, localStorageKeys } from 'services/localstorage'
import allDispatch from 'common/allDispatch'
import { translations, UnknownErrorMessage } from 'constants/translations'
import { getTeam } from 'actions/appBackendActions'
import { Alert } from '@material-ui/lab'

const CustomAlert = withStyles({
  root: {
    '& .MuiAlert-action': {
      alignItems: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
})(Alert)

const useStyle = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    textTransform: 'capitalize',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const ButtonRefreshSimulation = () => {
  const classes = useStyle()
  const dispatch = useDispatch()

  const teamInformation = loadLocalStorageValue(localStorageKeys.TEAM_INFORMATION)

  const initialSnackbarState = { open: false, severity: 'info', text: '' }
  const [snackbarState, setSnackbarState] = React.useState(initialSnackbarState)

  const handleSnackbarOpen = ({ severity, text }) => {
    setSnackbarState({ open: true, severity, text })
  }

  const snackbarSuccess = text => handleSnackbarOpen({ severity: 'success', text })
  const snackbarError = text => handleSnackbarOpen({ severity: 'error', text })

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarState(initialSnackbarState)
  }

  const refreshTeam = async () => {
    const response = await getTeam(teamInformation.id)
    return response
  }

  const getVariables = useCallback(() => {
    allDispatch(dispatch)
  }, [dispatch])

  const updateTeam = async () => {
    const response = await refreshTeam()
    if (response.outcome) {
      await getVariables()
      snackbarSuccess('Simulation data updated')
    } else {
      const translatedError = translations[`${response.message}`]
      snackbarError(translatedError || UnknownErrorMessage)
    }
  }

  return (
    <>
      <Fab
        onClick={updateTeam}
        variant="extended"
        size="small"
        aria-label="run"
        className={classes.margin}
      >
        <Typography fontWeight="fontWeightLight" fontSize={8} className={classes.title}>
          refresh
        </Typography>
        <RefreshIcon className={classes.extendedIcon} />
      </Fab>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbarState.open}
        TransitionComponent={Fade}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <CustomAlert onClose={handleSnackbarClose} severity={snackbarState.severity}>
          {snackbarState.text}
        </CustomAlert>
      </Snackbar>
    </>
  )
}

export default ButtonRefreshSimulation
