import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import HelpButton from 'components/HelpButton'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9ED',
    boxShadow: 'none',
    color: '#73879C',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '2px solid #E6E9ED',
    padding: '0.75rem 0.75rem',
    margin: '0 0.75rem',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#73879C',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
  itemText: {
    fontSize: '.875rem',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
})

const ListItems = ({ items, listTitle, helpMessage }) => {
  const classes = useStyles()
  let title = null
  let helpButton = null

  if (helpMessage) {
    helpButton = <HelpButton helpTitle={listTitle} helpMessage={helpMessage} />
  }

  if (listTitle) {
    title = (
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{listTitle}</h2>
        {helpButton}
      </div>
    )
  }

  return (
    <Card className={classes.card}>
      {title}
      <CardContent>
        <Box>
          {items.map(item => (
            <Box key={item.id} display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <p>{item.label}</p>
              </Box>
              <Box display="flex" alignItems="center">
                <Box style={{ padding: '0px 10px' }}>{item.component}</Box>
                {item.helpMessage ? (
                  <HelpButton helpTitle={item.label} helpMessage={item.helpMessage} />
                ) : null}
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

export default ListItems
