import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Card, CardContent, Box } from '@material-ui/core'
import { CONSTANTS } from 'constants/constants'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import useStyles from './style'

const { MARKET_SHARE } = CONSTANTS.MARKETING_AND_LOGISTICS

const { MARKET_SHARE_TOTAL } = MARKETING_AND_LOGISTICS

const MarketShare = () => {
  const classes = useStyles()
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.marketShare)
  const value = variables[MARKET_SHARE_TOTAL].toFixed(2)

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box key={MARKET_SHARE_TOTAL} alignItems="left">
          <Box style={{ marginBottom: '10px' }}>
            <Typography className={classes.value}>{value}</Typography>
          </Box>
          <Box>
            <p style={{ marginBottom: 0 }}>{MARKET_SHARE}</p>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
export default MarketShare
