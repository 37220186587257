import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/productsActions'
import Box from '@material-ui/core/Box'
import { Packaging, FlavorsPriority, PackagingPriority, BiweeklyProduction } from './List'
import { Flavors } from './TablesDecisions'
import {
  InventoryPackageA,
  InventoryPackageB,
  InventoryPackageC,
  InventoryPackageD,
  InventoryPackageE,
  InventoryPackageF,
} from './Graphics'

const Products = () => {
  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateProducts = useSelector(state => state.products.currentValues)
  if (validateStates(stateProducts)) {
    return <Loading />
  }
  return (
    <Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <Packaging />
          <BiweeklyProduction />
        </Box>
        <Box className="elementWrapper elementToRight">
          <Flavors />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <PackagingPriority />
        </Box>
        <Box className="elementWrapper elementToRight">
          <FlavorsPriority />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <InventoryPackageA />
        </Box>
        <Box className="elementWrapper elementToRight">
          <InventoryPackageB />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <InventoryPackageC />
        </Box>
        <Box className="elementWrapper elementToRight">
          <InventoryPackageD />
        </Box>
        <Box className="elementWrapper elementToLeft">
          <InventoryPackageE />
        </Box>
        <Box className="elementWrapper elementToRight">
          <InventoryPackageF />
        </Box>
      </Box>
    </Box>
  )
}

export default Products
