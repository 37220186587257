import Inventory from 'routes/Inventory'
import Products from 'routes/Products'
import Operations from 'routes/Operations'
import Results from 'routes/Results'
import MarketingAndLogistics from 'routes/MarketingAndLogistics'
import * as ROUTES from 'constants/routes'
import {
  faHome,
  faIceCream,
  faBoxesStacked,
  faGears,
  faChartLine,
  faCar,
} from '@fortawesome/free-solid-svg-icons'

const Menu = () => [
  {
    name: 'Home',
    icon: faHome,
    path: ROUTES.BASE,
  },
  {
    name: 'Products',
    icon: faIceCream,
    path: ROUTES.PRODUCTS,
    component: Products,
  },
  {
    name: 'Operations',
    icon: faGears,
    path: ROUTES.OPERATIONS,
    component: Operations,
  },
  {
    name: 'Marketing And Logistics',
    icon: faCar,
    path: ROUTES.MARKETING_AND_LOGISTICS,
    component: MarketingAndLogistics,
  },
  {
    name: 'Inventory',
    icon: faBoxesStacked,
    path: ROUTES.INVENTORY,
    component: Inventory,
  },
  {
    name: 'Results',
    icon: faChartLine,
    path: ROUTES.RESULTS,
    component: Results,
  },
]

export default Menu
