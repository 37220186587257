import { makeStyles } from '@material-ui/core/styles'

const style = makeStyles({
  container: {
    color: '#777777',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
    fontSize: '.875rem',
    fontWeight: '400',
  },
})

export default style
