import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { changeVariable } from 'actions/inventoryActions'
import { INVENTORY } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { changeValue as onChangeValue } from 'common'
import { createColumnCellForVariable } from 'common/UIComponentUtilities'
import { HELPS } from 'constants/helps'

const {
  RAW_MATERIALS,
  MATERIAL,
  MILK,
  OTHERS,
  REORDEN_QUANTITY,
  REORDEN_POINT,
} = CONSTANTS.INVENTORY

const {
  CANTIDAD_PEDIDA_LECHE,
  PUNTO_DE_REORDEN_LECHE,
  CANTIDAD_PEDIDA_OTROS,
  PUNTO_DE_REORDEN_OTROS,
} = INVENTORY

const columns = changeValue => [
  { type: 'label', name: MATERIAL },
  { type: 'inputNumber', name: REORDEN_QUANTITY, onChange: changeValue },
  { type: 'inputNumber', name: REORDEN_POINT, onChange: changeValue },
]

const returnData = variables => [
  [
    MILK,
    createColumnCellForVariable(CANTIDAD_PEDIDA_LECHE, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_LECHE, variables),
  ],
  [
    OTHERS,
    createColumnCellForVariable(CANTIDAD_PEDIDA_OTROS, variables),
    createColumnCellForVariable(PUNTO_DE_REORDEN_OTROS, variables),
  ],
]

const RawMaterials = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesRawMaterials', dispatch, changeVariable)
  const variables = useSelector(state => state.inventory.currentValues.variablesRawMaterials)
  return (
    <Table
      titleTable={RAW_MATERIALS}
      columns={columns(changeValue)}
      data={returnData(variables)}
      helpMessage={HELPS[RAW_MATERIALS]}
    />
  )
}

export default RawMaterials
