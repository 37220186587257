import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const { TIME } = HEADER
const { CHOCOLATE, STRAWBERRY, LEMON, MANGO, RUM, VANILLA } = CONSTANTS.PRODUCTS

const returnData = (variables, flavorsToVariableMapping, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      getChartLineForVariable(
        flavorsToVariableMapping[CHOCOLATE],
        CHOCOLATE,
        CHART_COLORS.COLOR_A,
        variables,
      ),
      getChartLineForVariable(
        flavorsToVariableMapping[STRAWBERRY],
        STRAWBERRY,
        CHART_COLORS.COLOR_B,
        variables,
      ),
      getChartLineForVariable(
        flavorsToVariableMapping[LEMON],
        LEMON,
        CHART_COLORS.COLOR_C,
        variables,
      ),
      getChartLineForVariable(
        flavorsToVariableMapping[MANGO],
        MANGO,
        CHART_COLORS.COLOR_D,
        variables,
      ),
      getChartLineForVariable(flavorsToVariableMapping[RUM], RUM, CHART_COLORS.COLOR_E, variables),
      getChartLineForVariable(
        flavorsToVariableMapping[VANILLA],
        VANILLA,
        CHART_COLORS.COLOR_F,
        variables,
      ),
    ],
  }
}

const BaseSalesChart = props => {
  const { variables, title, flavorsToVariableMapping } = props
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, flavorsToVariableMapping, time)} titleChart={title} />
  ) : null
}

export default BaseSalesChart
