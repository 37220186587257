import React from 'react'
import Box from '@material-ui/core/Box'
import Loading from 'components/Loading'
import { useSelector, useDispatch } from 'react-redux'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/inventoryActions'
import { InventoryPerPackage, RawMaterials } from './TablesDecisions'
import { ChartPackagingMaterial, ChartRawMaterials } from './Graphics'

const Inventory = () => {
  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateInventory = useSelector(state => state.inventory.currentValues)
  if (validateStates(stateInventory)) {
    return <Loading />
  }

  return (
    <div>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <InventoryPerPackage />
        </Box>
        <Box className="elementWrapper elementToRight">
          <RawMaterials />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ChartPackagingMaterial />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartRawMaterials />
        </Box>
      </Box>
    </div>
  )
}

export default Inventory
