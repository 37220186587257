import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9ED',
    boxShadow: 'none',
    color: '#73879C',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '2px solid #E6E9ED',
    padding: '0.75rem 0.75rem',
    margin: '0 0.75rem',
    backgroundColor: '#FFFFFF',
  },
  title: {
    color: '#73879C',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
})

export default useStyles
