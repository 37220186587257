import React from 'react'
import { useSelector } from 'react-redux'
import { PRODUCTS } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import ChartInventoryByProductPresentation from './InventoryByProductPresentation'

const {
  TOTAL,
  CHOCOLATE,
  STRAWBERRY,
  LEMON,
  MANGO,
  RUM,
  VANILLA,
  FINISHED_INVENTORY_0_1_GL,
} = CONSTANTS.PRODUCTS

const {
  TOTAL_FG_INVENTORY_E,
  PT_PAQUETE_E_SABOR_C,
  PT_PAQUETE_E_SABOR_F,
  PT_PAQUETE_E_SABOR_L,
  PT_PAQUETE_E_SABOR_M,
  PT_PAQUETE_E_SABOR_R,
  PT_PAQUETE_E_SABOR_V,
} = PRODUCTS

const productToVariableMapping = {
  [TOTAL]: TOTAL_FG_INVENTORY_E,
  [CHOCOLATE]: PT_PAQUETE_E_SABOR_C,
  [STRAWBERRY]: PT_PAQUETE_E_SABOR_F,
  [LEMON]: PT_PAQUETE_E_SABOR_L,
  [MANGO]: PT_PAQUETE_E_SABOR_M,
  [RUM]: PT_PAQUETE_E_SABOR_R,
  [VANILLA]: PT_PAQUETE_E_SABOR_V,
}

const ChartInventoryPackageA = () => {
  const variables = useSelector(
    state => state.products.currentValues.variablesChartInventoryPackageE,
  )
  return (
    <ChartInventoryByProductPresentation
      title={FINISHED_INVENTORY_0_1_GL}
      variables={variables}
      flavorToVariableMapping={productToVariableMapping}
    />
  )
}

export default ChartInventoryPackageA
