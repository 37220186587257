import {
  CLEAR_NEW_VALUES,
  CHANGE_VARIABLE_MARKETING_AND_LOGISTICS,
  GET_VARIABLES_PRICING,
  GET_VARIABLES_MARKETING,
  GET_VARIABLES_LOGISTICS,
  GET_VARIABLES_MARKET_SHARE_TOTAL,
  GET_VARIABLES_PERIOD_SALES,
  GET_VARIABLES_MARKET_PRICE,
  GET_VARIABLES_MARKET_SHARES,
  GET_VARIABLES_FILL_RATES,
  GET_VARIABLES_FLEET,
  GET_VARIABLES_SALES_PACKAGE_A,
  GET_VARIABLES_SALES_PACKAGE_B,
  GET_VARIABLES_SALES_PACKAGE_C,
  GET_VARIABLES_SALES_PACKAGE_D,
  GET_VARIABLES_SALES_PACKAGE_E,
  GET_VARIABLES_SALES_PACKAGE_F,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'

const {
  PRECIO_EMPAQUE_A,
  PRECIO_EMPAQUE_B,
  PRECIO_EMPAQUE_C,
  PRECIO_EMPAQUE_D,
  PRECIO_EMPAQUE_E,
  PRECIO_EMPAQUE_F,
  DECISION_A_INVERTIR_EN_MERCADEO,
  TRUCKS_DESIRED,
  MARKET_SHARE_TOTAL,
  VENTA_TOTAL_ACUMULADA,
  PRECIO_A_COMPETENCIA,
  PRECIO_B_COMPETENCIA,
  PRECIO_C_COMPETENCIA,
  PRECIO_D_COMPETENCIA,
  PRECIO_E_COMPETENCIA,
  PRECIO_F_COMPETENCIA,
  MARKET_SHARE_EMPAQUE_A,
  MARKET_SHARE_EMPAQUE_B,
  MARKET_SHARE_EMPAQUE_C,
  MARKET_SHARE_EMPAQUE_D,
  MARKET_SHARE_EMPAQUE_E,
  MARKET_SHARE_EMPAQUE_F,
  FILL_RATE_EMPAQUES_GRANDES,
  FILL_RATE_EMPAQUES_PEQUEÑOS,
  CAMIONES_COMPETENCIA,
  TOTAL_CAMIONES,
  VENTA_DE_A_SABOR_C,
  VENTA_DE_A_SABOR_F,
  VENTA_DE_A_SABOR_L,
  VENTA_DE_A_SABOR_M,
  VENTA_DE_A_SABOR_R,
  VENTA_DE_A_SABOR_V,
  VENTA_DE_B_SABOR_C,
  VENTA_DE_B_SABOR_F,
  VENTA_DE_B_SABOR_L,
  VENTA_DE_B_SABOR_M,
  VENTA_DE_B_SABOR_R,
  VENTA_DE_B_SABOR_V,
  VENTA_DE_C_SABOR_C,
  VENTA_DE_C_SABOR_F,
  VENTA_DE_C_SABOR_L,
  VENTA_DE_C_SABOR_M,
  VENTA_DE_C_SABOR_R,
  VENTA_DE_C_SABOR_V,
  VENTA_DE_D_SABOR_C,
  VENTA_DE_D_SABOR_F,
  VENTA_DE_D_SABOR_L,
  VENTA_DE_D_SABOR_M,
  VENTA_DE_D_SABOR_R,
  VENTA_DE_D_SABOR_V,
  VENTA_DE_E_SABOR_C,
  VENTA_DE_E_SABOR_F,
  VENTA_DE_E_SABOR_L,
  VENTA_DE_E_SABOR_M,
  VENTA_DE_E_SABOR_R,
  VENTA_DE_E_SABOR_V,
  VENTA_DE_F_SABOR_C,
  VENTA_DE_F_SABOR_F,
  VENTA_DE_F_SABOR_L,
  VENTA_DE_F_SABOR_M,
  VENTA_DE_F_SABOR_R,
  VENTA_DE_F_SABOR_V,
} = MARKETING_AND_LOGISTICS

const pricing = [
  PRECIO_EMPAQUE_A,
  PRECIO_EMPAQUE_B,
  PRECIO_EMPAQUE_C,
  PRECIO_EMPAQUE_D,
  PRECIO_EMPAQUE_E,
  PRECIO_EMPAQUE_F,
]

const getVariablesPricing = variables => ({
  type: GET_VARIABLES_PRICING,
  variables,
})

const fetchVariablesPricing = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(pricing)
    dispatch(getVariablesPricing(variables))
  }
}

const marketing = [DECISION_A_INVERTIR_EN_MERCADEO]

const getVariablesMarketing = variables => ({
  type: GET_VARIABLES_MARKETING,
  variables,
})

const fetchVariablesMarketing = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(marketing)
    dispatch(getVariablesMarketing(variables))
  }
}

const logistics = [TRUCKS_DESIRED]

const getVariablesLogistics = variables => ({
  type: GET_VARIABLES_LOGISTICS,
  variables,
})

const fetchVariablesLogistics = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(logistics)
    dispatch(getVariablesLogistics(variables))
  }
}

const marketShare = [MARKET_SHARE_TOTAL]

const getVariablesMarketShare = variables => ({
  type: GET_VARIABLES_MARKET_SHARE_TOTAL,
  variables,
})

const fetchVariablesMarketShare = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(marketShare)
    dispatch(getVariablesMarketShare(variables))
  }
}

const periodSales = [VENTA_TOTAL_ACUMULADA]

const getVariablesPeriodSales = variables => ({
  type: GET_VARIABLES_PERIOD_SALES,
  variables,
})

const fetchVariablesPeriodSales = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(periodSales)
    dispatch(getVariablesPeriodSales(variables))
  }
}

const marketPrice = [
  PRECIO_A_COMPETENCIA,
  PRECIO_B_COMPETENCIA,
  PRECIO_C_COMPETENCIA,
  PRECIO_D_COMPETENCIA,
  PRECIO_E_COMPETENCIA,
  PRECIO_F_COMPETENCIA,
]

const getVariablesMarketPrice = variables => ({
  type: GET_VARIABLES_MARKET_PRICE,
  variables,
})

const fetchVariablesMarketPrice = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(marketPrice)
    dispatch(getVariablesMarketPrice(variables))
  }
}

const marketShares = [
  MARKET_SHARE_EMPAQUE_A,
  MARKET_SHARE_EMPAQUE_B,
  MARKET_SHARE_EMPAQUE_C,
  MARKET_SHARE_EMPAQUE_D,
  MARKET_SHARE_EMPAQUE_E,
  MARKET_SHARE_EMPAQUE_F,
]

const getVariablesMarketShares = variables => ({
  type: GET_VARIABLES_MARKET_SHARES,
  variables,
})

const fetchVariablesMarketShares = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(marketShares)
    dispatch(getVariablesMarketShares(variables))
  }
}

const fillRates = [FILL_RATE_EMPAQUES_GRANDES, FILL_RATE_EMPAQUES_PEQUEÑOS]

const getVariablesFillRates = variables => ({
  type: GET_VARIABLES_FILL_RATES,
  variables,
})

const fetchVariablesFillRates = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(fillRates)
    dispatch(getVariablesFillRates(variables))
  }
}

const fleet = [CAMIONES_COMPETENCIA, TOTAL_CAMIONES]

const getVariablesFleet = variables => ({
  type: GET_VARIABLES_FLEET,
  variables,
})

const fetchVariablesFleet = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(fleet)
    dispatch(getVariablesFleet(variables))
  }
}

const salesPackageA = [
  VENTA_DE_A_SABOR_C,
  VENTA_DE_A_SABOR_F,
  VENTA_DE_A_SABOR_L,
  VENTA_DE_A_SABOR_M,
  VENTA_DE_A_SABOR_R,
  VENTA_DE_A_SABOR_V,
]

const getVariablesSalesPackageA = variables => ({
  type: GET_VARIABLES_SALES_PACKAGE_A,
  variables,
})

const fetchVariablesSalesPackageA = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(salesPackageA)
    dispatch(getVariablesSalesPackageA(variables))
  }
}

const salesPackageB = [
  VENTA_DE_B_SABOR_C,
  VENTA_DE_B_SABOR_F,
  VENTA_DE_B_SABOR_L,
  VENTA_DE_B_SABOR_M,
  VENTA_DE_B_SABOR_R,
  VENTA_DE_B_SABOR_V,
]

const getVariablesSalesPackageB = variables => ({
  type: GET_VARIABLES_SALES_PACKAGE_B,
  variables,
})

const fetchVariablesSalesPackageB = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(salesPackageB)
    dispatch(getVariablesSalesPackageB(variables))
  }
}

const salesPackageC = [
  VENTA_DE_C_SABOR_C,
  VENTA_DE_C_SABOR_F,
  VENTA_DE_C_SABOR_L,
  VENTA_DE_C_SABOR_M,
  VENTA_DE_C_SABOR_R,
  VENTA_DE_C_SABOR_V,
]

const getVariablesSalesPackageC = variables => ({
  type: GET_VARIABLES_SALES_PACKAGE_C,
  variables,
})

const fetchVariablesSalesPackageC = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(salesPackageC)
    dispatch(getVariablesSalesPackageC(variables))
  }
}

const salesPackageD = [
  VENTA_DE_D_SABOR_C,
  VENTA_DE_D_SABOR_F,
  VENTA_DE_D_SABOR_L,
  VENTA_DE_D_SABOR_M,
  VENTA_DE_D_SABOR_R,
  VENTA_DE_D_SABOR_V,
]

const getVariablesSalesPackageD = variables => ({
  type: GET_VARIABLES_SALES_PACKAGE_D,
  variables,
})

const fetchVariablesSalesPackageD = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(salesPackageD)
    dispatch(getVariablesSalesPackageD(variables))
  }
}

const salesPackageE = [
  VENTA_DE_E_SABOR_C,
  VENTA_DE_E_SABOR_F,
  VENTA_DE_E_SABOR_L,
  VENTA_DE_E_SABOR_M,
  VENTA_DE_E_SABOR_R,
  VENTA_DE_E_SABOR_V,
]

const getVariablesSalesPackageE = variables => ({
  type: GET_VARIABLES_SALES_PACKAGE_E,
  variables,
})

const fetchVariablesSalesPackageE = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(salesPackageE)
    dispatch(getVariablesSalesPackageE(variables))
  }
}

const salesPackageF = [
  VENTA_DE_F_SABOR_C,
  VENTA_DE_F_SABOR_F,
  VENTA_DE_F_SABOR_L,
  VENTA_DE_F_SABOR_M,
  VENTA_DE_F_SABOR_R,
  VENTA_DE_F_SABOR_V,
]

const getVariablesSalesPackageF = variables => ({
  type: GET_VARIABLES_SALES_PACKAGE_F,
  variables,
})

const fetchVariablesSalesPackageF = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(salesPackageF)
    dispatch(getVariablesSalesPackageF(variables))
  }
}

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_MARKETING_AND_LOGISTICS, payload }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFunctionsFetchMarketingAndLogistics = [
  fetchVariablesPricing,
  fetchVariablesMarketing,
  fetchVariablesLogistics,
  fetchVariablesMarketShare,
  fetchVariablesPeriodSales,
  fetchVariablesMarketPrice,
  fetchVariablesMarketShares,
  fetchVariablesFillRates,
  fetchVariablesFleet,
  fetchVariablesSalesPackageA,
  fetchVariablesSalesPackageB,
  fetchVariablesSalesPackageC,
  fetchVariablesSalesPackageD,
  fetchVariablesSalesPackageE,
  fetchVariablesSalesPackageF,
]

export {
  fetchVariablesPricing,
  fetchVariablesMarketing,
  fetchVariablesLogistics,
  fetchVariablesMarketShare,
  fetchVariablesPeriodSales,
  fetchVariablesMarketPrice,
  fetchVariablesMarketShares,
  fetchVariablesFillRates,
  fetchVariablesFleet,
  fetchVariablesSalesPackageA,
  fetchVariablesSalesPackageB,
  fetchVariablesSalesPackageC,
  fetchVariablesSalesPackageD,
  fetchVariablesSalesPackageE,
  fetchVariablesSalesPackageF,
  allFunctionsFetchMarketingAndLogistics,
  changeVariable,
  clearNewValues,
}
