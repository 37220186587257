import React from 'react'
import { useSelector } from 'react-redux'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import BaseMarketChart from './BaseMarketChart'

const { AVERAGE_MARKET_PRICE } = CONSTANTS.MARKETING_AND_LOGISTICS
const { EMPAQUE_A, EMPAQUE_B, EMPAQUE_C, EMPAQUE_D, EMPAQUE_E, EMPAQUE_F } = CONSTANTS.INVENTORY

const {
  PRECIO_A_COMPETENCIA,
  PRECIO_B_COMPETENCIA,
  PRECIO_C_COMPETENCIA,
  PRECIO_D_COMPETENCIA,
  PRECIO_E_COMPETENCIA,
  PRECIO_F_COMPETENCIA,
} = MARKETING_AND_LOGISTICS

const packageToVariableMapping = {
  [EMPAQUE_A]: PRECIO_A_COMPETENCIA,
  [EMPAQUE_B]: PRECIO_B_COMPETENCIA,
  [EMPAQUE_C]: PRECIO_C_COMPETENCIA,
  [EMPAQUE_D]: PRECIO_D_COMPETENCIA,
  [EMPAQUE_E]: PRECIO_E_COMPETENCIA,
  [EMPAQUE_F]: PRECIO_F_COMPETENCIA,
}

const ChartMarketPrice = () => {
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.marketPrice)
  return (
    <BaseMarketChart
      title={AVERAGE_MARKET_PRICE}
      variables={variables}
      packageToVariableMapping={packageToVariableMapping}
    />
  )
}

export default ChartMarketPrice
