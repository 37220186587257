import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { OPERATIONS } from 'constants/forioVariables'
import { changeVariable } from 'actions/operationsActions'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { changeValue as onChangeValue } from 'common'

const { MAINTENANCE, SPENDING_ON_MAINTENANCE } = CONSTANTS.OPERATIONS

const { DECISION_INVERTIR_MANTENIMIENTO } = OPERATIONS

const returnElements = (variables, changeValue) => [
  createListRowForVariable(
    DECISION_INVERTIR_MANTENIMIENTO,
    SPENDING_ON_MAINTENANCE,
    variables,
    changeValue,
  ),
]

const ListMaintenance = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('maintenance', dispatch, changeVariable)
  const variables = useSelector(state => state.operations.currentValues.maintenance)
  return <List items={returnElements(variables, changeValue)} listTitle={MAINTENANCE} />
}
export default ListMaintenance
