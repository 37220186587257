import {
  CHANGE_VARIABLE_RESULTS,
  CLEAR_NEW_VALUES,
  CLEAR_VALUES,
  GET_VARIABLES_RESULTS,
  GET_VARIABLES_FINANCES,
} from 'constants/actionTypes'

const initialResults = {
  currentValues: { variablesResults: {}, variablesFinances: {} },
  newValues: {},
}

const Results = (state = initialResults, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialResults
    case CHANGE_VARIABLE_RESULTS:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case GET_VARIABLES_RESULTS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesResults: action.variables,
        },
      }
    case GET_VARIABLES_FINANCES:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesFinances: action.variables,
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default Results
