import React from 'react'
import { useSelector } from 'react-redux'
import BarList from 'components/BarList'
import { CONSTANTS } from 'constants/constants'
import { OPERATIONS } from 'constants/forioVariables'
import { Card, CardContent, Box } from '@material-ui/core'
import { GaugeChart } from 'components/Charts'
import useStyles from './style'

const {
  PERCEIVED_QUALITY_TEXT,
  QUALITY_FROM_AGEING,
  QUALITY_FROM_OVERRUN,
  QUALITY_FROM_MACHINE,
} = CONSTANTS.OPERATIONS

const {
  QUALITY_POR_AGING,
  QUALITY_POR_OVERRUN,
  QUALITY_POR_MAQUINAS,
  PERCEIVED_QUALITY,
} = OPERATIONS

const createListRowForVariable = (label, variables, variableId) => {
  return {
    id: `list-${variableId}`,
    label,
    value: customValue(variables[variableId] * 100),
  }
}

const customValue = value => {
  if (value === 0) {
    return 1
  }
  return value
}

const returnElements = variables => [
  createListRowForVariable(QUALITY_FROM_AGEING, variables, QUALITY_POR_AGING),
  createListRowForVariable(QUALITY_FROM_OVERRUN, variables, QUALITY_POR_OVERRUN),
  createListRowForVariable(QUALITY_FROM_MACHINE, variables, QUALITY_POR_MAQUINAS),
]

const PerceivedQuality = () => {
  const classes = useStyles()
  const variables = useSelector(state => state.operations.currentValues.quality)

  return (
    <Card className={classes.card}>
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{PERCEIVED_QUALITY_TEXT}</h2>
      </div>
      <CardContent>
        <Box key="perceived-quality" display="flex" alignItems="center">
          <GaugeChart
            titleChart={PERCEIVED_QUALITY_TEXT}
            value={variables[PERCEIVED_QUALITY].toFixed(2)}
          />
          <BarList items={returnElements(variables)} />
        </Box>
      </CardContent>
    </Card>
  )
}
export default PerceivedQuality
