import React from 'react'
import GaugeChart from 'react-gauge-chart'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card, CardContent } from '@material-ui/core'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#fff',
    border: '1px solid #abd9ea',
    borderRadius: '5px',
    boxShadow: 'none',
    padding: '0px 0px 5px 0px',
    alignItems: 'center',
    width: '40%',
    height: 'max-content',
  },
  title: {
    textAlign: 'center',
    color: '#73879c',
    fontWeight: '400',
    fontSize: '.875rem',
    marginTop: '5px',
    marginBottom: '0px',
  },
})

const Gauge = ({ titleChart, value }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <h2 className={classes.title}>{titleChart}</h2>
      <p style={{ textAlign: 'center', margin: '0' }}>{value}</p>
      <CardContent>
        <GaugeChart
          id="gauge-chart1"
          arcsLength={[value, 1 - value]}
          colors={['#1ABB9C', '#E0E0E0']}
          percent={value}
          arcPadding={0}
          cornerRadius={0}
          needleColor="#000000"
          needleBaseColor="#000000"
          hideText
          arcWidth={1}
        />
      </CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="center" margin="0px 40px">
        <p style={{ margin: '0px' }}>0.00</p>
        <p style={{ margin: '0px' }}>1.00</p>
      </Box>
    </Card>
  )
}
export default Gauge
