import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const { TIME } = HEADER
const { TOTAL, CHOCOLATE, STRAWBERRY, LEMON, MANGO, RUM, VANILLA } = CONSTANTS.PRODUCTS

const returnData = (variables, flavorToVariableMapping, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      getChartLineForVariable(
        flavorToVariableMapping[TOTAL],
        TOTAL,
        CHART_COLORS.COLOR_A,
        variables,
      ),
      getChartLineForVariable(
        flavorToVariableMapping[CHOCOLATE],
        CHOCOLATE,
        CHART_COLORS.COLOR_B,
        variables,
      ),
      getChartLineForVariable(
        flavorToVariableMapping[STRAWBERRY],
        STRAWBERRY,
        CHART_COLORS.COLOR_C,
        variables,
      ),
      getChartLineForVariable(
        flavorToVariableMapping[LEMON],
        LEMON,
        CHART_COLORS.COLOR_D,
        variables,
      ),
      getChartLineForVariable(
        flavorToVariableMapping[MANGO],
        MANGO,
        CHART_COLORS.COLOR_E,
        variables,
      ),
      getChartLineForVariable(flavorToVariableMapping[RUM], RUM, CHART_COLORS.COLOR_F, variables),
      getChartLineForVariable(
        flavorToVariableMapping[VANILLA],
        VANILLA,
        CHART_COLORS.COLOR_G,
        variables,
      ),
    ],
  }
}

const ChartInventoryByProductPresentation = props => {
  const { variables, title, flavorToVariableMapping } = props
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, flavorToVariableMapping, time)} titleChart={title} />
  ) : null
}
export default ChartInventoryByProductPresentation
