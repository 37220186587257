import React from 'react'
import { Button, Fade, Snackbar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/HelpOutline'
import MuiAlert from '@material-ui/lab/Alert'
import { AlertTitle } from '@material-ui/lab'

import './style.css'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const CustomAlert = withStyles({
  root: {
    '& .MuiAlert-action': {
      alignItems: 'start',
      padding: '5px 4px',
    },
  },
})(Alert)

const CustomButton = withStyles({
  root: {
    minWidth: '35px',
    minHeight: '35px',
    maxWidth: '35px',
    maxHeight: '35px',
    color: '#C5C7CB',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    '&:hover': {
      backgroundColor: '#e6e6e6',
      border: '1px solid #adadad',
    },
  },
})(Button)

const HelpButton = ({ helpTitle, helpMessage }) => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  return (
    <>
      <CustomButton onClick={handleClick} variant="contained" size="small">
        <HelpIcon />
      </CustomButton>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        TransitionComponent={Fade}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <CustomAlert onClose={handleClose} severity="info">
          <AlertTitle>{helpTitle}</AlertTitle>
          {helpMessage.map(line => (
            <p>{line}</p>
          ))}
        </CustomAlert>
      </Snackbar>
    </>
  )
}

export default HelpButton
