import * as actions from "./action-types";

export const initialState = {
  tokenInfo: {},
  userProfile: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.LOGGED_IN:
      return {
        ...state,
        ...action.payload
      };
    case actions.NOT_LOGGED_IN:
      return {
        ...state
      };
    case actions.LOGIN_SUCCESSFUL:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
