export const CHECK_AUTH = "/";
export const CALLBACK = "/callback";
export const BASE = "/app";
export const CREATE_TEAM_PREAMBLE = "/CreateTeamPreamble";
export const CREATE_TEAM = "/CreateTeam";
export const JOIN_TEAM_PREAMBLE = "/JoinTeamPreamble";
export const JOIN_TEAM = "/JoinTeam";
export const SIMULATIONS = "/Simulations";
export const PRODUCTS = `${BASE}/products`;
export const OPERATIONS = `${BASE}/operations`;
export const MARKETING_AND_LOGISTICS = `${BASE}/marketingAndLogistics`;
export const INVENTORY = `${BASE}/inventory`;
export const RESULTS = `${BASE}/results`;
