import React from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { CONSTANTS } from 'constants/constants'
import { RESULTS } from 'constants/forioVariables'
import { faMoneyBill1 } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStyles from './style'

const { ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE } = RESULTS
const { NET_PROFIT } = CONSTANTS.RESULTS

const ProfitBeforeTaxes = () => {
  const classes = useStyles()
  const variables = useSelector(state => state.results.currentValues.variablesResults)
  const value = variables[ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE].toFixed(2)

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box key={ACCUMULATED_NET_INCOME_AFTER_TAXES_PARA_SABLE} alignItems="left">
            <Box style={{ marginBottom: '10px' }}>
              <Typography className={classes.value}>{value}</Typography>
            </Box>
            <Box>
              <p style={{ marginBottom: 0 }}>{NET_PROFIT}</p>
            </Box>
          </Box>
          <Box key="faMoney" alignItems="Right">
            <FontAwesomeIcon size="5x" icon={faMoneyBill1} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ProfitBeforeTaxes
