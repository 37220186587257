import React, { useContext } from "react";
import ShareModal from "components/Modals/ShareModal";
import { loadLocalStorageValue, localStorageKeys } from "services/localstorage";
import { createJoinTeamLink } from "utilities/appServiceUtilities";
import { MyContext } from "common/ConfigProvider";

const ShareLink = () => {
  const state = useContext(MyContext);
  const { openShareModal, setOpenShareModal } = state;

  const handleOpenShareLink = () => {
    setOpenShareModal();
  };

  const teamInformation = loadLocalStorageValue(
    localStorageKeys.TEAM_INFORMATION
  );
  const joinTeamLink = createJoinTeamLink(teamInformation);

  return (
    <ShareModal
      open={openShareModal}
      shareLink={joinTeamLink}
      titleModal="Join Team Link"
      titleSecondaryButton="Close"
      handleSecondaryButton={handleOpenShareLink}
      messageType
    />
  );
};

export default ShareLink;
