import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { HEADER } from 'constants/forioVariables'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const { TIME } = HEADER

const returnData = (variables, variableId, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [getChartLineForVariable(variableId, '', CHART_COLORS.COLOR_A, variables)],
  }
}

const BaseChart = ({ variableId, title }) => {
  const variables = useSelector(state => state.operations.currentValues.chartsVariables)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, variableId, time)} titleChart={title} hideLabel />
  ) : null
}

export default BaseChart
