import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { MARKETING_AND_LOGISTICS } from 'constants/forioVariables'
import { changeVariable } from 'actions/marketingAndLogisticsActions'
import { createListRowForVariable } from 'common/UIComponentUtilities'
import { changeValue as onChangeValue } from 'common'

const {
  PRICING,
  PRECIO_PER_GI_EMPAQUE_A,
  PRECIO_PER_GI_EMPAQUE_B,
  PRECIO_PER_GI_EMPAQUE_C,
  PRECIO_PER_GI_EMPAQUE_D,
  PRECIO_PER_GI_EMPAQUE_E,
  PRECIO_PER_GI_EMPAQUE_F,
} = CONSTANTS.MARKETING_AND_LOGISTICS

const {
  PRECIO_EMPAQUE_A,
  PRECIO_EMPAQUE_B,
  PRECIO_EMPAQUE_C,
  PRECIO_EMPAQUE_D,
  PRECIO_EMPAQUE_E,
  PRECIO_EMPAQUE_F,
} = MARKETING_AND_LOGISTICS

const returnElements = (variables, changeValue) => [
  createListRowForVariable(PRECIO_EMPAQUE_A, PRECIO_PER_GI_EMPAQUE_A, variables, changeValue),
  createListRowForVariable(PRECIO_EMPAQUE_B, PRECIO_PER_GI_EMPAQUE_B, variables, changeValue),
  createListRowForVariable(PRECIO_EMPAQUE_C, PRECIO_PER_GI_EMPAQUE_C, variables, changeValue),
  createListRowForVariable(PRECIO_EMPAQUE_D, PRECIO_PER_GI_EMPAQUE_D, variables, changeValue),
  createListRowForVariable(PRECIO_EMPAQUE_E, PRECIO_PER_GI_EMPAQUE_E, variables, changeValue),
  createListRowForVariable(PRECIO_EMPAQUE_F, PRECIO_PER_GI_EMPAQUE_F, variables, changeValue),
]

const ListPricing = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('pricing', dispatch, changeVariable)
  const variables = useSelector(state => state.marketingAndLogistics.currentValues.pricing)
  return <List items={returnElements(variables, changeValue)} listTitle={PRICING} />
}

export default ListPricing
