import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { INVENTORY, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { CHART_COLORS } from 'constants/chartLineColors'
import { getChartLineForVariable } from 'common/UIComponentUtilities'

const { INVENTARIO_DE_LECHE, INVENTARIO_DE_OTROS } = INVENTORY

const { TIME } = HEADER
const { RAW_MATERIALS_INVENTORY, MILK, OTHERS } = CONSTANTS.INVENTORY

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      getChartLineForVariable(INVENTARIO_DE_LECHE, MILK, CHART_COLORS.COLOR_A, variables),
      getChartLineForVariable(INVENTARIO_DE_OTROS, OTHERS, CHART_COLORS.COLOR_B, variables),
    ],
  }
}

const ChartRawMaterials = () => {
  const variables = useSelector(state => state.inventory.currentValues.variablesChartRawMaterials)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart={RAW_MATERIALS_INVENTORY} />
  ) : null
}
export default ChartRawMaterials
