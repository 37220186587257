import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { history } from "store/configureStore";
import { loginSuccessful } from "../../store/User/actions";
import { redirectionHandler } from "../../services/authService";
import LoadingComponent from "../../components/Loading";
import * as ROUTES from "../../constants/routes";

class CallBack extends Component {
  async componentDidMount() {
    const pathContainsHash = /access_token|id_token|error/.test(
      window.location.hash
    );
    if (pathContainsHash) {
      const { loginSuccessful: userLoginSuccessful } = this.props;
      const isLoggedIn = await userLoginSuccessful();
      if (isLoggedIn) {
        redirectionHandler();
      }
    } else {
      history.push(ROUTES.CHECK_AUTH);
    }
  }

  render() {
    return <LoadingComponent />;
  }
}

const mapDispatchToProps = dispatch => ({
  loginSuccessful: () => dispatch(loginSuccessful())
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(withRouter(CallBack));
