import {
  GET_VARIABLES_PER_PACKAGE,
  GET_VARIABLES_RAW_MATERIALS,
  GET_VARIABLES_CHART_PACKAGING_MATERIAL,
  GET_VARIABLES_CHART_RAW_MATERIALS,
  CHANGE_VARIABLE_INVENTORY,
  CLEAR_NEW_VALUES,
  CLEAR_VALUES,
} from 'constants/actionTypes'

const initialInventory = {
  currentValues: {
    variablesPerPackage: {},
    variablesRawMaterials: {},
    variablesChartPackagingMaterial: {},
    variablesChartRawMaterials: {},
  },
  newValues: {},
}

const Inventory = (state = initialInventory, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialInventory
    case GET_VARIABLES_PER_PACKAGE:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesPerPackage: action.variables,
        },
      }
    case GET_VARIABLES_RAW_MATERIALS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesRawMaterials: action.variables,
        },
      }
    case GET_VARIABLES_CHART_PACKAGING_MATERIAL:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartPackagingMaterial: action.variables,
        },
      }
    case GET_VARIABLES_CHART_RAW_MATERIALS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartRawMaterials: action.variables,
        },
      }
    case CHANGE_VARIABLE_INVENTORY:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default Inventory
