import React, { useCallback } from 'react'
import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Fab, withStyles, Snackbar, Fade } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import { loadLocalStorageValue, localStorageKeys, saveOnLocalStorage } from 'services/localstorage'
import allDispatch from 'common/allDispatch'
import seletedVariablesToUpdate from 'common/seletedVariablesToUpdate'
import { getVariablesOfTheState } from 'common/states'
import { getVariablesChanged, validateVariables } from 'common/utils'
import { changeRequest } from 'actions/appBackendActions'
import * as ROUTES from 'constants/routes'
import { translations, UnknownErrorMessage } from 'constants/translations'
import { Alert } from '@material-ui/lab'

const CustomAlert = withStyles({
  root: {
    '& .MuiAlert-action': {
      alignItems: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
})(Alert)

const useStyle = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    textTransform: 'capitalize',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const ButtonUpdateComponent = ({ url }) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const teamOutOfDateError = 'ChangeRequestValidationController_DoesntMatchRevisionNumber'
  let variables = null
  let currentValues = null
  let showButton = true

  const initialSnackbarState = { open: false, severity: 'info', text: '' }
  const [snackbarState, setSnackbarState] = React.useState(initialSnackbarState)

  const handleSnackbarOpen = ({ severity, text }) => {
    setSnackbarState({ open: true, severity, text })
  }

  const snackbarSuccess = text => handleSnackbarOpen({ severity: 'success', text })
  const snackbarWarning = text => handleSnackbarOpen({ severity: 'warning', text })
  const snackbarError = text => handleSnackbarOpen({ severity: 'error', text })

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarState(initialSnackbarState)
  }

  const inventory = useSelector(state => state.inventory.newValues)
  const marketingAndLogistics = useSelector(state => state.marketingAndLogistics.newValues)
  const operations = useSelector(state => state.operations.newValues)
  const products = useSelector(state => state.products.newValues)
  const productsCurrentValues = useSelector(state => state.products.currentValues)
  const results = useSelector(state => state.results.newValues)
  switch (url) {
    case ROUTES.PRODUCTS:
      variables = { ...getVariablesOfTheState(products) }
      currentValues = { ...getVariablesOfTheState(productsCurrentValues) }
      break
    case ROUTES.OPERATIONS:
      variables = { ...getVariablesOfTheState(operations) }
      break
    case ROUTES.MARKETING_AND_LOGISTICS:
      variables = { ...getVariablesOfTheState(marketingAndLogistics) }
      break
    case ROUTES.INVENTORY:
      variables = { ...getVariablesOfTheState(inventory) }
      break
    case ROUTES.RESULTS:
      variables = { ...getVariablesOfTheState(results) }
      break
    default:
      showButton = false
  }

  let variablesToUpdate = seletedVariablesToUpdate(variables)
  const teamInformation = loadLocalStorageValue(localStorageKeys.TEAM_INFORMATION)

  const updateVariables = async () => {
    const data = {
      changes: variablesToUpdate,
      revisionNumber: teamInformation.changeRevisionNumber,
      step: teamInformation.step,
      teamId: teamInformation.id,
    }
    const response = await changeRequest(data)
    return response
  }

  const getVariables = useCallback(() => {
    allDispatch(dispatch)
  }, [dispatch])

  const updateSimulation = async () => {
    if (Object.keys(variables).length === 0) {
      snackbarWarning('No changes to save')
      return
    }
    let validationErrors
    if (url === ROUTES.PRODUCTS) {
      validationErrors = validateVariables(variables)
    }

    if (validationErrors && validationErrors.error) {
      snackbarError(validationErrors.message)
      return
    }
    if (validationErrors) {
      variablesToUpdate = getVariablesChanged(currentValues, variablesToUpdate)
    }

    const response = await updateVariables()
    if (response.outcome === true) {
      await getVariables()
      snackbarSuccess('Changes submitted')
      return
    }

    if (response.message === teamOutOfDateError) {
      saveOnLocalStorage(localStorageKeys.TEAM_INFORMATION, response.payload)
      await getVariables()
    }

    const translatedError = translations[`${response.message}`]

    snackbarError(translatedError || UnknownErrorMessage)
  }

  if (showButton) {
    return (
      <>
        <Fab
          onClick={updateSimulation}
          variant="extended"
          size="small"
          aria-label="run"
          className={classes.margin}
        >
          <Typography fontWeight="fontWeightLight" fontSize={8} className={classes.title}>
            save changes
          </Typography>
          <SaveIcon className={classes.extendedIcon} />
        </Fab>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarState.open}
          TransitionComponent={Fade}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <CustomAlert onClose={handleSnackbarClose} severity={snackbarState.severity}>
            {snackbarState.text}
          </CustomAlert>
        </Snackbar>
      </>
    )
  }

  return null
}

class ButtonUpdateSimulation extends React.Component {
  render() {
    const { location } = this.props
    const { pathname } = location

    return <ButtonUpdateComponent url={pathname} />
  }
}

export default withRouter(ButtonUpdateSimulation)
